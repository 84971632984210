import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  /**
   * 키에 값을 설정합니다.
   * @param key 키
   * @param value 값
   */
  async setItem<T = any>(key: string, value: T): Promise<void> {
    await Storage.set({ key, value: JSON.stringify(value) });
  }

  /**
   * 키에 설정된 값을 가져옵니다.
   * @param key 키
   */
  async getItem<T = any>(key: string): Promise<T | null> {
    const ret = await Storage.get({ key });
    return ret.value != null ? JSON.parse(ret.value) : null;
  }

  /**
   * 키에 설정된 값을 제거합니다.
   * @param key 키
   */
  async removeItem(key: string): Promise<void> {
    await Storage.remove({ key });
  }

  /**
   * 모든 키와 값을 제거합니다.
   */
  async clear(): Promise<void> {
    await Storage.clear();
  }
}
