import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ROUTER_NAVIGATING } from '../../constants/router-navigating.constant';

const BACKDROP = 'backdrop';

@Injectable({
  providedIn: 'root',
})
export class ModalPopGuard<T> implements CanDeactivate<T> {
  constructor(
    private location: Location,
    private router: Router,
    private modalCtrl: ModalController,
  ) {}

  canDeactivate(
    component: T,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if ((this.router as any)[ROUTER_NAVIGATING]) {
      return true;
    }

    return this.modalCtrl.getTop().then((modal) => {
      if (modal != null) {
        this.location.go(this.location.path(), undefined, this.location.getState());
        return modal.dismiss(undefined, BACKDROP).then(() => false);
      }
      return true;
    });
  }
}
