import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';
import { TicketReceived } from '../../types/client.type';
import { isNotNullish } from '../../utils/etc.util';
import { hasKeyExtend } from '../../utils/has-key';
import { josa } from '../../utils/josa';
import { TicketReceivedModalParams } from './ticket-received-modal.type';

/**
 * XSS 주의
 */
@Component({
  selector: 'app-ticket-received-modal',
  templateUrl: './ticket-received-modal.component.html',
  styleUrls: ['./ticket-received-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TicketReceivedModalComponent implements OnInit {
  /* eslint-disable max-len */
  headerTemplate = '무비캡 ${type} 지급 알림';
  messageTemplate = '티켓 <span class="modal-highlight">${amount}</span>장을 획득했습니다.';
  buttonTemplate = '확인';
  ticketPaidTemplate = '유료 티켓 <span class="modal-highlight">${amount}</span>장';
  ticketFreeTemplate = '${obtainedFrom} - <span class="modal-highlight">All</span> 작품 무료 티켓 <span class="modal-highlight">${amount}</span>장';
  ticketPassTemplate = '${obtainedFrom} - <span class="modal-highlight">All</span> 작품 이용권 <span class="modal-highlight">${amount}</span>장';
  ticketPassWithWorkTemplate = '${obtainedFrom} - &lt;<span class="modal-highlight">${relatedWorkTitle}</span>&gt; 작품 이용권 <span class="modal-highlight">${amount}</span>장';
  receivedTickets?: Array<TicketReceived>;
  /* eslint-enable max-len */

  type?: string;
  amount?: number;
  amountPaid?: number;
  amountFree?: number;
  amountPass?: number;
  relatedWorkTitles?: Array<string>;

  constructor(
    private domSanitizer: DomSanitizer,
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {}

  ngOnInit(): void {
    const params: TicketReceivedModalParams = {
      headerTemplate: this.navParams.get('headerTemplate'),
      messageTemplate: this.navParams.get('messageTemplate'),
      buttonTemplate: this.navParams.get('buttonTemplate'),
      ticketPaidTemplate: this.navParams.get('ticketPaidTemplate'),
      ticketFreeTemplate: this.navParams.get('ticketFreeTemplate'),
      ticketPassTemplate: this.navParams.get('ticketPassTemplate'),
      ticketPassWithWorkTemplate: this.navParams.get('ticketPassWithWorkTemplate'),
      receivedTickets: this.navParams.get('receivedTickets'),
    };

    this.headerTemplate = params.headerTemplate ?? '무비캡 ${type} 지급 알림';
    this.messageTemplate = params.messageTemplate ?? '${type} <span class="modal-highlight">${amount}</span>장을 획득했습니다.';
    this.buttonTemplate = params.buttonTemplate ?? '확인';
    this.ticketPaidTemplate = params.ticketPaidTemplate ?? '유료 티켓 <span class="modal-highlight">${amount}</span>장';
    this.ticketFreeTemplate = params.ticketFreeTemplate ??
      '${obtainedFrom} - <span class="modal-highlight">All</span> 작품 무료 티켓 <span class="modal-highlight">${amount}</span>장';
    this.ticketPassTemplate = params.ticketPassTemplate ??
      '${obtainedFrom} - <span class="modal-highlight">All</span> 작품 이용권 <span class="modal-highlight">${amount}</span>장';
    this.ticketPassWithWorkTemplate = params.ticketPassWithWorkTemplate ??
      '${obtainedFrom} - &lt;<span class="modal-highlight">${relatedWorkTitle}</span>&gt; ' +
        '작품 이용권 <span class="modal-highlight">${amount}</span>장';
    this.receivedTickets = params.receivedTickets;

    if (Array.isArray(this.receivedTickets)) {
      this.type = [
        this.receivedTickets.some((item) => item.type === 'paid' || item.type === 'free') ? '티켓' : null,
        this.receivedTickets.some((item) => item.type === 'pass') ? '이용권' : null,
      ].filter(isNotNullish).join('/');
      this.amount = this.receivedTickets.reduce((acc, cur) => acc + cur.ticket.total_amount, 0);
      this.amountPaid = this.receivedTickets.filter((item) => item.type === 'paid').reduce((acc, cur) => acc + cur.ticket.total_amount, 0);
      this.amountFree = this.receivedTickets.filter((item) => item.type === 'free').reduce((acc, cur) => acc + cur.ticket.total_amount, 0);
      this.amountPass = this.receivedTickets.filter((item) => item.type === 'pass').reduce((acc, cur) => acc + cur.ticket.total_amount, 0);
      this.relatedWorkTitles = this.receivedTickets.map((item) => item.relatedWork?.title).filter(isNotNullish);
    }
  }

  onClickClose(): void {
    this.modalCtrl.dismiss();
  }

  formatMessage(template: string): SafeHtml;
  formatMessage(template: string | null | undefined): SafeHtml | null;
  formatMessage(template: string | null | undefined): SafeHtml | null {
    if (template == null) {
      return null;
    }

    return this.domSanitizer.bypassSecurityTrustHtml(
      template.replace(/(\\+)?\${([^{}]+)}/g, (_match, backslashes, varName) => {
        if (typeof backslashes === 'string' && backslashes.length % 2 === 1) {
          return `{{${varName}}}`;
        }

        switch (varName.trim()) {
          case 'type':
            return this.type + '';
          case 'amount':
            return this.amount + '';
          case 'amountPaid':
            return this.amountPaid + '';
          case 'amountFree':
            return this.amountFree + '';
          case 'amountPass':
            return this.amountPass + '';
          case 'relatedWorkTitles':
            return (this.relatedWorkTitles?.join(', ') ?? '') + '';
          default:
            return `\${${varName}}`;
        }
      }).replace(/(.)?\${([^{}]+)}/, (_match, leadingChar, josaWord) => {
        const josaResult = josa(leadingChar, josaWord);
        return josaResult !== leadingChar ? josaResult : `${leadingChar}\${${josaWord}}`;
      }),
    );
  }

  formatTicket(template: string, receivedTicket: TicketReceived): SafeHtml;
  formatTicket(template: string | null | undefined, receivedTicket: TicketReceived): SafeHtml | null;
  formatTicket(template: string | null | undefined, receivedTicket: TicketReceived): SafeHtml | null {
    if (template == null) {
      return null;
    }

    return this.domSanitizer.bypassSecurityTrustHtml(
      template.replace(/(\\+)?\${([^{}]+)}/g, (_match, backslashes, varName) => {
        if (typeof backslashes === 'string' && backslashes.length % 2 === 1) {
          return `{{${varName}}}`;
        }

        switch (varName.trim()) {
          case 'type':
            return receivedTicket.type === 'paid' ?
              '유료 티켓' :
              receivedTicket.type === 'free' ?
              '무료 티켓' :
              receivedTicket.type === 'pass' ?
              '이용권' :
              '';
          case 'amount':
            return receivedTicket.ticket.total_amount + '';
          case 'obtainedFrom':
            return hasKeyExtend(receivedTicket.ticket, 'obtained_from') ? receivedTicket.ticket.obtained_from + '' : '';
          case 'relatedWorkTitle':
            return receivedTicket.relatedWork?.title ?? '';
          default:
            return `\${${varName}}`;
        }
      }).replace(/(.)?\${([^{}]+)}/, (_match, leadingChar, josaWord) => {
        const josaResult = josa(leadingChar, josaWord);
        return josaResult !== leadingChar ? josaResult : `${leadingChar}\${${josaWord}}`;
      }),
    );
  }

  getTicketTemplate(receivedTicket: TicketReceived): string | null {
    switch (receivedTicket.type) {
      case 'paid':
        return this.ticketPaidTemplate;
      case 'free':
        return this.ticketFreeTemplate;
      case 'pass':
        return receivedTicket.relatedWork?._id != null ? this.ticketPassWithWorkTemplate : this.ticketPassTemplate;
      default:
    }
    return null;
  }
}
