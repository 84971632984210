import { Injectable } from '@angular/core';
import { Capacitor, Plugins } from '@capacitor/core';
import { ModalController, Platform } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { BehaviorSubject, from, lastValueFrom, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { LoginWaikikiModalComponent } from '../../components/login-waikiki-modal';
import { EmailCodePurpose } from '../../constants/email-code-purpose.constant';
import { ExternalAuthType } from '../../constants/external-auth-type.constant';
import { AccountChangePasswordParams, AccountDeleteAccountResult } from '../../types/api/account.type';
import { LoginScreenInfo } from '../../types/client.type';
import { openModal } from '../../utils/open-modal';
import { AlertService } from '../alert';
import { ApiService } from '../api/api.service';
import { FirebaseAnalyticsService } from '../firebase-analytics';
import { GoogleService } from '../google';
import { KakaoService } from '../kakao';
import { NaverService } from '../naver/naver.service';
import { PromotionService } from '../promotion';
import { StorageService } from '../storage';
import { RenewTokenInfo } from './auth.type';

const { Device } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private initPromise: Promise<void>;
  private initPromiseResolve?: () => void;

  private accessTokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private platform: Platform,
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private apiService: ApiService,
    private storageService: StorageService,
    private kakaoService: KakaoService,
    private naverService: NaverService,
    private googleService: GoogleService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private promotionService: PromotionService,
  ) {
    this.initPromise = new Promise((resolve) => {
      this.initPromiseResolve = resolve;
    });
  }

  /**
   * `AuthService`를 초기화합니다.
   * 저장된 정보를 불러옵니다.
   */
  async init(): Promise<void> {
    const accessToken = await this.storageService.getItem<string>('waikiki.login.accessToken');
    await this.setAccessToken(accessToken);
    this.initPromiseResolve?.();
  }

  /**
   * 로그인을 합니다. 로그인 성공 시 액세스 토큰이 설정됩니다.
   * @param email 이메일
   * @param password 비밀번호
   */
  async login(email: string, password: string, loginScreenId?: string): Promise<void> {
    const platform = this.getPlatformString();
    const deviceId = (await Device.getInfo()).uuid;
    const res = await lastValueFrom(this.apiService.accountV1SignIn({ email, password, platform, deviceId, loginScreenId }));
    await this.setAccessToken(res.result.accessToken);
    this.firebaseAnalyticsService.logEvent('login', { method: 'waikiki' });

    const { receivedTickets } = res.result;
    if (receivedTickets != null) {
      this.promotionService.logTicketReceived(receivedTickets);
      this.promotionService.showTicketReceivedModal(receivedTickets, { navigateToMainOnDismiss: true }).subscribe();
    }
  }

  /**
   * 외부 계정으로 로그인합니다. 로그인 성공 시 액세스 토큰이 설정됩니다.
   * @param externalType 외부 계정 종류
   * @param externalAccessToken 외부 계정 액세스 토큰
   * @returns 앱 로그인 프로모션 무료 티켓 지급 알림 표시하는 함수
   */
  async loginWithExternal(externalType: ExternalAuthType, externalAccessToken: string, loginScreenId?: string): Promise<Function | void> {
    const platform = this.getPlatformString();
    const deviceId = (await Device.getInfo()).uuid;
    const res = await lastValueFrom(this.apiService.accountV1SignInExternal({
      externalAccessToken,
      externalType,
      platform,
      deviceId,
      isApp: Capacitor.isNative && Capacitor.getPlatform() === 'ios',
      loginScreenId,
    }));
    await this.setAccessToken(res.result.accessToken);
    this.firebaseAnalyticsService.logEvent('login', { method: externalType });

    const { receivedTickets } = res.result;
    if (receivedTickets != null) {
      this.promotionService.logTicketReceived(receivedTickets);
      return () => {
        this.promotionService.showTicketReceivedModal(receivedTickets, { navigateToMainOnDismiss: true }).subscribe();
      };
    }
  }

  /**
   * 액세스 토큰을 갱신합니다.
   */
  async renewToken(silent?: boolean, renewTokenInfo?: RenewTokenInfo): Promise<void> {
    try {
      const renew = renewTokenInfo ?? (await lastValueFrom(this.apiService.accountV1RenewToken())).result;

      if (renew.accessToken) {
        await this.setAccessToken(renew.accessToken);
      }

      const { receivedTickets } = renew;
      if (receivedTickets != null) {
        this.promotionService.logTicketReceived(receivedTickets);
        this.promotionService.showTicketReceivedModal(receivedTickets, { navigateToMainOnDismiss: true }).subscribe();
      }
    } catch (err) {
      if (!silent) {
        this.alertService.alertAPIError(err);
      }
      await this.setAccessToken(null);
    }
  }

  /**
   * 로그아웃을 합니다.
   */
  async logout(): Promise<void> {
    this.kakaoService.logout().catch(() => {});
    this.naverService.logout().catch(() => {});
    this.googleService.logout().catch(() => {});
    try {
      await lastValueFrom(this.apiService.accountV1SignOut());
    } catch (err) {
      this.alertService.alertAPIError(err);
    }
    await this.setAccessToken(null);
  }

  /**
   * 회원가입을 합니다.
   * @param email 이메일
   * @param password 비밀번호
   * @param nickname 닉네임
   * @param emailCode 이메일 인증 코드
   * @param options 옵션
   * @param options.marketingPush 마케팅 메시지 수신 동의
   */
  async register(
    email: string,
    password: string,
    nickname: string,
    emailCode: string,
    options?: {
      marketingPush: boolean;
    },
  ): Promise<void> {
    const { marketingPush } = options ?? {};
    await lastValueFrom(this.apiService.accountV1Register({ email, password, nickname, emailCode, marketingPush }));
    this.firebaseAnalyticsService.logEvent('sign_up', { method: 'waikiki' });
  }

  /**
   * 외부 계정으로 회원가입합니다.
   * @param externalType 외부 계정 종류
   * @param externalAccessToken 외부 계정 액세스 토큰
   * @param options 옵션
   * @param options.email 이메일 주소
   * @param options.emailCode 이메일 인증 코드
   * @param options.marketingPush 마케팅 메시지 수신 동의
   * @returns 앱 로그인 프로모션 무료 티켓 지급 알림 표시하는 함수
   */
  async registerWithSocial(
    externalType: ExternalAuthType,
    externalAccessToken: string,
    options: {
      email?: string;
      emailCode?: string;
      marketingPush?: boolean;
    },
  ): Promise<Function | void> {
    const { email, emailCode, marketingPush } = options;
    const platform = this.getPlatformString();
    const deviceId = (await Device.getInfo()).uuid;
    const res = await lastValueFrom(this.apiService.accountV1SignInExternal({
      externalType,
      externalAccessToken,
      platform,
      deviceId,
      registerMode: true,
      email,
      emailCode,
      marketingPush,
    }));
    await this.setAccessToken(res.result.accessToken);
    this.firebaseAnalyticsService.logEvent('sign_up', { method: externalType });
    this.firebaseAnalyticsService.logEvent('login', { method: externalType });

    const { receivedTickets } = res.result;
    if (receivedTickets != null) {
      this.promotionService.logTicketReceived(receivedTickets);
      this.promotionService.showTicketReceivedModal(receivedTickets, { navigateToMainOnDismiss: true }).subscribe();
    }
  }

  /**
   * 회원가입을 위한 이메일 인증 코드를 요청합니다. 코드 만료 시간을 반환합니다.
   * @param email 코드를 받을 이메일
   */
  requestEmailVerificationCodeForSignUp(email: string): Observable<Date> {
    return this.apiService.accountV1SendEmailCode({ email, purpose: EmailCodePurpose.REGISTER }).pipe(
      map((res) => res.result.expiresAt),
    );
  }

  /**
   * 비밀번호 초기화를 위한 이메일 인증 코드를 요청합니다. 코드 만료 시간을 반환합니다.
   * @param email 코드를 받을 이메일
   */
  requestEmailVerificationCodeForResetPassword(email: string): Observable<Date> {
    return this.apiService.accountV1SendEmailCode({ email, purpose: EmailCodePurpose.RESET_PASSWORD }).pipe(
      map((res) => res.result.expiresAt),
    );
  }

  /**
   * 이메일 변경을 위한 이메일 인증 코드를 요청합니다. 코드 만료 시간을 반환합니다.
   * @param email 코드를 받을 이메일
   */
  requestEmailVerificationCodeForChangeEmail(email: string): Observable<Date> {
    return this.apiService.accountV1SendEmailCode({ email, purpose: EmailCodePurpose.CHANGE_EMAIL }).pipe(
      map((res) => res.result.expiresAt),
    );
  }

  /**
   * 회원탈퇴를 합니다. 탈퇴 성공 시 로그아웃합니다.
   */
  async deleteAccount(externalAccessToken?: string): Promise<AccountDeleteAccountResult> {
    const res = await lastValueFrom(this.apiService.accountV1DeleteAccount({ externalAccessToken, isApp: Capacitor.isNative }));
    await this.setAccessToken(null);
    return res.result;
  }

  /**
   * 이메일을 변경합니다.
   * @param email 이메일
   * @param code 이메일 인증 코드
   */
  async changeEmail(email: string, code: string): Promise<void> {
    await lastValueFrom(this.apiService.accountV1ChangeEmail({ email, code }));
  }

  /**
   * 비밀번호를 변경합니다.
   * @param params 비밀번호 변경 파라미터
   */
  async changePassword(params: AccountChangePasswordParams): Promise<void> {
    const res = await lastValueFrom(this.apiService.accountV1ChangePassword(params));
    if (res.result?.accessToken) {
      await this.setAccessToken(res.result.accessToken);
    } else {
      await this.setAccessToken(null);
    }
  }

  async updatePushToken(fcmToken: string): Promise<void> {
    await lastValueFrom(this.apiService.accountV1UpdatePushToken({ fcmToken }));
  }

  /**
   * 현재 사용중인 액세스 토큰을 반환합니다.
   * 로그인 상태가 아니면 `null`을 반환합니다.
   */
  getAccessToken(): string | null {
    return this.accessTokenSubject.value;
  }

  /**
   * `AuthService`가 초기화된 후 액세스 토큰 값으로 이행합니다.
   * 이미 초기화 상태인 경우 현재 사용중인 액세스 토큰 값으로 이행합니다.
   * 로그인 상태가 아니면 `null` 값으로 이행합니다.
   */
  async getAccessTokenPromise(): Promise<string | null> {
    await this.initPromise;
    return this.accessTokenSubject.value;
  }

  /**
   * 사용할 액세스 토큰이 변경될 때 새 액세스 토큰을 방출합니다.
   * `subscribe` 시 현재 사용중인 액세스 토큰을 방출합니다.
   */
  getAccessTokenObservable(): Observable<string | null> {
    return from(this.accessTokenSubject);
  }

  getLoginScreenInfo(): Observable<LoginScreenInfo> {
    return this.apiService.newsV1LoginScreenInfo().pipe(
      map((res) => res.result.loginScreenInfo),
    );
  }

  openLoginModal(loginScreenId?: string): Observable<OverlayEventDetail> {
    return openModal(this.modalCtrl, {
      component: LoginWaikikiModalComponent,
      componentProps: {
        loginScreenId,
      },
      cssClass: 'modal-bottom-drawer',
    }).pipe(
      share(),
    );
  }

  /**
   * 사용할 액세스 토큰을 설정합니다.
   * @param accessToken 액세스 토큰
   */
  private async setAccessToken(accessToken: string | null): Promise<void> {
    this.storageService.setItem('waikiki.login.accessToken', accessToken);
    this.accessTokenSubject.next(accessToken);
  }

  /**
   * 플랫폼 정보 문자열을 가져옵니다.
   */
  private getPlatformString(): string {
    const base = `${this.platform.is('mobile') ? 'Mobile ' : ''}${this.platform.is('hybrid') ? 'App' : 'Web'}`;
    const additional: Array<string> = [];
    if (this.platform.is('android')) {
      additional.push('Android');
    }
    if (this.platform.is('ios')) {
      additional.push('iOS');
    }
    if (this.platform.is('pwa')) {
      additional.push('PWA');
    }
    return base + (additional.length ? ` (${additional.join(', ')})` : '');
  }
}
