import { Injectable } from '@angular/core';
import { API, Auth, AuthObj, KakaoWeb, StatusObj } from './kakao-web.type';

declare global {
  interface Window {
    /**
     * 카카오 SDK
     */
    Kakao: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class KakaoWebService {
  Auth: Auth | null = null;
  Link: any = null;
  API: API | null = null;
  Navi: any = null;
  Story: any = null;
  PlusFriend: any = null;
  Emoticon: any = null;

  /**
   * 카카오 SDK
   */
  private Kakao: KakaoWeb;

  constructor() {
    this.Kakao = window.Kakao;
  }

  /**
   * 서비스를 초기화합니다.
   * @param key 카카오 앱 키
   */
  init(key: string): void {
    if (!this.Kakao) {
      throw new Error('Kakao is undefined or null');
    }

    this.Kakao.init(key);

    this.Auth = this.Kakao.Auth;
    this.Link = this.Kakao.Link;
    this.API = this.Kakao.API;
    this.Navi = this.Kakao.Navi;
    this.Story = this.Kakao.Story;
    this.PlusFriend = this.Kakao.PlusFriend;
    this.Emoticon = this.Kakao.Emoticon;
  }

  /**
   * 카카오 SDK 리소스를 정리합니다.
   */
  cleanup(): void {
    this.Auth = null;
    this.Link = null;
    this.API = null;
    this.Navi = null;
    this.Story = null;
    this.PlusFriend = null;
    this.Emoticon = null;

    this.Kakao.cleanup();
  }

  /**
   * 버전
   */
  get VERSION(): string {
    return this.Kakao.VERSION;
  }

  set VERSION(v: string) {
    this.Kakao.VERSION = v;
  }

  /**
   * 로그인 상태를 가져옵니다.
   */
  authGetStatus(): Promise<StatusObj> {
    return new Promise((resolve) => {
      if (this.Kakao.Auth.getAccessToken()) {
        this.Kakao.Auth.getStatusInfo((status) => {
          resolve(status);
        });
      } else {
        resolve({ status: 'not_connected' });
      }
    });
  }

  /**
   * 로그아웃합니다.
   */
  authLogout(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.Kakao.Auth.getAccessToken()) {
        this.Kakao.Auth.logout(() => {
          resolve(true);
        });
        } else {
        resolve(true);
      }
    });
  }

  /**
   * 로그인 팝업을 띄웁니다.
   */
  async openLoginPopup(): Promise<AuthObj> {
    return new Promise<AuthObj>((resolve, reject) => {
      if (this.Auth == null) {
        reject(new Error('Kakao API is not available'));
        return;
      }

      // Kakao.Auth.login에서 window.open을 사용하여 팝업을 띄우는데,
      // 팝업 닫힘을 감지하지 않으므로 window.open을 덮어씌움으로써 닫힘 감지를 추가합니다.
      let win: Window | null;
      const openBackup = window.open;
      window.open = function open(...args) {
        win = openBackup.apply(this, args);

        if (win == null) {
          reject(new Error('Failed to open Kakao login window'));
          return win;
        }

        // 팝업 닫힘 감지
        const detectClose = setInterval(() => {
          if (!win || win.closed) {
            clearInterval(detectClose);
            setTimeout(() => {
              reject(new Error('User closed the Kakao login window'));
            }, 3000);
          }
        }, 1000);

        return win;
      };

      this.Auth.login({
        success: (authObj) => {
          resolve(authObj);
        },
        fail: (errorObj) => {
          reject(errorObj);
        }
      });
      window.open = openBackup;
    });
  }
}
