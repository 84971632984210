import { Injectable } from '@angular/core';
import { Capacitor, Plugins } from '@capacitor/core';
import { KakaoLoginPlugin as KakaoLoginPluginType } from '../../plugins/kakao-login-plugin/definitions';
import { KakaoCapacitorService } from '../kakao-capacitor';
import { KakaoWebService, MeAPIResult } from '../kakao-web';
import { KakaoUserInfo } from './kakao.type';

const KakaoLoginPlugin = Plugins.KakaoLoginPlugin as KakaoLoginPluginType;

@Injectable({
  providedIn: 'root'
})
export class KakaoService {
  readonly available = Boolean(KakaoLoginPlugin);

  private native: boolean;

  constructor(
    private kakaoWebService: KakaoWebService,
    private kakaoCapacitorService: KakaoCapacitorService,
  ) {
    this.native = Capacitor.isNative ?? false;
  }

  init(key: string): void {
    this.kakaoWebService.init(key);
  }

  async login(): Promise<string | null> {
    if (!this.available) {
      return Promise.reject(new Error('Kakao API is not available'));
    }

    let accessToken: string | null = null;
    if (this.native) {
      accessToken = (await this.kakaoCapacitorService.login()).accessToken;
    } else if (this.kakaoWebService.Auth != null) {
      this.kakaoWebService.Auth.setAccessToken('', true);
      accessToken = (await this.kakaoWebService.openLoginPopup()).access_token;
    }

    return accessToken;
  }

  async logout(): Promise<void> {
    if (!this.available) {
      return Promise.reject(new Error('Kakao API is not available'));
    }

    let result = false;
    try {
      if (this.native) {
        await this.kakaoCapacitorService.logout().catch((err) => {
          if (
            err instanceof Error ||
            [
              'no authentication key!',
              'authentication tokens not exist.',
            ].includes(err.message)
          ) {
            // pass
          } else {
            throw err;
          }
        });
        result = true;
      } else {
        try {
          result = await this.kakaoWebService.authLogout();
        } catch {
          result = true;
        }
        this.kakaoWebService.Auth?.setAccessToken('', true);
      }
    } catch {}

    if (!result) {
      return Promise.reject(new Error('Failed to logout from Kakao'));
    }
  }

  async getUserInfo(): Promise<KakaoUserInfo> {
    if (!this.available) {
      return Promise.reject(new Error('Kakao API is not available'));
    }

    if (this.native) {
      const info = await this.kakaoCapacitorService.me();
      return {
        id: info.id,
        kakao_account: info.kakaoAccount ? {
          has_age_range: info.kakaoAccount.ageRange != null,
          age_range_needs_agreement: info.kakaoAccount.ageRangeNeedsAgreement,
          age_range: info.kakaoAccount.ageRange,

          birthday_needs_agreement: info.kakaoAccount.birthdayNeedsAgreement,
          has_birthday: info.kakaoAccount.birthday != null,
          birthday_type: info.kakaoAccount.birthdayType,
          birthday: info.kakaoAccount.birthday,

          has_gender: info.kakaoAccount.gender != null,
          gender_needs_agreement: info.kakaoAccount.genderNeedsAgreement,
          gender: info.kakaoAccount.gender,

          has_email: info.kakaoAccount.email != null,
          email_needs_agreement: info.kakaoAccount.emailNeedsAgreement,
          is_email_valid: info.kakaoAccount.isEmailValid,
          is_email_verified: info.kakaoAccount.isEmailValid,
          email: info.kakaoAccount.email,
        } : info.kakaoAccount,
        properties: {
          nickname: info.properties && info.properties.nickname,
          profile_image: info.properties && info.properties.profile_image,
          thumbnail_image: info.properties && info.properties.thumbnail_image,
        },
      } as KakaoUserInfo;
    } else {
      const info = await new Promise<MeAPIResult>((resolve, reject) => {
        if (this.kakaoWebService.API != null) {
          this.kakaoWebService.API.request({
            url: '/v2/user/me',
            success: (res) => { resolve(res); },
            fail: (err: any) => { reject(err); },
          });
        } else {
          reject(new Error('Not available'));
        }
      });
      return {
        id: info.id,
        kakao_account: info.kakao_account ? {
          has_age_range: info.kakao_account.has_age_range,
          age_range_needs_agreement: info.kakao_account.age_range_needs_agreement,
          age_range: info.kakao_account.age_range,

          birthday_needs_agreement: info.kakao_account.birthday_needs_agreement,
          has_birthday: info.kakao_account.has_birthday,
          birthday_type: info.kakao_account.birthday_type,
          birthday: info.kakao_account.birthday,

          has_gender: info.kakao_account.has_gender,
          gender_needs_agreement: info.kakao_account.gender_needs_agreement,
          gender: info.kakao_account.gender,

          has_email: info.kakao_account.has_email,
          email_needs_agreement: info.kakao_account.email_needs_agreement,
          is_email_valid: info.kakao_account.is_email_valid,
          is_email_verified: info.kakao_account.is_email_valid,
          email: info.kakao_account.email,
        } : info.kakao_account,
        properties: {
          nickname: info.properties && info.properties.nickname,
          profile_image: info.properties && info.properties.profile_image,
          thumbnail_image: info.properties && info.properties.thumbnail_image,
        },
      } as KakaoUserInfo;
    }
  }

  async getAccessToken(): Promise<string> {
    if (!this.available) {
      return Promise.reject(new Error('Kakao API is not available'));
    }

    if (this.native) {
      return this.kakaoCapacitorService.getAccessToken();
    } else {
      return this.kakaoWebService.Auth != null
        ? this.kakaoWebService.Auth.getAccessToken()
        : Promise.reject('Kakao API is not available');
    }
  }
}
