import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutocompleteTypes, TextFieldTypes } from '@ionic/core';
import { auditTime, NEVER, startWith, switchMap } from 'rxjs';
import { coerceBooleanProperty } from '../../utils/coerce-boolean-property';
import { fromResize } from '../../utils/from-resize';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    },
  ],
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input', { static: false }) inputElem?: ElementRef<HTMLInputElement>;
  @ViewChild('textarea', { static: false }) textareaElem?: ElementRef<HTMLTextAreaElement>;
  @ViewChildren('textarea') textareaElems!: QueryList<HTMLTextAreaElement>;

  @HostBinding('class.input-focused') inputFocused = false;

  @Input() accept?: string;
  @Input() autocapitalize?: string;
  @Input() autocomplete?: AutocompleteTypes;
  @Input() autocorrect?: 'on' | 'off';
  @Input() set autofocus(value: boolean | undefined) { this.innerAutofocus = coerceBooleanProperty(value); }
  get autofocus() { return this.innerAutofocus; }
  @Input() enterkeyhint?: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';
  @Input() inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  @Input() max?: string;
  @Input() maxlength?: number;
  @Input() min?: string;
  @Input() minlength?: number;
  @Input() set multiple(value: boolean | undefined) { this.innerMultiple = coerceBooleanProperty(value); }
  get multiple() { return this.innerMultiple; }
  @Input() pattern?: string;
  @Input() placeholder?: string | null;
  @Input() set readonly(value: boolean | undefined) { this.innerReadonly = coerceBooleanProperty(value); }
  get readonly() { return this.innerReadonly; }
  @Input() set required(value: boolean | undefined) { this.innerRequired = coerceBooleanProperty(value); }
  get required() { return this.innerRequired; }
  @Input() set spellcheck(value: boolean | undefined) { this.innerSpellcheck = coerceBooleanProperty(value); }
  get spellcheck() { return this.innerSpellcheck; }
  @Input() step?: string;
  @Input() type?: TextFieldTypes | 'textarea';
  @Input() set disabled(value: boolean) {
    this.innerDisabled = coerceBooleanProperty(value);
  };
  get disabled(): boolean {
    return this.innerDisabled;
  }
  @Input()
  set value(value: any) {
    this.writeValue(value);
  }
  get value(): any {
    return this.innerValue;
  }

  private innerAutofocus?: boolean;
  private innerMultiple?: boolean;
  private innerReadonly?: boolean;
  private innerRequired?: boolean;
  private innerSpellcheck?: boolean;
  private innerDisabled = false;
  private innerValue: any;
  private onChangeFn?: (value: any) => void;
  private onTouchedFn?: () => void;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private renderer2: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.textareaElems.changes.pipe(
      startWith(null),
      switchMap(() => {
        const textarea = this.textareaElem?.nativeElement;
        return textarea != null ? fromResize(textarea) : NEVER;
      }),
      auditTime(0),
    ).subscribe(() => {
      this.resizeTextarea();
    });
  }

  onModelChange(value: any): void {
    this.innerValue = value;
    this.onChangeFn?.(value);
    this.resizeTextarea();
  }

  onFocus(): void {
    this.inputFocused = true;
  }

  onBlur(): void {
    this.inputFocused = false;
    this.onTouchedFn?.();
  }

  writeValue(value: any): void {
    this.innerValue = value;
    this.changeDetectorRef.markForCheck();
    this.resizeTextarea();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getInput(): HTMLInputElement | HTMLTextAreaElement | null {
    return this.inputElem?.nativeElement ?? this.textareaElem?.nativeElement ?? null;
  }

  private resizeTextarea(): void {
    const textarea = this.textareaElem?.nativeElement;
    if (textarea != null) {
      this.renderer2.setStyle(textarea, 'height', '1px');
      this.renderer2.setStyle(textarea, 'height', `${textarea.scrollHeight}px`);
    }
  }
}
