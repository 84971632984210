import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppUrlOpen, Plugins } from '@capacitor/core';
import { from, Subject, Subscription } from 'rxjs';
import { unsubscribeAll } from '../../utils/unsubscribe-all';

const { App } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService implements OnDestroy {
  private urlOpenSubject = new Subject<AppUrlOpen>();
  private urlOpenSubscription?: Subscription;

  constructor(
    private ngZone: NgZone,
    private router: Router,
  ) {
    App.addListener('appUrlOpen', (data: AppUrlOpen) => {
      this.urlOpenSubject.next(data);
    });
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.urlOpenSubscription,
    ]);
    this.urlOpenSubject.complete();
  }

  enable(): void {
    this.urlOpenSubscription = from(this.urlOpenSubject).subscribe((data) => {
      this.ngZone.run(() => {
        try {
          const url = new URL(data.url);
          this.router.navigateByUrl(url.pathname.replace(/^\/\/app/, '') + url.search);
        } catch (err) {
          console.error(err);
        }
      });
    });
  }

  disable(): void {
    unsubscribeAll([this.urlOpenSubscription]);
  }
}
