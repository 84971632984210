import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';
import { TicketReceived } from '../../types/client.type';
import { hasKeyExtend } from '../../utils/has-key';
import { josa } from '../../utils/josa';
import { TicketReceivedSingleModalParams } from './ticket-received-single-modal.type';

@Component({
  selector: 'app-ticket-received-single-modal',
  templateUrl: './ticket-received-single-modal.component.html',
  styleUrls: ['./ticket-received-single-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TicketReceivedSingleModalComponent implements OnInit {
  /* eslint-disable max-len */
  headerTemplate = '알림';
  messageTemplate = '${type} <span class="modal-highlight">${amount}</span>장을 획득했습니다.';
  buttonTemplate = '확인';
  receivedTicket?: TicketReceived;
  /* eslint-enable max-len */

  constructor(
    private domSanitizer: DomSanitizer,
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {}

  ngOnInit(): void {
    const params: TicketReceivedSingleModalParams = {
      headerTemplate: this.navParams.get('headerTemplate'),
      messageTemplate: this.navParams.get('messageTemplate'),
      buttonTemplate: this.navParams.get('buttonTemplate'),
      receivedTicket: this.navParams.get('receivedTicket'),
    };

    this.headerTemplate = params.headerTemplate ?? '알림';
    this.messageTemplate = params.messageTemplate ?? (
      params.receivedTicket.relatedWork?._id != null ?
      '<span class="modal-highlight">${relatedWorkTitle}</span> 작품에 사용할 수 있는\n' +
        '${type} <span class="modal-highlight">${amount}</span>장을 획득했습니다.' :
      '${type} <span class="modal-highlight">${amount}</span>장을 획득했습니다.'
    );
    this.buttonTemplate = params.buttonTemplate ?? '확인';
    this.receivedTicket = params.receivedTicket;
  }

  onClickClose(): void {
    this.modalCtrl.dismiss();
  }

  formatMessage(message: string): SafeHtml;
  formatMessage(message: string | null | undefined): SafeHtml | null;
  formatMessage(message: string | null | undefined): SafeHtml | null {
    if (message == null) {
      return null;
    }

    return this.domSanitizer.bypassSecurityTrustHtml(
      message.replace(/(\\+)?\${([^{}]+)}/g, (_match, backslashes, varName) => {
        if (typeof backslashes === 'string' && backslashes.length % 2 === 1) {
          return `{{${varName}}}`;
        }

        switch (varName.trim()) {
          case 'type':
            return this.receivedTicket?.type === 'paid' || this.receivedTicket?.type === 'free' ?
              '티켓' :
              this.receivedTicket?.type === 'pass' ?
              '이용권' :
              '';
          case 'amount':
            return this.receivedTicket?.ticket.total_amount + '';
          case 'obtainedFrom':
            return hasKeyExtend(this.receivedTicket?.ticket, 'obtained_from') ? this.receivedTicket?.ticket.obtained_from + '' : '';
          case 'relatedWorkTitle':
            return this.receivedTicket?.relatedWork?.title ?? '';
          default:
            return `\${${varName}}`;
        }
      }).replace(/(.)?\${([^{}]+)}/, (_match, leadingChar, josaWord) => {
        const josaResult = josa(leadingChar, josaWord);
        return josaResult !== leadingChar ? josaResult : `${leadingChar}\${${josaWord}}`;
      }),
    );
  }
}
