import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, from, lastValueFrom, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TicketReceivedSingleModalComponent } from '../../components/ticket-received-single-modal';
import {
  AccountMovieCapRentHistoryResult,
  AccountPurchaseHistoryResult,
  AccountReadSettingResult,
  AccountTicketFreeHistoryResult,
  AccountTicketObtainHistoryDetailResult,
  AccountTicketObtainHistoryResult,
  AccountTicketPassObtainHistoryResult,
  AccountUpdateSettingParams,
  AccountUpdateSettingResult,
  AccountUseCouponResult
} from '../../types/api/account.type';
import { AccountInfo } from '../../types/client.type';
import { openModal } from '../../utils/open-modal';
import { AlertService } from '../alert';
import { ApiService } from '../api/api.service';
import { FirebaseAnalyticsService } from '../firebase-analytics';
import {
  AccountInfoUpdate,
  Balance,
  BalanceUpdate,
  Setting
} from './user.type';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private isUpdatingBalance = false;
  private isUpdatingAccountInfo = false;

  private balanceUpdatePromise?: Promise<Balance | null>;
  private accountInfoUpdatePromise?: Promise<AccountInfo | null>;

  private balanceSubject = new BehaviorSubject<BalanceUpdate>({ state: 'idle', balance: null });
  private accountInfoSubject = new BehaviorSubject<AccountInfoUpdate>({ state: 'idle', accountInfo: null });

  private settingSubject = new Subject<Setting>();

  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private apiService: ApiService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
  ) {}

  /**
   * 현재 계정 정보를 반환합니다.
   */
  getAccountInfo(): AccountInfo | null {
    return this.accountInfoSubject.value?.accountInfo;
  }

  /**
   * 현재 계정 정보가 변경될 때 새 계정 정보를 방출합니다.
   * `subscribe` 시 현재 사용중인 계정 정보를 방출합니다.
   */
  getAccountInfoObservable(): Observable<AccountInfoUpdate> {
    return from(this.accountInfoSubject);
  }

  /**
   * 계정 정보를 업데이트합니다.
   * @param isAuthed 로그인 여부
   * @returns 계정 정보
   */
  async updateAccountInfo(isAuthed: boolean, silent?: boolean): Promise<AccountInfo | null> {
    if (this.isUpdatingAccountInfo) {
      return this.accountInfoUpdatePromise ?? null;
    }

    if (!isAuthed) {
      this.accountInfoSubject.next({
        state: 'idle',
        accountInfo: null,
      });
      return null;
    }

    this.isUpdatingAccountInfo = true;
    this.accountInfoSubject.next({
      state: 'updating',
      accountInfo: this.accountInfoSubject.value?.accountInfo,
    });

    this.accountInfoUpdatePromise = lastValueFrom(this.apiService.accountV1AccountInfo())
      .then((res) => res.result.accountInfo, (err) => {
        if (!silent) {
          this.alertService.alertAPIError(err);
        }
        return this.accountInfoSubject.value?.accountInfo;
      });
    const newBalance = await this.accountInfoUpdatePromise;
    this.accountInfoSubject.next({
      state: 'idle',
      accountInfo: newBalance,
    });

    this.accountInfoUpdatePromise = undefined;
    this.isUpdatingAccountInfo = false;
    return newBalance;
  }

  /**
   * 현재 잔액을 반환합니다.
   */
  getBalance(): Balance | null {
    return this.balanceSubject.value?.balance;
  }

  /**
   * 현재 잔액이 변경될 때 새 잔액을 방출합니다.
   * `subscribe` 시 현재 사용중인 잔액을 방출합니다.
   */
  getBalanceObservable(): Observable<BalanceUpdate> {
    return from(this.balanceSubject);
  }

  /**
   * 잔액을 업데이트합니다.
   * @param isAuthed 로그인 여부
   * @returns 잔액
   */
  async updateBalance(isAuthed: boolean, silent?: boolean): Promise<Balance | null> {
    if (this.isUpdatingBalance) {
      return this.balanceUpdatePromise ?? null;
    }

    if (!isAuthed) {
      this.balanceSubject.next({
        state: 'idle',
        balance: null,
      });
      return null;
    }

    this.isUpdatingBalance = true;
    this.balanceSubject.next({
      state: 'updating',
      balance: this.balanceSubject.value?.balance,
    });

    this.balanceUpdatePromise = lastValueFrom(this.apiService.accountV1Balance()).then((res) => ({
      ticketFreeTotal: res.result.ticketFreeBalance,
      ticketFreeList: res.result.ticketFreeList,
      ticketPaidTotal: res.result.ticketPaidBalance,
      ticketPaidList: res.result.ticketPaidList,
      ticketPassTotal: res.result.ticketPassBalance,
      ticketPassList: res.result.ticketPassList,
    }), (err) => {
      if (!silent) {
        this.alertService.alertAPIError(err);
      }
      return this.balanceSubject.value?.balance;
    });
    const newBalance = await this.balanceUpdatePromise;
    this.balanceSubject.next({
      state: 'idle',
      balance: newBalance,
    });

    this.balanceUpdatePromise = undefined;
    this.isUpdatingBalance = false;
    return newBalance;
  }

  /**
   * 구매 내역 리스트를 가져옵니다.
   * @param page 페이지
   */
  getPurchaseHistory(page: number): Observable<AccountPurchaseHistoryResult> {
    return this.apiService.accountV1PurchaseHistory({ page }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 무료 티켓 획득 내역 리스트를 가져옵니다.
   * @param page 페이지
   */
  getTicketFreeHistory(page: number): Observable<AccountTicketFreeHistoryResult> {
    return this.apiService.accountV1TicketFreeHistory({ page }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 유료/무료 티켓 획득 내역을 가져옵니다.
   * @param page 페이지
   */
  getTicketObtainHistory(page: number): Observable<AccountTicketObtainHistoryResult> {
    return this.apiService.accountV1TicketObtainHistory({ page }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 유료/무료 티켓 획득 내역의 상세 정보를 가져옵니다.
   * @param id 획득 내역 id
   */
  getTicketObtainHistoryDetail(id: string): Observable<AccountTicketObtainHistoryDetailResult> {
    return this.apiService.accountV1TicketObtainHistoryDetail({ id }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 이용권 티켓 획득 내역을 가져옵니다.
   * @param page 페이지
   */
  getTicketPassObtainHistory(page: number): Observable<AccountTicketPassObtainHistoryResult> {
    return this.apiService.accountV1TicketPassObtainHistory({ page }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 무비캡 대여 내역 리스트를 가져옵니다.
   * @param page 페이지
   */
  getMovieCapRentHistory(page: number): Observable<AccountMovieCapRentHistoryResult> {
    return this.apiService.accountV1MovieCapRentHistory({ page }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 사용자 설정을 불러옵니다.
   */
  readSetting(): Observable<AccountReadSettingResult> {
    return this.apiService.accountV1ReadSetting().pipe(
      map((res) => res.result),
      tap((result) => { this.settingSubject.next(result); }),
    );
  }

  /**
   * 사용자 설정을 저장합니다.
   * @param setting 설정
   */
  updateSetting(setting: AccountUpdateSettingParams): Observable<AccountUpdateSettingResult> {
    return this.apiService.accountV1UpdateSetting(setting).pipe(
      map((res) => res.result),
      tap((result) => { this.settingSubject.next(result); }),
    );
  }

  getSettingObservable(): Observable<Setting> {
    return from(this.settingSubject);
  }

  /**
   * 쿠폰 코드를 사용합니다. 쿠폰 코드가 성공적으로 사용되면 모달이 표시됩니다.
   */
  useCoupon(code: string): Observable<AccountUseCouponResult> {
    return this.apiService.accountV1UseCoupon({ code }).pipe(
      tap((res) => {
        if ((res.result?.receivedTicketFreeAmount ?? 0) > 0) {
          this.firebaseAnalyticsService.logEvent('earn_virtual_currency', {
            virtual_currency_name: 'ticket_free',
            value: res.result.receivedTicketFreeAmount,
          });

          openModal(this.modalCtrl, {
            component: TicketReceivedSingleModalComponent,
            componentProps: {
              amount: res.result.receivedTicketFreeAmount,
            },
            cssClass: 'modal-transparent',
          }).subscribe();
        }
      }),
      map((res) => res.result),
    );
  }
}
