/**
 * 문자열을 클립보드로 복사합니다.
 * @param str 복사될 문자열
 */
export async function copy(str: string): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    try {
      const ta = document.createElement('textarea');
      document.body.appendChild(ta);
      ta.value = str;
      ta.select();
      document.execCommand('copy');
      document.body.removeChild(ta);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
}

/**
 * 가장 가까운 스크롤 가능한 부모를 가져옵니다.
 * @param elem 부모를 찾기 시작할 엘리먼트
 */
export function getScrollParent(elem: HTMLElement): HTMLElement {
  const elemStyle = getComputedStyle(elem);
  const excludeStaticParent = elemStyle.position === 'absolute';
  const overflowRegex = /auto|scroll/;

  if (elemStyle.position === 'fixed') {
    return window.document.body;
  }

  let parent: HTMLElement | null = elem;
  while (true) {
    // shadow dom
    if (parent.assignedSlot) {
      const slotParent = getScrollParent(parent.assignedSlot);

      if (slotParent !== window.document.body) {
        const slotStyle = getComputedStyle(slotParent);

        if (overflowRegex.test(slotStyle.overflow + slotStyle.overflowY + slotStyle.overflowX)) {
          return slotParent;
        }
      }
    }

    parent = parent.parentElement;
    if (!parent) {
      break;
    }

    const style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === 'static') {
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent;
    }
  }

  return window.document.body;
}

/**
 * Blob을 Base64 문자열로 변환합니다.
 * @param blob Blob
 */
export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onloadend = () => {
      const result = fr.result as string;
      resolve(result.slice(result.indexOf(',') + 1));
    };
    fr.onerror = () => {
      reject(fr.error);
    };
    fr.readAsDataURL(blob);
  });
}

/**
 * 앱에서 url을 엽니다. 성공 여부를 반환하지만 정확하지는 않습니다.
 * @param url 앱에서 열 url
 */
export function openInApp(platform: 'android' | 'ios', url: string): Promise<boolean> {
  if (platform !== 'android' && platform !== 'ios') {
    return Promise.resolve(false);
  }

  return new Promise((resolve) => {
    const visibilityListener = () => {
      resolve(true);
    };
    document.addEventListener('visibilitychange', visibilityListener);
    setTimeout(() => {
      document.removeEventListener('visibilitychange', visibilityListener);
      resolve(false);
    }, 3000);

    const anchor = document.createElement('a');
    anchor.href = platform === 'android' ?
      `intent://www.waikikii.kr${url}#Intent;package=kr.waikikii.app;scheme=https;end` :
      `waikiki://app${url}`;
    anchor.style.opacity = '0';
    anchor.style.pointerEvents = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  });
}

export function remToPx(rem: number): number {
  const pxPerRem = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return rem * pxPerRem;
}

export function pxToRem(px: number): number {
  const pxPerRem = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return px / pxPerRem;
}

export function isNotNullish<T>(v: T): v is NonNullable<T> {
  return v != null;
}
