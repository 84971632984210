import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { KakaoLoginPlugin as KakaoLoginPluginType } from '../../plugins/kakao-login-plugin/definitions';
import {
  KakaoLoginAccessTokenInfo,
  KakaoLoginOAuthToken,
  KakaoLoginUser
} from './kakao-capacitor.type';

const KakaoLoginPlugin = Plugins.KakaoLoginPlugin as KakaoLoginPluginType;

@Injectable({
  providedIn: 'root'
})
export class KakaoCapacitorService {
  readonly available = Boolean(KakaoLoginPlugin);

  async login(): Promise<KakaoLoginOAuthToken> {
    const result = await KakaoLoginPlugin.login();
    return {
      ...result,
      accessTokenExpiresAt: result.accessTokenExpiresAt != null ? new Date(result.accessTokenExpiresAt) : result.accessTokenExpiresAt,
      refreshTokenExpiresAt: result.refreshTokenExpiresAt != null ? new Date(result.refreshTokenExpiresAt) : result.refreshTokenExpiresAt,
    } as KakaoLoginOAuthToken;
  }

  async logout(): Promise<void> {
    return KakaoLoginPlugin.logout();
  }

  async unlink(): Promise<void> {
    return KakaoLoginPlugin.unlink();
  }

  async getAccessToken(): Promise<string> {
    return (await KakaoLoginPlugin.getAccessToken()).accessToken;
  }

  async accessTokenInfo(): Promise<KakaoLoginAccessTokenInfo> {
    return KakaoLoginPlugin.accessTokenInfo();
  }

  async me(): Promise<KakaoLoginUser> {
    const result = await KakaoLoginPlugin.me();
    return {
      ...result,
      kakaoAccount: {
        ...result.kakaoAccount,
        ciAuthenticatedAt: result.kakaoAccount?.ciAuthenticatedAt != null ?
          new Date(result.kakaoAccount.ciAuthenticatedAt) :
          result.kakaoAccount?.ciAuthenticatedAt,
      },
      connectedAt: result.connectedAt != null ? new Date(result.connectedAt) : result.connectedAt,
      synchedAt: result.synchedAt != null ? new Date(result.synchedAt) : result.synchedAt,
    } as KakaoLoginUser;
  }
}
