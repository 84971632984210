import { Injectable } from '@angular/core';
import { Capacitor, Plugins } from '@capacitor/core';
import { ExpandPluginPlugin, StatusBarStyle } from '../../plugins/expand-plugin/definitions';
import { DarkMode, ExpandMode } from './expand.type';

const ExpandPlugin = Plugins.ExpandPlugin as ExpandPluginPlugin;

@Injectable({
  providedIn: 'root'
})
export class ExpandService {
  /**
   * 네이티브 앱 여부
   */
  private isNative = Capacitor.isNative ?? false;

  constructor() {}

  /**
   * 영역 확장 모드를 설정합니다.
   * 안드로이드 앱 환경에서만 작동합니다.
   * @param expandMode 영역 확장 모드
   */
  async setExpandMode(expandMode: ExpandMode): Promise<void> {
    if (!this.isNative) {
      throw new Error('setExpandMode is not supported on this platform.');
    }

    await ExpandPlugin.setExpandMode({ mode: expandMode });
  }

  /**
   * 설정된 영역 확장 모드를 가져옵니다.
   * 안드로이드 앱 환경에서만 작동합니다.
   */
  async getExpandMode(): Promise<ExpandMode> {
    if (!this.isNative) {
      throw new Error('setExpandMode is not supported on this platform.');
    }

    return (await ExpandPlugin.getExpandMode()).mode;
  }

  /**
   * 전체화면 모드를 설정합니다.
   * 안드로이드 앱 환경에서만 작동합니다.
   * @param expandMode 전체화면 모드
   */
  async setFullscreenMode(fullscreen: boolean): Promise<void> {
    if (!this.isNative) {
      throw new Error('setFullscreenMode is not supported on this platform.');
    }

    await ExpandPlugin.setFullscreenMode({ fullscreen });
  }

  /**
   * 다크 모드를 설정합니다.
   * 안드로이드 앱 환경에서만 작동합니다.
   * @param expandMode 다크 모드
   */
  async setDarkMode(darkMode: DarkMode): Promise<void> {
    if (!this.isNative) {
      throw new Error('setDarkMode is not supported on this platform.');
    }

    await ExpandPlugin.setDarkMode({ mode: darkMode });
  }

  /**
   * 설정된 다크 모드를 가져옵니다.
   * 안드로이드 앱 환경에서만 작동합니다.
   */
  async getDarkMode(): Promise<DarkMode> {
    if (!this.isNative) {
      throw new Error('setDarkMode is not supported on this platform.');
    }

    return (await ExpandPlugin.getForceDarkMode()).mode;
  }

  /**
   * 상태바 스타일을 설정합니다.
   * @param style 상태바 스타일
   */
  async setStatusBarStyle(style: StatusBarStyle): Promise<void> {
    if (!this.isNative) {
      throw new Error('setStatusBarStyle is not supported on this platform.');
    }

    await ExpandPlugin.setStatusBarStyle({ style });
  }

  /**
   * 설정된 상태바 스타일을 가져옵니다.
   */
  async getStatusBarStyle(): Promise<StatusBarStyle> {
    if (!this.isNative) {
      throw new Error('setStatusBarStyle is not supported on this platform.');
    }

    return (await ExpandPlugin.getStatusBarStyle()).style;
  }
}
