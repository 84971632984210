import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { hasKey } from '../../utils/has-key';
import { ForceDevService } from '../force-dev';
import { StorageService } from '../storage';

const FONT_STORAGE_KEY = 'waikiki.experimental.font';

const STYLESHEETS = {
  pretendard: 'https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css',
};

@Injectable({
  providedIn: 'root'
})
export class FontService implements OnDestroy {
  private renderer2: Renderer2;

  constructor(
    rendererFactory2: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private storageService: StorageService,
    private forceDevService: ForceDevService,
  ) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  ngOnDestroy(): void {
    this.renderer2.destroy();
  }

  init(): void {
    this.storageService.getItem(FONT_STORAGE_KEY).then((value) => {
      this.use(value ?? null);
    });
  }

  get(): string | null {
    return this.document.documentElement.getAttribute('data-font');
  }

  use(fontName: string | null): void {
    if (environment.production && !this.forceDevService.enabled) {
      return;
    }

    if (fontName == null) {
      this.renderer2.removeAttribute(this.document.documentElement, 'data-font');
      this.storageService.removeItem(FONT_STORAGE_KEY);
    } else {
      this.renderer2.setAttribute(this.document.documentElement, 'data-font', fontName);
      this.storageService.setItem(FONT_STORAGE_KEY, fontName);

      if (hasKey(STYLESHEETS, fontName)) {
        this.loadStylesheet(STYLESHEETS[fontName]);
      }
    }
  }

  private loadStylesheet(href: string): void {
    const linkTag = this.renderer2.createElement('link') as HTMLLinkElement;
    linkTag.rel = 'stylesheet';
    linkTag.type = 'text/css';
    linkTag.href = href;
    this.renderer2.appendChild(this.document.head, linkTag);
  }
}
