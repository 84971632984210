import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { AppState, Plugins } from '@capacitor/core';
import { catchError, concat, debounceTime, delay, EMPTY, filter, map, merge, Subject, Subscription, switchMap, tap } from 'rxjs';
import { unsubscribeAll } from '../../utils/unsubscribe-all';
import { AuthService } from '../auth';
import { FcmService } from '../fcm';
import { NewsService } from '../news';
import { PromotionService } from '../promotion';
import { UserService } from '../user';

const { App } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService implements OnDestroy {
  private isAuthed = false;
  private stateChangeSubject = new Subject<AppState>();
  private stateChangeSubscription?: Subscription;
  private settingsSubscription?: Subscription;
  private markAsReadSubscription?: Subscription;

  constructor(
    private ngZone: NgZone,
    private authService: AuthService,
    private fcmService: FcmService,
    private userService: UserService,
    private newsService: NewsService,
    private promotionService: PromotionService,
  ) {
    App.addListener('appStateChange', (state) => {
      this.ngZone.run(() => {
        this.stateChangeSubject.next(state);
      });
    });
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.stateChangeSubscription,
      this.settingsSubscription,
      this.markAsReadSubscription,
    ]);
    this.stateChangeSubject.complete();
  }

  init(): void {
    // 알림 채널 추가
    this.fcmService.ensureChannel().catch((err) => {
      console.error(err);
    });

    // 로그인 상태가 되거나 로그인 상태인 채로 앱 활성화 시 설정 정보 불러오기
    this.stateChangeSubscription = merge(
      this.authService.getAccessTokenObservable().pipe(
        map((accessToken) => !!accessToken),
      ),
      this.stateChangeSubject.pipe(
        filter((state) => state.isActive),
        delay(0),
        map(() => this.isAuthed),
      ),
    ).pipe(
      filter((isAuthed) => isAuthed),
      debounceTime(100),
      switchMap(() => this.userService.readSetting()),
    ).subscribe();

    // 설정 불러오기 성공 시
    this.settingsSubscription = this.userService.getSettingObservable().subscribe((setting) => {
      if (setting?.pushNotification) {
        // 푸시 알림 설정이 켜져있으면 알림 허용 상태 체크
        this.fcmService.checkGranted().then((granted) => {
          if (granted) {
            // 알림 허용 상태면 알림 토큰 가져오기
            this.fcmService.getToken().then((token) => {
              if (token) {
                // 알림 토큰 가져왔으면 알림 토큰 서버에 전송
                this.authService.updatePushToken(token).catch((err) => {
                  console.error(err);
                });
              }
            }, (err) => {
              console.error(err);
            });
          }
        });
      }
    });

    // 푸시 알림 터치 시 연관된 notification id 읽음 처리
    this.markAsReadSubscription = this.authService.getAccessTokenObservable().pipe(
      // this.apiService.setAuthToken() 이 호출된 이후에 작동하도록 하기 위함
      delay(0),
      switchMap((accessToken) => accessToken ? this.fcmService.notificationIdObservable : EMPTY),
    ).subscribe((notificationId) => {
      this.newsService.markNotificationAsRead(notificationId, true).pipe(
        tap((res) => {
          const { receivedTickets } = res;
          if (receivedTickets != null) {
            this.promotionService.logTicketReceived(receivedTickets);
            concat(
              ...receivedTickets.map((receivedTicket) =>
                this.promotionService.showTicketReceivedSingleModal(receivedTicket),
              ),
            ).subscribe();
          }
        }),
        catchError(() => EMPTY),
      ).subscribe();
    });
  }
}
