<section class="modal-section">
  <div *ngIf="headerTemplate != null" class="modal-header">
    <div class="modal-header-text" [innerHTML]="formatMessage(headerTemplate)"></div>
  </div>
  <div class="modal-content">
    <div class="modal-message" [innerHTML]="formatMessage(messageTemplate)"></div>
    <div class="modal-message-2">지금 바로 무비캡을 감상해보세요~</div>
  </div>
  <div class="modal-footer">
    <div class="modal-buttons">
      <ion-button class="modal-button" [innerHTML]="formatMessage(buttonTemplate)" (click)="onClickClose()"></ion-button>
    </div>
  </div>
</section>
