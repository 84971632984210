import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ShowdownModule } from 'ngx-showdown';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScreenTrackingService } from './services/screen-tracking';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        IonicModule.forRoot({
            backButtonIcon: 'wai-nav-back',
            backButtonText: '',
        }),
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAnalyticsModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        ShowdownModule.forRoot({
            flavor: 'github',
        }),
        ServiceWorkerModule.register('combined-sw.js', { enabled: environment.serviceWorker, registrationStrategy: 'registerImmediately' }),
        AppRoutingModule,
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        ScreenTrackingService,
        InAppPurchase2,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
