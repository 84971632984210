import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AppDownloadModalParams } from './app-download-modal.type';

@Component({
  selector: 'app-app-download-modal',
  templateUrl: './app-download-modal.component.html',
  styleUrls: ['./app-download-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppDownloadModalComponent implements OnInit {
  showLogo = true;
  message1Template = '와이키키 앱에서 제공되는\n다양한 혜택을 놓치지 마세요';
  message2Template = '로그인만 해도 에피소드를 감상만 해도\n유료 티켓과 이용권을 제공中';
  downloadButtonTemplate = '앱 설치하고 혜택 받기';
  closeButtonTemplate = '괜찮아요. 오늘은 웹에서 볼게요';

  isBottomDrawer = false;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {}

  ngOnInit(): void {
    const ionModalClassList = this.elementRef.nativeElement.closest('ion-modal')?.classList;
    this.isBottomDrawer =
      (ionModalClassList?.contains('modal-bottom-drawer') || ionModalClassList?.contains('modal-bottom-drawer-transparent')) ??
      false;

    const params: AppDownloadModalParams = {
      showLogo: this.navParams.get('showLogo'),
      message1Template: this.navParams.get('message1Template'),
      message2Template: this.navParams.get('message2Template'),
      downloadButtonTemplate: this.navParams.get('downloadButtonTemplate'),
      closeButtonTemplate: this.navParams.get('closeButtonTemplate'),
    };

    this.showLogo = params.showLogo ?? true;
    this.message1Template = params.message1Template ?? '와이키키 앱에서 제공되는\n다양한 혜택을 놓치지 마세요';
    this.message2Template = params.message2Template ?? '로그인만 해도 에피소드를 감상만 해도\n유료 티켓과 이용권을 제공中';
    this.downloadButtonTemplate = params.downloadButtonTemplate ?? '앱 설치하고 혜택 받기';
    this.closeButtonTemplate = params.closeButtonTemplate ?? '괜찮아요. 오늘은 웹에서 볼게요';
  }

  onClickDownload(): void {
    this.modalCtrl.dismiss({ download: true });
  }

  onClickClose(): void {
    this.modalCtrl.dismiss({ download: false });
  }
}
