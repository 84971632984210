import { Injectable, NgZone } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Router } from '@angular/router';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor, LocalNotification, Plugins, PushNotification } from '@capacitor/core';
import { BehaviorSubject, firstValueFrom, from, Observable } from 'rxjs';
import { QueueSubject } from 'src/queue-subject';
import { normalizeObservable } from '../../utils/normalize-observable';

const { LocalNotifications, PushNotifications } = Plugins;

interface LocalNotificationPatched extends LocalNotification {
  imageUrl?: string;
}

interface PushNotificationPatched extends PushNotification {
  channel_id?: string;
  color?: string;
  icon?: string;
  image_url?: string;
  vibrate_timings?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  get grantedObservable(): Observable<boolean> {
    return from(this.grantedSubject);
  }
  get notificationIdObservable(): Observable<string> {
    return from(this.notificationIdSubject);
  }

  private fcm: FCM = new FCM();

  private grantedSubject = new BehaviorSubject<boolean>(false);
  private notificationIdSubject = new QueueSubject<string>();

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private afMessaging: AngularFireMessaging,
  ) {
    if (Capacitor.isNative) {
      LocalNotifications.addListener('localNotificationActionPerformed', (localNotificationActionPerformed) => {
        // 로컬 알림 터치 시 작동
        const clickAction = localNotificationActionPerformed.notification.extra?.click_action;
        if (clickAction) {
          this.ngZone.run(() => {
            try {
              this.router.navigateByUrl(clickAction);
            } catch (err) {
              console.error(err);
            }
          });
        }

        const notificationId = localNotificationActionPerformed.notification.extra?.notification_id;
        if (notificationId) {
          this.notificationIdSubject.next(notificationId);
        }
      });
      PushNotifications.addListener('pushNotificationReceived', (pushNotification: PushNotificationPatched) => {
        // 앱 활성화 상태에서 메시지 수신 시 작동
        const notifications: Array<LocalNotificationPatched> = [
          {
            title: pushNotification.title ?? '',
            body: pushNotification.body ?? '',
            id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
            smallIcon: 'ic_notification',
            iconColor: pushNotification.color ?? '#FECE00',
            extra: pushNotification.data,
            group: pushNotification.group,
            groupSummary: pushNotification.groupSummary,
            imageUrl: pushNotification.image_url,
            channelId: pushNotification.channel_id ?? 'common',
            attachments: pushNotification.data?.fcm_options?.image ? [
              {
                id: '',
                url: pushNotification.data.fcm_options.image,
              },
            ] : undefined,
          },
        ];
        LocalNotifications.schedule({ notifications });
      });
      PushNotifications.addListener('pushNotificationActionPerformed', (pushNotificationActionPerformed) => {
        // 앱 비활성화 상태에서 메시지 수신하여 온 푸시 알림 터치 시 작동
        const clickAction = pushNotificationActionPerformed.notification.data?.click_action;
        if (clickAction) {
          this.ngZone.run(() => {
            try {
              this.router.navigateByUrl(clickAction);
            } catch (err) {
              console.error(err);
            }
          });
        }

        const notificationId = pushNotificationActionPerformed.notification.data?.notification_id;
        if (notificationId) {
          this.notificationIdSubject.next(notificationId);
        }
      });
    } else {
      // 포그라운드 메시지 수신 시 알림 표시
      this.afMessaging.messages.subscribe(async (message: any) => {
        const clickAction = message?.data?.click_action ?? message?.notification?.click_action;
        const notificationId = message?.data?.notification_id;

        const notification = new Notification(
          message?.notification?.title,
          {
            body: message?.notification?.body,
            image: message?.notification?.image,
            icon: message?.notification?.icon ?? '/assets/icon/icon-512x512.png',
          },
        );

        notification.addEventListener('click', () => {
          if (clickAction) {
            const url = new URL(clickAction, window.location.href);
            const origin = new URL(window.location.origin);

            if (url.host === origin.host) {
              this.router.navigateByUrl(url.pathname + url.search);
            }
          }

          if (notificationId) {
            this.notificationIdSubject.next(notificationId);
          }
        });
      });

      // TODO: 백그라운드 메시지로 표시된 알림 클릭 시 라우팅
    }
  }

  /**
   * 푸시 알림 권한이 있는지 확인하고, 없으면 권한을 요청합니다.
   * 권한 요청 실패 시 `false`를 이행하는 `Promise`를 반환합니다.
   */
  async checkGranted(): Promise<boolean> {
    let granted = false;

    if (Capacitor.isNative) {
      granted = (await PushNotifications.requestPermission()).granted;
      if (granted) {
        await PushNotifications.register();
      }
    } else {
      granted = await firstValueFrom(normalizeObservable(this.afMessaging.requestPermission)).then(() => true, () => false);
    }

    if (granted !== this.grantedSubject.value) {
      this.grantedSubject.next(granted);
    }

    return granted;
  }

  async getToken(): Promise<string | null> {
    if (!this.grantedSubject.value) {
      return null;
    }

    if (Capacitor.isNative) {
      return (await this.fcm.getToken()).token;
    } else {
      return await firstValueFrom(normalizeObservable(this.afMessaging.getToken));
    }
  }

  /**
   * 알림 채널의 존재 여부를 확인하고, 존재하지 않으면 알림 채널을 생성합니다.
   * 안드로이드 앱 환경에서만 작동하며, 다른 환경에서는 바로 반환됩니다.
   */
  async ensureChannel(): Promise<void> {
    if (!Capacitor.isNative || Capacitor.getPlatform() !== 'android') {
      return;
    }

    const { channels } = await PushNotifications.listChannels();

    if (!channels.some((channel) => channel.id === 'common')) {
      await PushNotifications.createChannel({
        id: 'common',
        name: '일반 알림',
        description: '일반 알림',
        importance: 4,
        lights: true,
        lightColor: '#ff4c39',
        vibration: true,
      });
    }

    if (!channels.some((channel) => channel.id === 'marketing')) {
      await PushNotifications.createChannel({
        id: 'marketing',
        name: '마케팅 알림',
        description: '마케팅 알림',
        importance: 4,
        lights: true,
        lightColor: '#ff4c39',
        vibration: true,
      });
    }
  }
}
