import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DevLockService, LockState } from '../../services/dev-lock';

const MOVIE_CAP_REGEX = /^\/movie\/([^/]+)(?:\/([0-9]+))?$/;
const SUB_CAP_REGEX = /^\/sub-cap\/([0-9a-f]+)$/;

@Injectable({
  providedIn: 'root'
})
export class DevLockGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private devLockService: DevLockService,
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.devLockService.lockState === LockState.LOCKED) {
      this.router.navigate(['/dev-lock'], {
        queryParams: { r: state.url },
        state: { rState: this.router.getCurrentNavigation()?.extras.state },
        skipLocationChange: true,
      });
      return false;
    } else if (this.devLockService.lockState === LockState.MOVIE_CAP_ONLY) {
      if (!MOVIE_CAP_REGEX.test(state.url)) {
        return false;
      }
    } else if (this.devLockService.lockState === LockState.SUB_CAP_ONLY) {
      if (!SUB_CAP_REGEX.test(state.url)) {
        return false;
      }
    }

    return true;
  }
}
