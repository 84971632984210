import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { map, Observable, tap } from 'rxjs';
import { TicketReceivedModalComponent, TicketReceivedModalParams } from '../../components/ticket-received-modal';
import { TicketReceivedSingleModalComponent, TicketReceivedSingleModalParams } from '../../components/ticket-received-single-modal';
import { TicketReceived } from '../../types/client.type';
import { openModal } from '../../utils/open-modal';
import { FirebaseAnalyticsService } from '../firebase-analytics';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {
  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
  ) {}

  logTicketReceived(receivedTickets: Array<TicketReceived>): void {
    const paidAmount = receivedTickets.filter((item) => item.type === 'paid').reduce((acc, cur) => acc + cur.ticket.total_amount, 0);
    const freeAmount = receivedTickets.filter((item) => item.type === 'free').reduce((acc, cur) => acc + cur.ticket.total_amount, 0);
    const passAmount = receivedTickets.filter((item) => item.type === 'pass').reduce((acc, cur) => acc + cur.ticket.total_amount, 0);

    if (paidAmount > 0) {
      this.firebaseAnalyticsService.logEvent('earn_virtual_currency', {
        virtual_currency_name: 'ticket_paid',
        value: paidAmount,
      });
    }
    if (freeAmount > 0) {
      this.firebaseAnalyticsService.logEvent('earn_virtual_currency', {
        virtual_currency_name: 'ticket_free',
        value: freeAmount,
      });
    }
    if (passAmount > 0) {
      this.firebaseAnalyticsService.logEvent('earn_virtual_currency', {
        virtual_currency_name: 'ticket_pass',
        value: passAmount,
      });
    }
  }

  showTicketReceivedSingleModal(
    receivedTicket: TicketReceived,
    options?: {
      templates?: { header?: string; message?: string; button?: string; };
    },
  ): Observable<void> {
    const componentProps: TicketReceivedSingleModalParams = {
      headerTemplate: options?.templates?.header,
      messageTemplate: options?.templates?.message,
      buttonTemplate: options?.templates?.button,
      receivedTicket,
    };
    return openModal(this.modalCtrl, {
      component: TicketReceivedSingleModalComponent,
      componentProps,
      cssClass: 'modal-transparent',
    }).pipe(
      map(() => undefined),
    );
  }

  showTicketReceivedModal(
    receivedTickets: Array<TicketReceived>,
    options?: {
      templates?: { header?: string; message?: string; button?: string; };
      navigateToMainOnDismiss?: boolean;
    },
  ): Observable<void> {
    const navigateToMainOnDismiss = options?.navigateToMainOnDismiss ?? false;
    const componentProps: TicketReceivedModalParams = {
      headerTemplate: options?.templates?.header,
      messageTemplate: options?.templates?.message ?? '프로모션 참여로 ${type}${을} 지급드렸어요\n지금 바로 무비캡을 감상할 수 있어요',
      buttonTemplate: options?.templates?.button ?? (navigateToMainOnDismiss ? '무비캡 감상하러 가기' : undefined),
      ticketFreeTemplate:
        '<span class="modal-highlight">All</span> 작품 무료 감상 티켓 <span class="modal-highlight">${amount}</span>장',
      ticketPassTemplate:
        '<span class="modal-highlight">All</span> 작품 이용권 <span class="modal-highlight">${amount}</span>장',
      ticketPassWithWorkTemplate:
        'Only <span class="modal-highlight">${relatedWorkTitle}</span> 이용권 <span class="modal-highlight">${amount}</span>장',
      receivedTickets,
    };
    return openModal(this.modalCtrl, {
      component: TicketReceivedModalComponent,
      componentProps,
      cssClass: 'modal-transparent',
    }).pipe(
      map(() => undefined),
      tap(() => {
        if (navigateToMainOnDismiss) {
          setTimeout(() => {
            this.navCtrl.navigateForward('/');
          }, 100);
        }
      }),
    );
  }
}
