import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 * 일주일 간 무제한 액세스 가능한 코드
 */
const CODE_WEEK_FULL = [
  'ydeas-p@ss@@',
];

/**
 * 일회용 무제한 액세스 가능한 코드
 */
const CODE_ONE_TIME_FULL = [
  'YDEAS123',
  '0611@@',
];

/* eslint-disable quote-props */
/**
 * 일회용 부분적 액세스 가능한 코드
 */
const CODE_ONE_TIME_MOVIE_CAP_ONLY: { [key: string]: Date } = {
  '830273': new Date('2022-05-31T23:59:59+0900'),
  '767881': new Date('2022-06-30T23:59:59+0900'),
  '458868': new Date('2022-07-31T23:59:59+0900'),
  '324547': new Date('2022-08-31T23:59:59+0900'),
};
/**
 * 일회용 부분적 액세스 가능한 코드
 */
const CODE_ONE_TIME_SUB_CAP_ONLY: { [key: string]: Date } = {
  '394224': new Date('2022-05-31T23:59:59+0900'),
  '948373': new Date('2022-06-30T23:59:59+0900'),
  '389512': new Date('2022-07-31T23:59:59+0900'),
  '692353': new Date('2022-08-31T23:59:59+0900'),
};
/* eslint-enable quote-props */

const MOVIE_CAP_REGEX = /^\/movie\/([^/]+)(?:\/([0-9]+))?$/;
const SUB_CAP_REGEX = /^\/sub-cap\/([0-9a-f]+)$/;

const LOCAL_STORAGE_KEY = 'kr.waikikii.devUnlockExpiry';

/**
 * 잠금 상태
 */
export enum LockState {
  LOCKED = 'locked',
  MOVIE_CAP_ONLY = 'movieCapOnly',
  SUB_CAP_ONLY = 'subCapOnly',
  UNLOCKED = 'unlocked',
}

@Injectable({
  providedIn: 'root'
})
export class DevLockService {
  /**
   * 잠금 상태
   */
  get lockState(): LockState {
    return this.innerLockState;
  }

  private innerLockState: LockState = (environment.production || !environment.serviceWorker) ? LockState.UNLOCKED : LockState.LOCKED;

  constructor() {
    // 잠금 해제 만료일이 지나지 않았으면 잠금 해제
    try {
      const passUntil = window.localStorage.getItem(LOCAL_STORAGE_KEY);
      if (passUntil) {
        const passUntilNum = parseInt(passUntil.split('').reverse().join(''), 10);
        if (Date.now() < passUntilNum) {
          this.innerLockState = LockState.UNLOCKED;
        } else {
          window.localStorage.removeItem(LOCAL_STORAGE_KEY);
        }
      }
    } catch {}
  }

  /**
   * 잠금 해제를 시도합니다.
   * @throws Error
   */
  unlock(code: string, returnUrl?: string): void {
    if (this.lockState !== LockState.LOCKED) {
      return;
    }

    if (CODE_WEEK_FULL.indexOf(code) > -1) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEY,
        (Date.now() + 7 * 24 * 60 * 60 * 1000 + '').split('').reverse().join(''), // 7 days
      );
      this.innerLockState = LockState.UNLOCKED;
    } else if (CODE_ONE_TIME_FULL.indexOf(code) > -1) {
      this.innerLockState = LockState.UNLOCKED;
    } else if (CODE_ONE_TIME_MOVIE_CAP_ONLY[code]) {
      if (Date.now() < CODE_ONE_TIME_MOVIE_CAP_ONLY[code].getTime() && returnUrl?.match(MOVIE_CAP_REGEX)) {
        this.innerLockState = LockState.MOVIE_CAP_ONLY;
      } else {
        throw new Error('잠금 해제 코드 불일치');
      }
    } else if (CODE_ONE_TIME_SUB_CAP_ONLY[code]) {
      if (Date.now() < CODE_ONE_TIME_SUB_CAP_ONLY[code].getTime() && returnUrl?.match(SUB_CAP_REGEX)) {
        this.innerLockState = LockState.SUB_CAP_ONLY;
      } else {
        throw new Error('잠금 해제 코드 불일치');
      }
    } else {
      throw new Error('잠금 해제 코드 불일치');
    }
  }
}
