import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { AlertService } from '../../services/alert';
import { GuardUtilService } from '../../services/guard-util';
import { UserService } from '../../services/user';

@Injectable({
  providedIn: 'root'
})
export class IosUseCouponGuard implements CanActivate {
  constructor(
    private router: Router,
    private guardUtilService: GuardUtilService,
    private alertService: AlertService,
    private userService: UserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (Capacitor.isNative && Capacitor.getPlatform() === 'ios') {
      const code = route.queryParams.code;
      if (code != null) {
        this.useCoupon(code);
      }

      if (!this.guardUtilService.hasRouted) {
        this.router.navigate(['/']);
      }
      return false;
    }

    return true;
  }

  private useCoupon(code: string): void {
    this.userService.useCoupon(code).subscribe(
      () => {},
      (err) => { this.alertService.alertAPIError(err); },
    );
  }
}
