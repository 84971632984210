import { isNotNullish } from './etc.util';

function stringify(value: any): string {
  return (
    value && (typeof value === 'object' || typeof value === 'function') && typeof value.toJSON === 'function'
    ? value.toJSON()
    : JSON.stringify(value)
  ) + '';
}

/**
 * 파라미터 객체를 쿼리 문자열로 변환합니다.
 * @param params 파라미터 객체
 */
export function paramsToQueryString(params: { [key: string]: any }): string;
export function paramsToQueryString(params: { [key: string]: any } | null | undefined): string | null;
export function paramsToQueryString(params: { [key: string]: any } | null | undefined): string | null {
  if (params && typeof params === 'object') {
    return Object.keys(params).map((key) => (
      params[key] !== undefined
      ? encodeURIComponent(key) + '=' + encodeURIComponent(
        (typeof params[key] === 'object')
        ? stringify(params[key])
        : (params[key] + ''),
      )
      : null
    )).filter(isNotNullish).join('&');
  }

  return null;
}

/**
 * 쿼리 문자열을 파라미터 객체로 변환합니다.
 * @param queryString 쿼리 문자열
 */
export function queryStringToParams(queryString: string): { [key: string]: string };
export function queryStringToParams(queryString: string | null | undefined): { [key: string]: string } | null;
export function queryStringToParams(queryString: string | null | undefined): { [key: string]: string } | null {
  if (queryString && typeof queryString === 'string') {
    const res: { [key: string]: string } = {};
    const split = queryString.split('&');
    for (const part of split) {
      const equalIndex = part.indexOf('=');
      res[decodeURIComponent(part.slice(0, equalIndex))] = decodeURIComponent(part.slice(equalIndex + 1));
    }
    return res;
  }

  return null;
}
