import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { NaverLoginLoginResult, NaverLoginPlugin as NaverLoginPluginType } from '../../plugins/naver-login-plugin/definitions';
import { NaverCapacitorMeResult } from './naver-capacitor.type';

const NaverLoginPlugin = Plugins.NaverLoginPlugin as NaverLoginPluginType;

@Injectable({
  providedIn: 'root'
})
export class NaverCapacitorService {
  readonly available = Boolean(NaverLoginPlugin);

  async login(): Promise<NaverLoginLoginResult> {
    return NaverLoginPlugin.login();
  }

  async logout(): Promise<void> {
    return NaverLoginPlugin.logout();
  }

  async logoutAndDeleteToken(): Promise<void> {
    return NaverLoginPlugin.logoutAndDeleteToken();
  }

  async getState(): Promise<string> {
    return (await NaverLoginPlugin.getState()).state;
  }

  async refreshAccessToken(): Promise<NaverLoginLoginResult> {
    return NaverLoginPlugin.refreshAccessToken();
  }

  async requestApi(url: string): Promise<string> {
    return (await NaverLoginPlugin.requestApi({ url })).data;
  }

  async requestMe(): Promise<NaverCapacitorMeResult> {
    return JSON.parse((await NaverLoginPlugin.requestApi({ url: 'https://openapi.naver.com/v1/nid/me' })).data);
  }
}
