import { Injectable } from '@angular/core';

import { NaverWeb, LoginWithNaverIdSettings, LoginWithNaverId, AccessToken } from './naver-web.type';

declare global {
  interface Window {
    /**
     * 네이버 SDK
     */
    naver: NaverWeb;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NaverWebService {
  /**
   * 네이버 로그인 라이브러리 초기화 완료 Promise
   */
  naverLoginLoadPromise: Promise<LoginWithNaverId>;

  /**
   * 네이버 SDK
   */
  private naverJS: NaverWeb;
  /**
   * 네이버 아이디로 로그인 인스턴스
   */
  private loginWithNaverId: LoginWithNaverId | null = null;

  private naverLoginLoadPromiseResolve?: (loginWithNaverId: LoginWithNaverId) => void;

  constructor() {
    this.naverJS = window.naver;
    this.naverLoginLoadPromise = new Promise((resolve) => {
      this.naverLoginLoadPromiseResolve = resolve;
    });
  }

  /**
   * 서비스를 초기화합니다.
   * @param settings 네이버 아이디로 로그인 설정
   */
  init(settings: LoginWithNaverIdSettings): void {
    if (!this.naverJS) {
      throw new Error('Naver is undefined or null');
    }

    // 네이버 아이디로 로그인 초기화
    this.loginWithNaverId = new this.naverJS.LoginWithNaverId(settings);
    this.loginWithNaverId.init();
    this.loginWithNaverId.getLoginStatus(() => {
      this.naverLoginLoadPromiseResolve?.(this.loginWithNaverId!);
    });
  }

  /**
   * 네이버 아이디로 로그인 인스턴스
   */
  get login(): LoginWithNaverId | null {
    return this.loginWithNaverId;
  }

  set login(v: LoginWithNaverId | null) {
    this.loginWithNaverId = v;
  }

  /**
   * 네이버 아이디로 로그인 상태를 가져옵니다.
   */
  async getLoginStatus(): Promise<boolean> {
    const loginWithNaverId = await this.naverLoginLoadPromise;

    return new Promise<boolean>(
      (resolve) => loginWithNaverId.getLoginStatus(
        (loginStatus) => resolve(loginStatus),
      ),
    );
  }

  /**
   * 액세스 토큰을 네이버 아이디로 로그인에 연결합니다.
   * @param accessToken 액세스 토큰
   * @param expires 만료 시간 (초)
   */
  async loginWithAccessToken(accessToken: string, expires: number): Promise<boolean> {
    const loginWithNaverId = await this.naverLoginLoadPromise;

    loginWithNaverId.accessToken = {
      accessToken,
      expires: Math.floor(Date.now() / 1000) + expires,
      ttl: expires
    };
    localStorage.setItem('com.naver.nid.access_token', 'bearer.' + accessToken + '.' + expires);
    return new Promise<boolean>((resolve) => {
      loginWithNaverId.getLoginStatus((status) => {
        resolve(status);
      });
    });
  }

  /**
   * 로그아웃 후 네이버 아이디로 로그인 인스턴스를 정리합니다.
   */
  logout(): void {
    if (this.loginWithNaverId == null) {
      return;
    }

    this.loginWithNaverId.logout();
    (this.loginWithNaverId as any).accessToken = null;
    (this.loginWithNaverId as any).loginStatus.accessToken = null;
    (this.loginWithNaverId as any).loginStatus.status = false;
    (this.loginWithNaverId as any).loginStatus.naverUser = null;
    if (this.loginWithNaverId.user) {
      delete this.loginWithNaverId.user;
    }
  }

  /**
   * 네이버 로그인 팝업을 띄웁니다.
   */
  async openLoginPopup(): Promise<AccessToken> {
    const loginWithNaverId = await this.naverLoginLoadPromise;

    return new Promise<AccessToken>((resolve, reject) => {
      const win = window.open(
        loginWithNaverId.generateAuthorizeUrl(),
        '',
        `titlebar=1, resizable=1, scrollbars=yes, width=500, height=700`
      );

      // postMessage로 오는 로그인 정보 리스너
      const listener = async (ev: MessageEvent) => {
        if (ev.source === win) {
          const naverLoggedIn = await this.loginWithAccessToken(ev.data.access_token, parseInt(ev.data.expires_in, 10));
          if (naverLoggedIn) {
            resolve(loginWithNaverId.accessToken);
          } else {
            reject(new Error('Failed to login to Naver'));
          }
          window.removeEventListener('message', listener);
        }
      };

      // 팝업 닫힘 감지
      if (win != null) {
        const detectClose = setInterval(() => {
          if (win.closed) {
            clearInterval(detectClose);
            setTimeout(() => {
              window.removeEventListener('message', listener);
              reject(new Error('User closed the Naver login window'));
            }, 3000);
          }
        }, 1000);

        window.addEventListener('message', listener);
      }
    });
  }

  /**
   * 권한 재동의 팝업을 띄웁니다.
   */
  async openRepromptPopup(): Promise<AccessToken> {
    const login = await this.naverLoginLoadPromise;

    const generateAuthorizeUrlReprompt = () => {
      login.generateState();
      let temp = 'https://nid.naver.com/oauth2.0/authorize?response_type=token';
      temp += '&client_id=' + login.clientId;
      temp += '&state=' + login.state;
      temp += '&redirect_uri=' + encodeURIComponent(login.callbackUrl);
      temp += '&version=js-' + login.version;
      temp += '&auth_type=reprompt';
      if (!1 === login.isPopup) {
        temp += '&svctype=1';
      }
      return temp;
    };

    return new Promise<AccessToken>((resolve, reject) => {
      const win = window.open(
        generateAuthorizeUrlReprompt(),
        '',
        `titlebar=1, resizable=1, scrollbars=yes, width=500, height=700`
      );

      const listener = async (ev: MessageEvent) => {
        if (ev.source === win) {
          if (!ev.data.error) {
            resolve({
              accessToken: ev.data.access_token,
              expires: Math.floor(Date.now() / 1000) + ev.data.expires_in,
              ttl: ev.data.expires_in
            });
          } else {
            reject(new Error(ev.data.error_description || 'Failed to get permission'));
          }
          window.removeEventListener('message', listener);
        }
      };

      if (win != null) {
        const detectClose = setInterval(() => {
          if (win.closed) {
            clearInterval(detectClose);
            setTimeout(() => {
              window.removeEventListener('message', listener);
              reject(new Error('User closed the Naver login window'));
            }, 3000);
          }
        }, 1000);

        window.addEventListener('message', listener);
      }
    });

  }
}
