import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { DateTime } from 'luxon';
import { NaverLoginLoginResult } from '../../plugins/naver-login-plugin/definitions';
import { NaverCapacitorService } from '../naver-capacitor';
import { NaverWebService } from '../naver-web/naver-web.service';
import { StorageService } from '../storage';
import { NaverUserInfo } from './naver.type';

@Injectable({
  providedIn: 'root'
})
export class NaverService {
  /**
   * 이용 가능 여부
   */
  get available(): boolean {
    return (this.native && this.naverCapacitorService.available) || !this.native;
  }

  /**
   * 네이티브 여부
   */
  private native: boolean;

  constructor(
    private storageService: StorageService,
    private naverWeb: NaverWebService,
    private naverCapacitorService: NaverCapacitorService,
  ) {
    this.native = Capacitor.isNative ?? false;
  }

  /**
   * 서비스를 초기화합니다.
   * @param key 네이버 아이디로 로그인 키
   */
  init(key: string): void {
    this.naverWeb.init({
      clientId: key,
      callbackUrl:
        `${location.protocol}//${location.hostname}${location.port && location.port !== '80' ? ':' + location.port : ''}/oauth-naver`,
      isPopup: false,
    });
  }

  /**
   * 네이버 아이디로 로그인 화면을 표시합니다.
   */
  async login(): Promise<string> {
    if (!this.available) {
      return Promise.reject(new Error('Naver API is not available'));
    }

    let accessToken: string;
    if (this.native) {
      const res = await this.naverCapacitorService.login();
      await this.storageService.setItem('naver.cordova.login', res);
      accessToken = res.accessToken;
    } else {
      accessToken = (await this.naverWeb.openLoginPopup()).accessToken;
    }

    return accessToken;
  }

  /**
   * 로그아웃합니다.
   */
  async logout(): Promise<void> {
    if (!this.available) {
      return Promise.reject(new Error('Naver API is not available'));
    }

    if (this.native) {
      await this.naverCapacitorService.logout();
      await this.storageService.removeItem('naver.cordova.login');
    } else {
      this.naverWeb.logout();
    }
  }

  /**
   * 사용자 정보를 가져옵니다.
   */
  async getUserInfo(): Promise<NaverUserInfo> {
    if (!this.available) {
      return Promise.reject(new Error('Naver API is not available'));
    }

    let info: NaverUserInfo | null = null;
    if (this.native) {
      const cordovaInfo = (await this.naverCapacitorService.requestMe()).response;
      if (cordovaInfo) {
        info = {
          id: cordovaInfo.id,
          nickname: cordovaInfo.nickname,
          name: cordovaInfo.name,
          email: cordovaInfo.email,
          gender: cordovaInfo.gender,
          age: cordovaInfo.age,
          birthday: cordovaInfo.birthday,
          profileImage: cordovaInfo.profile_image,
        };
      }
    } else if (this.naverWeb.login != null) {
      const webInfo = this.naverWeb.login.user || null;
      if (webInfo) {
        info = {
          id: webInfo.getId(),
          nickname: webInfo.getNickName(),
          name: webInfo.getName(),
          email: webInfo.getEmail(),
          gender: webInfo.getGender(),
          age: webInfo.getAge(),
          birthday: webInfo.getBirthday(),
          profileImage: webInfo.getProfileImage(),
        };
      }
    }

    if (info == null) {
      throw new Error('Cannot get user info');
    }
    return info;
  }

  /**
   * 액세스 토큰을 가져옵니다.
   */
  async getAccessToken(): Promise<string | null> {
    if (!this.available) {
      return Promise.reject(new Error('Naver API is not available'));
    }

    if (this.native) {
      const loginInfo: NaverLoginLoginResult | null = await this.storageService.getItem('naver.cordova.login');

      if (loginInfo == null) {
        return null;
      }

      if (DateTime.local() < DateTime.fromMillis(loginInfo.expiresAt)) {
        return loginInfo.accessToken;
      } else {
        const newLoginInfo = await this.naverCapacitorService.refreshAccessToken();
        await this.storageService.setItem('naver.cordova.login', newLoginInfo);
        return newLoginInfo.accessToken;
      }
    } else if (this.naverWeb.login != null) {
      return  this.naverWeb.login && this.naverWeb.login.accessToken
        ? this.naverWeb.login.accessToken.accessToken
        : null;
    }
    return null;
  }

  /**
   * 권한 재동의 화면을 표시합니다.
   */
  async openRepromptPopup(): Promise<string> {
    if (!this.available) {
      return Promise.reject(new Error('Naver API is not available'));
    }

    if (this.native) {
      // 안드로이드 SDK에서는 재동의 프로세스를 사용할 수 없으므로 연동 해제 후 재로그인
      // https://developers.naver.com/forum/posts/28262
      // https://developers.naver.com/forum/posts/28923
      await this.naverCapacitorService.logoutAndDeleteToken();
      return this.login();
    } else {
      return (await this.naverWeb.openRepromptPopup()).accessToken;
    }
  }
}
