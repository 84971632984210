import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import '@codetrix-studio/capacitor-google-auth';
import { GoogleAuthPlugin } from '@codetrix-studio/capacitor-google-auth';
import { User } from '@codetrix-studio/capacitor-google-auth/dist/esm/user';
import { GoogleUserInfo } from './google.type';

const GoogleAuth = Plugins.GoogleAuth as GoogleAuthPlugin;

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  /**
   * 이용 가능 여부
   */
  get available(): boolean {
    return Boolean(GoogleAuth);
  }

  /**
   * 마지막 로그인 시 가져온 유저 정보
   */
  private lastUser?: User;

  /**
   * 구글 로그인 화면을 표시합니다.
   */
  async login(): Promise<string> {
    if (!this.available) {
      return Promise.reject(new Error('Google API is not available'));
    }

    this.lastUser = await GoogleAuth.signIn();
    return this.lastUser.authentication?.accessToken;
  }

  /**
   * 로그아웃합니다.
   */
  async logout(): Promise<void> {
    if (!this.available) {
      return Promise.reject(new Error('Google API is not available'));
    }

    let result = false;
    try {
      await GoogleAuth.signOut();
      result = true;
    } catch {}

    if (!result) {
      return Promise.reject(new Error('Failed to logout from Google'));
    }
  }

  /**
   * 사용자 정보를 가져옵니다.
   */
  async getUserInfo(): Promise<GoogleUserInfo | null> {
    if (!this.available) {
      return Promise.reject(new Error('Google API is not available'));
    }

    return this.lastUser ? {
      id: this.lastUser.id,
      email: this.lastUser.email,
      name: this.lastUser.name || (this.lastUser as any).displayName,
    } : null;
  }

  /**
   * 액세스 토큰을 가져옵니다.
   */
  async getAccessToken(): Promise<string | null> {
    if (!this.available) {
      return Promise.reject(new Error('Google API is not available'));
    }

    return this.lastUser?.authentication?.accessToken ?? null;
  }
}
