import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  AccountCompletePurchaseResult,
  AccountMakePurchaseResultIapIos,
  AccountMakePurchaseResultKakaopay,
  AccountMakePurchaseResultPayco,
  AccountRefundTicketResult
} from '../../types/api/account.type';
import { TicketBuyOption } from '../../types/client.type';
import { ApiService } from '../api/api.service';
import { FirebaseAnalyticsService } from '../firebase-analytics';
import { PromotionService } from '../promotion';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  constructor(
    private platform: Platform,
    private apiService: ApiService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private promotionService: PromotionService,
  ) {}

  /**
   * 티켓 구매 옵션 목록을 가져옵니다.
   */
  ticketBuyOptionList(platform?: 'web' | 'ios'): Observable<Array<TicketBuyOption>> {
    return this.apiService.accountV1TicketBuyOptionList(platform ? { platform } : undefined).pipe(
      map((res) => res.result.list),
    );
  }

  /**
   * 신용카드 결제 전 티켓 구매 요청을 합니다.
   *
   * 구매 ID를 가져옵니다.
   * @param ticketBuyOptionId 티켓 구매 옵션 ID
   */
  makePurchaseCard(ticketBuyOptionId: string): Observable<string> {
    return this.apiService.accountV1MakePurchase_Card({ ticketBuyOptionId, method: 'card' }).pipe(
      map((res) => res.result.userPurchaseUid),
    );
  }

  /**
   * 카카오페이 결제 전 티켓 구매 요청을 합니다.
   *
   * 구매 ID를 가져옵니다.
   * @param ticketBuyOptionId 티켓 구매 옵션 ID
   */
  makePurchaseKakaopay(params: {
    ticketBuyOptionId: string;
    approvalUrl: string;
    cancelUrl: string;
    failUrl: string;
  }): Observable<AccountMakePurchaseResultKakaopay> {
    return this.apiService.accountV1MakePurchase_Kakaopay({ ...params, method: 'kakaopay' }).pipe(
      map((res) => res.result),
    );
  }

  makePurchaseIapIos(params: {
    receipt: string;
  }): Observable<AccountMakePurchaseResultIapIos> {
    return this.apiService.accountV1MakePurchase_IapIos({ ...params, method: 'iap-ios' }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 페이코 결제 전 티켓 구매 요청을 합니다.
   *
   * 구매 ID를 가져옵니다.
   * @param ticketBuyOptionId 티켓 구매 옵션 ID
   */
  makePurchasePayco(params: {
    ticketBuyOptionId: string;
    returnUrl: string;
  }): Observable<AccountMakePurchaseResultPayco> {
    return this.apiService.accountV1MakePurchase_Payco({
      ...params,
      method: 'payco',
      isMobile: this.platform.is('mobile'),
      isApp: Capacitor.isNative ?? false,
    }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 페이코 결제 전 티켓 구매 요청을 합니다.
   *
   * 구매 ID를 가져옵니다.
   * @param ticketBuyOptionId 티켓 구매 옵션 ID
   */
  makePurchasePaycoAlpha(params: {
    ticketBuyOptionId: string;
    returnUrl: string;
  }): Observable<AccountMakePurchaseResultPayco> {
    return this.apiService.accountV1MakePurchase_PaycoAlpha({
      ...params,
      method: 'payco-alpha',
      isMobile: this.platform.is('mobile'),
      isApp: Capacitor.isNative ?? false,
    }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 결제 후 결제 검증을 요청합니다.
   * @param userPurchaseUid 결제 ID
   */
  completePurchase(
    userPurchaseUid: string,
    additionalData: { transactionId: string; } | { pgToken: string; } | { reserveOrderNo: string; paymentCertifyToken: string; },
  ): Observable<AccountCompletePurchaseResult> {
    return this.apiService.accountV1CompletePurchase({ userPurchaseUid, ...additionalData }).pipe(
      map((res) => res.result),
      tap((result) => {
        if (result.purchaseInfo) {
          this.firebaseAnalyticsService.logEvent('purchase', result.purchaseInfo);
        }
        const { receivedTickets } = result;
        if (receivedTickets != null) {
          this.promotionService.logTicketReceived(receivedTickets);
        }
      }),
    );
  }

  /**
   * 결제 후 결제 검증을 요청합니다.
   * @param userPurchaseUid 결제 ID
   */
  refundTicket(userPurchaseUid: string): Observable<AccountRefundTicketResult> {
    return this.apiService.accountV1RefundTicket({ userPurchaseUid }).pipe(
      map((res) => res.result),
      tap((result) => {
        if (result.refundInfo) {
          this.firebaseAnalyticsService.logEvent('refund', result.refundInfo);
        }
      }),
    );
  }

  /**
   * 구매 요청을 취소합니다.
   * @param userPurchaseUid 결제 ID
   */
  cancelPurchase(userPurchaseUid: string): Observable<void> {
    return this.apiService.accountV1CancelPurchase({ userPurchaseUid }).pipe(
      map((res) => res.result),
    );
  }
}
