export const environment = {
  production: false,
  serviceWorker: true,
  apiUrl: 'https://api-dev.waikikii.net',
  shareBaseUrl: 'https://dev.waikikii.kr',
  googleAnalytics: 'G-5B93YXE66F',
  naverAnalytics: 'b8712cc4b8f798',
  kakaoKey: '5c6fba571eaca48e4731eb90de4735c6',
  naverKey: '06wKfTRMHOP9ik5KxzP5',
  bootpayWebId: '5fd6fd312fa5c2002f0386c0',
  googleOAuth: '287410423857-bs57c717bnkk5qn9oh2nsdn8gl5s0505.apps.googleusercontent.com',
  firebaseConfig: {
    apiKey: 'AIzaSyCNRN4qdj7o-ZU7IS_xW2FrIqpd8R-vPTw',
    authDomain: 'waikiki-dev.firebaseapp.com',
    projectId: 'waikiki-dev',
    storageBucket: 'waikiki-dev.appspot.com',
    messagingSenderId: '287410423857',
    appId: '1:287410423857:web:7c58ddd5c50e9587fd2d10',
    measurementId: 'G-97HMKZ9PKY',
  },
  facebookAppId: '',
  facebookPixelId: '',
  appDownloadLinkQR: 'https://link.waikikii.kr/l/dlQR',
};
