function hasJongseong(char: string): boolean {
  const charCode = char.charCodeAt(char.length - 1);
  return charCode >= 0xAC_00 && charCode <= 0xD7_A3 && (charCode - 0xAC_00) % 28 > 0;
}

function getJongseongIndex(char: string): number | null {
  const charCode = char.charCodeAt(char.length - 1);
  return charCode >= 0xAC_00 && charCode <= 0xD7_A3 ? (charCode - 0xAC_00) % 28 : null;
}

export function josa(word: string, josaWord: string): string {
  switch (josaWord) {
    case '은':
    case '는':
    case '은는':
    case '은/는':
      return word + (hasJongseong(word) ? '은' : '는');
    case '을':
    case '를':
    case '을를':
    case '을/를':
      return word + (hasJongseong(word) ? '을' : '를');
    case '이':
    case '가':
    case '이가':
    case '이/가':
      return word + (hasJongseong(word) ? '이' : '가');
    case '으로':
    case '로':
    case '으로로':
    case '으로/로':
      return word + (hasJongseong(word) && getJongseongIndex(word) !== 8 ? '으로' : '로');
    case '과':
    case '와':
    case '과와':
    case '과/와':
      return word + (hasJongseong(word) ? '과' : '와');
    case '이나':
    case '나':
    case '이나나':
    case '이나/나':
      return word + (hasJongseong(word) ? '이나' : '나');
    case '이라도':
    case '라도':
    case '이라도라도':
    case '이라도/라도':
      return word + (hasJongseong(word) ? '이라도' : '라도');
    case '으로서':
    case '로서':
    case '으로서로서':
    case '으로서/로서':
      return word + (hasJongseong(word) && getJongseongIndex(word) !== 8 ? '으로서' : '로서');
    case '이나마':
    case '나마':
    case '이나마나마':
    case '이나마/나마':
      return word + (hasJongseong(word) ? '이나마' : '나마');
    default:
  }
  return word;
}
