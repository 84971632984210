declare module '@capacitor/core' {
  interface PluginRegistry {
    ExpandPlugin: ExpandPluginPlugin;
  }
}

export interface ExpandPluginPlugin {
  setExpandMode(options: ExpandPluginSetExpandModeOptions): Promise<void>;
  getExpandMode(): Promise<ExpandPluginGetExpandModeResult>;
  setFullscreenMode(options: ExpandPluginSetFullscreenModeOptions): Promise<void>;
  setDarkMode(options: ExpandPluginSetDarkModeOptions): Promise<void>;
  getForceDarkMode(): Promise<ExpandPluginGetForceDarkModeResult>;
  setStatusBarStyle(options: ExpandPluginSetStatusBarStyleOptions): Promise<void>;
  getStatusBarStyle(): Promise<ExpandPluginGetStatusBarStyleResult>;
}

export enum ExpandMode {
  DISABLED = 'disabled',
  TRANSLUCENT = 'translucent',
  TRANSPARENT = 'transparent',
}

export enum DarkMode {
  OFF = 'off',
  AUTO = 'auto',
  ON = 'on',
}

export enum StatusBarStyle {
  DEFAULT = 'default',
  LIGHT = 'light',
  DARK = 'dark',
}

export interface ExpandPluginSetExpandModeOptions {
  mode: ExpandMode;
}
export interface ExpandPluginGetExpandModeResult {
  mode: ExpandMode;
}
export interface ExpandPluginSetFullscreenModeOptions {
  fullscreen: boolean;
}

export interface ExpandPluginSetDarkModeOptions {
  mode: DarkMode;
}
export interface ExpandPluginGetForceDarkModeResult {
  mode: DarkMode;
}

export interface ExpandPluginSetStatusBarStyleOptions {
  style: StatusBarStyle;
}

export interface ExpandPluginGetStatusBarStyleResult {
  style: StatusBarStyle;
}
