import { defer, Observable } from 'rxjs';

export const fromResize = (target: Element | Array<Element>, options?: ResizeObserverOptions): Observable<Array<ResizeObserverEntry>> =>
  defer(() => new Observable<Array<ResizeObserverEntry>>((subscriber) => {
    const mo = new ResizeObserver((entries) => {
      subscriber.next(entries);
    });
    if (Array.isArray(target)) {
      for (const t of target) {
        mo.observe(t, options);
      }
    } else {
      mo.observe(target, options);
    }
    return () => {
      mo.disconnect();
    };
  }));
