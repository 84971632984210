import { Injectable } from '@angular/core';
import { NavigationBehaviorOptions, NavigationEnd, RouteConfigLoadEnd, Router, UrlTree } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Capacitor, Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, filter, firstValueFrom, Observable, share, skip } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ROUTER_NAVIGATING } from '../../constants/router-navigating.constant';
import { ModalPopGuard } from '../../guards/modal-pop';
import { AlertService } from '../alert';
import { APIError, ApiService } from '../api';
import { AppDownloadService } from '../app-download';
import { AuthService } from '../auth';
import { RenewTokenInfo } from '../auth/auth.type';
import { BackButtonService } from '../back-button';
import { DeepLinkService } from '../deep-link';
import { DarkMode, ExpandMode, ExpandService } from '../expand';
import { FontService } from '../font';
import { GuardUtilService } from '../guard-util';
import { InAppPurchaseService } from '../in-app-purchase';
import { KakaoService } from '../kakao';
import { NaverService } from '../naver/naver.service';
import { PushNotificationService } from '../push-notification';
import { UserService } from '../user';
import { VersionService } from '../version';
import { VersionInfo } from '../version/version.type';
import { BootOptions } from './boot.type';

const { SplashScreen } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class BootService {
  get isBooted(): boolean {
    return this.bootSubject.value;
  }

  get bootObservable(): Observable<boolean> {
    return this.bootSubject.asObservable();
  }

  get bootStateObservable(): Observable<string> {
    return this.bootStateSubject.asObservable();
  }

  private bootSubject = new BehaviorSubject<boolean>(false);
  private bootStateSubject = new BehaviorSubject<string>('초기화 중...');
  private bootPromise?: Promise<void>;

  private isAuthed = false;
  private routerNavigationEnd = this.router.events.pipe(
    filter((ev): ev is NavigationEnd => ev instanceof NavigationEnd),
    share(),
  );

  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private platform: Platform,
    private authService: AuthService,
    private backButtonService: BackButtonService,
    private deepLinkService: DeepLinkService,
    private expandService: ExpandService,
    private pushNotificationService: PushNotificationService,
    private inAppPurchaseService: InAppPurchaseService,
    private guardUtilService: GuardUtilService,
    private fontService: FontService,
    private apiService: ApiService,
    private userService: UserService,
    private versionService: VersionService,
    private alertService: AlertService,
    private kakaoService: KakaoService,
    private naverService: NaverService,
    private appDownloadService: AppDownloadService,
  ) {}

  async boot(bootOptions: BootOptions): Promise<void> {
    if (!this.bootPromise) {
      this.bootPromise = this.innerBoot(bootOptions).then(() => {
        this.bootSubject.next(true);
      }, (err) => {
        this.bootSubject.next(true);
        throw err;
      });
    }

    await this.bootPromise;
  }

  private async innerBoot(bootOptions: BootOptions): Promise<void> {
    try {
      this.bootStateSubject.next('앱 초기화 중...');
      this.deepLinkService.enable();
      await this.platform.ready();
      this.expandService.setExpandMode(ExpandMode.TRANSLUCENT).catch(() => {});
      this.expandService.setDarkMode(DarkMode.ON).catch(() => {});
      this.pushNotificationService.init();
      this.backButtonService.init(bootOptions.ionRouterOutlet);
      this.initInAppPurchase();
      this.enableServiceWorkerUpdate();

      this.bootStateSubject.next('초기화 중...');
      this.enableDismissModalOnBack();
      this.guardUtilService.init();
      this.enableFacebookPixel(bootOptions.facebookPixelId, bootOptions.facebookAppId);
      this.enableFacebookSdk(bootOptions.facebookAppId);
      this.setupNaverAnalytics(bootOptions.naverAnalytics);
      this.fontService.init();
      this.blockImageVideoSave();
      this.initExternalAuth();
      this.enableLogoutOnUnauthorized();

      this.bootStateSubject.next('버전 확인 중...');
      await this.preinitAuthService();
      const bootData = (await firstValueFrom(this.apiService.accountV1Boot({ platform: Capacitor.getPlatform() }))).result;
      await this.checkUpdate({
        latestVersion: bootData.latestVersion ?? null,
        requiredMinVersion: bootData.requiredMinVersion ?? null,
      });
      await this.initAuthService({
        accessToken: bootData.accessToken,
        receivedTicketFreeAmount: bootData.receivedTicketFreeAmount,
        receivedTicket: bootData.receivedTicket,
        receivedTickets: bootData.receivedTickets,
      });

      this.bootStateSubject.next('불러오는 중...');
      this.appDownloadService.openAppDownloadModal({ bottomDrawer: true }).subscribe();
      setTimeout(() => {
        SplashScreen.hide();
      }, 1000);
      await new Promise((resolve) => requestAnimationFrame(resolve));
    } catch (err) {
      // 문제 발생 시 사용자에게 alert를 표시해주기 위해 스플래시 화면 숨김
      requestAnimationFrame(() => {
        SplashScreen.hide();
      });

      await (err instanceof APIError ?
        this.alertService.alertAPIError(err) :
        this.alertService.alert('알림', '문제가 발생했습니다.')
      ).then(() => {
        if (Capacitor.isNative) {
          // App.exitApp(); // Error: Method not implemented.
          (navigator as any).app.exitApp();
        } else {
          window.location.reload();
        }
      });
    }
  }

  /**
   * 인 앱 결제를 초기화합니다.
   */
  private initInAppPurchase(): void {
    if (Capacitor.isNative && Capacitor.getPlatform() === 'ios') {
      this.inAppPurchaseService.init().then(() => {
        // IAP Ready
      }, (err) => {
        console.error(err);
      });
    }
  }

  /**
   * 서비스 워커를 통한 자동 업데이트를 활성화합니다.
   * 업데이트가 가능하면 업데이트를 진행한 후 새로고침 합니다.
   */
  private enableServiceWorkerUpdate(): void {
    if (environment.serviceWorker) {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.versionUpdates.pipe(
          filter((ev) => ev.type === 'VERSION_READY')
        ).subscribe(() => {
          this.swUpdate.activateUpdate().then(() => {
            window.location.reload();
          });
        });
      }
    }
  }

  private enableDismissModalOnBack(): void {
    const originalNavigate = Router.prototype.navigate;
    Router.prototype.navigate = function navigate(this: Router, commands: any[], extras?: NavigationBehaviorOptions) {
      (this as any)[ROUTER_NAVIGATING] = true;
      return originalNavigate.call(this, commands, extras).then((value) => {
        (this as any)[ROUTER_NAVIGATING] = false;
        return value;
      });
    };
    const originalNavigateByUrl = Router.prototype.navigateByUrl;
    Router.prototype.navigateByUrl = function navigateByUrl(this: Router, url: string | UrlTree, extras?: NavigationBehaviorOptions) {
      (this as any)[ROUTER_NAVIGATING] = true;
      return originalNavigateByUrl.call(this, url, extras).then((value) => {
        (this as any)[ROUTER_NAVIGATING] = false;
        return value;
      });
    };

    this.router.events.pipe(
      filter((ev): ev is RouteConfigLoadEnd => ev instanceof RouteConfigLoadEnd),
    ).subscribe((ev) => {
      if (!ev.route.canDeactivate?.includes(ModalPopGuard)) {
        ev.route.canDeactivate = [...ev.route.canDeactivate ?? [], ModalPopGuard];
      }
    });
  }

  private enableFacebookPixel(facebookPixelId: string, facebookAppId: string): void {
    if (!environment.production) {
      return;
    }

    if (Capacitor.isNative && facebookPixelId && facebookAppId) {
      fbq('set', 'mobileBridge', facebookPixelId, facebookAppId);
    }

    this.routerNavigationEnd.subscribe(() => {
      if ('fbq' in window && typeof fbq === 'function') {
        fbq('track', 'PageView');
      }
    });
  }

  private enableFacebookSdk(facebookAppId: string): void {
    if (!facebookAppId || !window.FB) {
      return;
    }

    this.routerNavigationEnd.subscribe(() => {
      window.FB.AppEvents.logPageView();
    });
  }

  /**
   * 네이버 애널리틱스를 활성화합니다.
   * Angular Router에서 NavigationEnd 이벤트가 발생하면 이벤트를 전송합니다.
   * @param naverAnalyticsId 네이버 애널리틱스 ID
   */
  private setupNaverAnalytics(naverAnalyticsId: string): void {
    const win = window as { wcs?: unknown; wcs_add?: { wa?: string; }; wcs_do?: () => void; } & Window & typeof globalThis;

    if (!win.wcs) {
      return;
    }

    win.wcs_add = win.wcs_add || {};
    win.wcs_add.wa = naverAnalyticsId;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        win.wcs_do?.();
      }
    });
  }

  /**
   * 이미지와 동영상의 저장을 방지합니다.
   * 이미지와 비디오의 우클릭을 막고, 이미지의 드래그를 막습니다.
   */
  private blockImageVideoSave(): void {
    // 이미지/비디오 우클릭 방지
    document.addEventListener('contextmenu', (ev) => {
      const tagName = (ev.target as HTMLElement).tagName;
      if (tagName === 'IMG' || tagName === 'VIDEO') {
        ev.preventDefault();
      }
    });

    // 이미지 드래그 방지
    document.addEventListener('dragstart', (ev: DragEvent) => {
      const tagName = (ev.target as HTMLElement).tagName;
      if (
        tagName === 'IMG' &&
        ev.dataTransfer?.getData('text/uri-list') === (ev.target as HTMLImageElement).src
      ) {
        ev.preventDefault();
      }
    });
  }

  /**
   * 인증 서비스를 초기화합니다.
   */
  private async preinitAuthService(): Promise<void> {
    await this.authService.init();
    const accessToken1 = await firstValueFrom(this.authService.getAccessTokenObservable());
    this.isAuthed = !!accessToken1;
    if (this.isAuthed) {
      this.apiService.setAuthToken(accessToken1);
    }
  }

  /**
   * 인증 서비스를 초기화합니다.
   * 로그인 상태 변경 시 게정 정보와 잔액을 업데이트합니다.
   */
  private async initAuthService(renewTokenInfo?: RenewTokenInfo): Promise<void> {
    if (this.isAuthed) {
      await this.authService.renewToken(true, renewTokenInfo);
    }

    this.authService.getAccessTokenObservable().pipe(
      skip(1),
    ).subscribe((accessToken) => {
      this.isAuthed = !!accessToken;
      this.apiService.setAuthToken(accessToken);
      this.userService.updateAccountInfo(this.isAuthed, true);
      this.userService.updateBalance(this.isAuthed, true);
    });
  }

  /**
   * 간편 로그인 서비스를 초기화합니다.
   */
  private initExternalAuth(): void {
    try {
      this.kakaoService.init(environment.kakaoKey);
    } catch {}
    try {
      this.naverService.init(environment.naverKey);
    } catch {}
  }

  /**
   * API 호출 시 401 Unauthorized 오류가 발생하면 로그아웃 처리하고 로그인 페이지로 이동시킵니다.
   */
  private async enableLogoutOnUnauthorized(): Promise<void> {
    this.apiService.getUnauthorizedObservable().subscribe(() => {
      if (this.isAuthed) {
        this.authService.logout().then(() => {
          const urlMatch = this.router.url.match(/^[^?]+/);
          if (urlMatch?.[0] !== '/login') {
            this.router.navigateByUrl(`/login?r=${encodeURIComponent(this.router.url)}`);
          }
        });
      }
    });
  }

  private async checkUpdate(versionInfo?: VersionInfo): Promise<void> {
    const updateRequired = await this.versionService.init(versionInfo);
    while (updateRequired) {
      SplashScreen.hide();
      const yes = await this.alertService.alert('알림', '더 나은 서비스를 제공해드리기 위해 업데이트가 필요합니다.', '업데이트 하러 가기', 'solid');
      if (yes) {
        if (this.platform.is('android')) {
          window.open('https://play.google.com/store/apps/details?id=kr.waikikii.app', '_blank');
        } else if (this.platform.is('ios')) {
          window.open('https://apps.apple.com/kr/app/%EC%99%80%EC%9D%B4%ED%82%A4%ED%82%A4-waikiki/id1564416230', '_blank');
        }
      } else {
        if (Capacitor.isNative) {
          // App.exitApp(); // Error: Method not implemented.
          (navigator as any).app.exitApp();
        } else {
          window.location.reload();
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
        break;
      }
    }
  }
}
