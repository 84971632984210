import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { GuardUtilService } from '../../services/guard-util';

@Injectable({
  providedIn: 'root'
})
export class AppDownloadGuard implements CanActivate {
  constructor(
    private router: Router,
    private platform: Platform,
    private guardUtilService: GuardUtilService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const platform = this.platform.is('android') ? 'android' : this.platform.is('ios') ? 'ios' : null;
    if (!Capacitor.isNative && platform && this.guardUtilService.hasRouted && this.guardUtilService.hasInteracted) {
      if (platform === 'android') {
        window.open('https://play.google.com/store/apps/details?id=kr.waikikii.app', '_blank');
      } else if (platform === 'ios') {
        window.open('https://apps.apple.com/kr/app/id1564416230', '_blank');
      }
      return false;
    }

    // 임시로 iOS에서 /app-download 접속 시 /use-coupon 대신 표시
    if (!Capacitor.isNative && platform === 'ios' && !(this.guardUtilService.hasRouted && this.guardUtilService.hasInteracted)) {
      this.router.navigate(['/use-coupon']);
      return false;
    }

    return true;
  }
}
