import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { IonRouterOutlet, NavController, Platform } from '@ionic/angular';
import { filter, map, Observable, startWith, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { OnHardwareBack } from './back-button.type';

const MAIN_URLS = [
  '/',
  '/.',
  '/dev-lock',
];

const MAIN_TAB_URLS = [
  '/upcoming',
  '/time-flash',
  '/personal',
];

@Injectable({
  providedIn: 'root'
})
export class BackButtonService implements OnDestroy {
  private ngOnDestroySubject = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private platform: Platform,
    private navCtrl: NavController,
  ) {}

  ngOnDestroy(): void {
    this.ngOnDestroySubject.next();
    this.ngOnDestroySubject.complete();
  }

  async init(ionRouterOutlet: IonRouterOutlet): Promise<void> {
    if (Capacitor.getPlatform() !== 'android') {
      return;
    }

    this.fromBackButton(0).pipe(
      withLatestFrom(this.router.events.pipe(
        filter((ev): ev is NavigationEnd => ev instanceof NavigationEnd),
        map(() => ionRouterOutlet.activatedView?.ref),
        startWith(undefined),
      )),
      takeUntil(this.ngOnDestroySubject)
    ).subscribe(([, cmpRef]) => {
      const componentInstance = cmpRef?.instance;
      if (this.isOnHardwareBack(componentInstance)) {
        componentInstance.appOnHardwareBack();
        return;
      }

      const ionBackButton: HTMLIonBackButtonElement | null = cmpRef?.location.nativeElement?.querySelector('ion-back-button');
      if (ionBackButton != null) {
        ionBackButton.click();
        return;
      }

      if (ionRouterOutlet.canGoBack()) {
        this.location.back();
        return;
      }

      if (MAIN_TAB_URLS.includes(this.router.url)) {
        this.navCtrl.navigateBack('/', { replaceUrl: true });
        return;
      }

      if (!MAIN_URLS.includes(this.router.url)) {
        return;
      }

      // App.exitApp(); // Error: Method not implemented.
      (navigator as any).app.exitApp();
    });
  }

  fromBackButton(priority: number): Observable<void> {
    return new Observable<void>((subscriber) => this.platform.backButton.subscribeWithPriority(priority, () => {
      subscriber.next();
    }));
  }

  private isOnHardwareBack(cmp: any): cmp is OnHardwareBack {
    return typeof cmp?.appOnHardwareBack === 'function';
  }
}
