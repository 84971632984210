import { Injectable, OnDestroy } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { ToastController } from '@ionic/angular';
import {
  BehaviorSubject,
  delay,
  distinctUntilChanged,
  from,
  Observable,
  shareReplay,
  skip,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { RemoteDebugPluginPlugin } from '../../plugins/remote-debug-plugin/definitions';
import { StorageService } from '../storage';

const RemoteDebugPlugin = Plugins.RemoteDebugPlugin as RemoteDebugPluginPlugin;

const FORCE_DEV_COMMAND = 'uuddlrlrba';
const FORCE_DEV_STORAGE_KEY = 'waikiki.forceDev';

@Injectable({
  providedIn: 'root'
})
export class ForceDevService implements OnDestroy {
  get enabled(): boolean {
    return this.enabledSubject.value;
  }

  readonly enabled$: Observable<boolean>;

  private ngOnDestroySubject = new Subject<void>();
  private keys: Array<string> = [];
  private enabledSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private toastCtrl: ToastController,
    private storageService: StorageService,
  ) {
    this.enabled$ = from(this.storageService.getItem<boolean>(FORCE_DEV_STORAGE_KEY)).pipe(
      tap((enabled) => {
        this.enabledSubject.next(environment.production && (enabled ?? false));
        if (environment.production && (enabled ?? false)) {
          RemoteDebugPlugin.enable().catch(() => {});
        }
      }),
      switchMap(() => from(this.enabledSubject)),
      distinctUntilChanged(),
      takeUntil(this.ngOnDestroySubject),
      shareReplay(1),
    );

    this.enabled$.pipe(
      skip(1),
      takeUntil(this.ngOnDestroySubject),
    ).subscribe((enabled) => {
      this.toastCtrl.create({ message: enabled ? 'Enabled' : 'Disabled', duration: 2000 }).then((toast) => {
        toast.present();
      });
      if (environment.production) {
        if (enabled) {
          RemoteDebugPlugin.enable().catch(() => {});
        } else {
          RemoteDebugPlugin.disable().catch(() => {});
        }
      }
    });

    this.enabled$.pipe(
      skip(1),
      delay(0),
      takeUntil(this.ngOnDestroySubject),
    ).subscribe((enabled) => {
      this.storageService.setItem(FORCE_DEV_STORAGE_KEY, enabled).then();
    });
  }

  ngOnDestroy(): void {
    this.enabledSubject.next(false);
    this.ngOnDestroySubject.next();
    this.ngOnDestroySubject.complete();
    this.enabledSubject.complete();
  }

  inputCommand(key: string): void {
    this.keys.push(key);

    const lengthDiff = this.keys.length - FORCE_DEV_COMMAND.length;
    if (lengthDiff > 0) {
      this.keys = this.keys.slice(this.keys.length - FORCE_DEV_COMMAND.length);
    }

    if (this.keys.join('') === FORCE_DEV_COMMAND) {
      this.keys = [];
      this.enabledSubject.next(environment.production && !this.enabledSubject.value);
    }
  }
}
