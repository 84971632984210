import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, retryWhen, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as AccountType from '../../types/api/account.type';
import * as CurationType from '../../types/api/curation.type';
import * as NewsType from '../../types/api/news.type';
import * as QnaType from '../../types/api/qna.type';
import * as SearchType from '../../types/api/search.type';
import * as SubCapType from '../../types/api/sub-cap.type';
import * as WorkType from '../../types/api/work.type';
import { DateWrapped } from '../../types/common';
import { unwrapDate } from '../../utils/date-wrapper';
import { decodeJWTPayload } from '../../utils/decode-jwt-payload';
import { paramsToQueryString } from '../../utils/url.util';
import { FirebaseAnalyticsService } from '../firebase-analytics';
import { APIError, APIResponse, APIResponseFailure } from './api.type';

const LOCAL_MODE = false;
const API_URL = LOCAL_MODE ? 'http://localhost:3000' : environment.apiUrl;
const SERVICE = {
  ACCOUNT: LOCAL_MODE ? '/dev' : '/account',
  WORK: LOCAL_MODE ? '/dev' : '/work',
  CURATION: LOCAL_MODE ? '/dev' : '/curation',
  SUB_CAP: LOCAL_MODE ? '/dev' : '/sub-cap',
  NEWS: LOCAL_MODE ? '/dev' : '/news',
  QNA: LOCAL_MODE ? '/dev' : '/qna',
  SEARCH: LOCAL_MODE ? '/dev' : '/search',
};

interface HeadersMap { [header: string]: string | string[]; }

type APIResponseObservable<T> = Observable<APIResponse<T>>;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private authToken: string | null = null;

  private unauthorizedSubject = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
  ) {}

  /* eslint-disable  max-len */
  accountV1Test() { return this.get<AccountType.AccountTestResult>(`${SERVICE.ACCOUNT}/v1/test`); }
  accountV1SignIn(params: AccountType.AccountSignInParams) { return this.post<AccountType.AccountSignInResult>(`${SERVICE.ACCOUNT}/v1/sign-in`, params); }
  accountV1SignInExternal(params: AccountType.AccountSignInExternalParams) { return this.post<AccountType.AccountSignInExternalResult>(`${SERVICE.ACCOUNT}/v1/sign-in-external`, params); }
  accountV1RenewToken() { return this.post<AccountType.AccountRenewTokenResult>(`${SERVICE.ACCOUNT}/v1/renew-token`); }
  accountV1SignOut() { return this.post<void>(`${SERVICE.ACCOUNT}/v1/sign-out`); }
  accountV1Register(params: AccountType.AccountRegisterParams) { return this.post<void>(`${SERVICE.ACCOUNT}/v1/register`, params); }
  accountV1SendEmailCode(params: AccountType.AccountSendEmailCodeParams) { return this.post<AccountType.AccountSendEmailCodeResult>(`${SERVICE.ACCOUNT}/v1/send-email-code`, params); }
  accountV1DeleteAccount(params: AccountType.AccountDeleteAccountParams) { return this.post<AccountType.AccountDeleteAccountResult>(`${SERVICE.ACCOUNT}/v1/delete-account`, params); }
  accountV1UpdatePushToken(params: AccountType.AccountUpdatePushTokenParams) { return this.post<void>(`${SERVICE.ACCOUNT}/v1/update-push-token`, params); }
  accountV1AccountInfo() { return this.get<AccountType.AccountAccountInfoResult>(`${SERVICE.ACCOUNT}/v1/account-info`); }
  accountV1Balance() { return this.get<AccountType.AccountBalanceResult>(`${SERVICE.ACCOUNT}/v1/balance`); }
  accountV1PurchaseHistory(params: AccountType.AccountPurchaseHistoryParams) { return this.get<AccountType.AccountPurchaseHistoryResult>(`${SERVICE.ACCOUNT}/v1/purchase-history`, params); }
  accountV1TicketFreeHistory(params: AccountType.AccountTicketFreeHistoryParams) { return this.get<AccountType.AccountTicketFreeHistoryResult>(`${SERVICE.ACCOUNT}/v1/ticket-free-history`, params); }
  accountV1TicketObtainHistory(params: AccountType.AccountTicketObtainHistoryParams) { return this.get<AccountType.AccountTicketObtainHistoryResult>(`${SERVICE.ACCOUNT}/v1/ticket-obtain-history`, params); }
  accountV1TicketObtainHistoryDetail(params: AccountType.AccountTicketObtainHistoryDetailParams) { return this.get<AccountType.AccountTicketObtainHistoryDetailResult>(`${SERVICE.ACCOUNT}/v1/ticket-obtain-history-detail`, params); }
  accountV1TicketPassObtainHistory(params: AccountType.AccountTicketPassObtainHistoryParams) { return this.get<AccountType.AccountTicketPassObtainHistoryResult>(`${SERVICE.ACCOUNT}/v1/ticket-pass-obtain-history`, params); }
  accountV1MovieCapRentHistory(params: AccountType.AccountMovieCapRentHistoryParams) { return this.get<AccountType.AccountMovieCapRentHistoryResult>(`${SERVICE.ACCOUNT}/v1/movie-cap-rent-history`, params); }
  accountV1ChangeEmail(params: AccountType.AccountChangeEmailParams) { return this.post<void>(`${SERVICE.ACCOUNT}/v1/change-email`, params); }
  accountV1ChangePassword(params: AccountType.AccountChangePasswordParams) { return this.post<AccountType.AccountChangePasswordResult>(`${SERVICE.ACCOUNT}/v1/change-password`, params); }
  accountV1ReadSetting() { return this.get<AccountType.AccountReadSettingResult>(`${SERVICE.ACCOUNT}/v1/read-setting`); }
  accountV1UpdateSetting(params: AccountType.AccountUpdateSettingParams) { return this.post<AccountType.AccountUpdateSettingResult>(`${SERVICE.ACCOUNT}/v1/update-setting`, params); }
  accountV1TicketBuyOptionList(params?: AccountType.AccountTicketBuyOptionListParams) { return this.get<AccountType.AccountTicketBuyOptionListResult>(`${SERVICE.ACCOUNT}/v1/ticket-buy-option-list`, params); }
  accountV1MakePurchase_Card(params: AccountType.AccountMakePurchaseParamsCard) { return this.post<AccountType.AccountMakePurchaseResultCard>(`${SERVICE.ACCOUNT}/v1/make-purchase`, params); }
  accountV1MakePurchase_Kakaopay(params: AccountType.AccountMakePurchaseParamsKakaopay) { return this.post<AccountType.AccountMakePurchaseResultKakaopay>(`${SERVICE.ACCOUNT}/v1/make-purchase`, params); }
  accountV1MakePurchase_IapIos(params: AccountType.AccountMakePurchaseParamsIapIos) { return this.post<AccountType.AccountMakePurchaseResultIapIos>(`${SERVICE.ACCOUNT}/v1/make-purchase`, params); }
  accountV1MakePurchase_Payco(params: AccountType.AccountMakePurchaseParamsPayco) { return this.post<AccountType.AccountMakePurchaseResultPayco>(`${SERVICE.ACCOUNT}/v1/make-purchase`, params); }
  accountV1MakePurchase_PaycoAlpha(params: AccountType.AccountMakePurchaseParamsPaycoAlpha) { return this.post<AccountType.AccountMakePurchaseResultPaycoAlpha>(`${SERVICE.ACCOUNT}/v1/make-purchase`, params); }
  accountV1CompletePurchase(params: AccountType.AccountCompletePurchaseParams) { return this.post<AccountType.AccountCompletePurchaseResult>(`${SERVICE.ACCOUNT}/v1/complete-purchase`, params); }
  accountV1RefundTicket(params: AccountType.AccountRefundTicketParams) { return this.post<AccountType.AccountRefundTicketResult>(`${SERVICE.ACCOUNT}/v1/refund-ticket`, params); }
  accountV1CancelPurchase(params: AccountType.AccountCancelPurchaseParams) { return this.post<void>(`${SERVICE.ACCOUNT}/v1/cancel-purchase`, params); }
  accountV1UseCoupon(params: AccountType.AccountUseCouponParams) { return this.post<AccountType.AccountUseCouponResult>(`${SERVICE.ACCOUNT}/v1/use-coupon`, params); }
  accountV1MovieCapWatched(params: AccountType.AccountMovieCapWatchedParams) { return this.get<AccountType.AccountMovieCapWatchedResult>(`${SERVICE.ACCOUNT}/v1/movie-cap-watched`, params); }
  accountV1SubCapWatched(params: AccountType.AccountSubCapWatchedParams) { return this.get<AccountType.AccountSubCapWatchedResult>(`${SERVICE.ACCOUNT}/v1/sub-cap-watched`, params); }
  accountV1ContentLiked(params: AccountType.AccountContentLikedParams) { return this.get<AccountType.AccountContentLikedResult>(`${SERVICE.ACCOUNT}/v1/content-liked`, params); }
  accountV1Boot(params: AccountType.AccountBootParams) { return this.get<AccountType.AccountBootResult>(`${SERVICE.ACCOUNT}/v1/boot`, params); }
  workV1WorkList(params: WorkType.WorkWorkListParams) { return this.get<WorkType.WorkWorkListResult>(`${SERVICE.WORK}/v1/work-list`, params); }
  workV1WorkDetail(params: WorkType.WorkWorkDetailParams) { return this.get<WorkType.WorkWorkDetailResult>(`${SERVICE.WORK}/v1/work-detail`, params); }
  workV1WorkWish(params: WorkType.WorkWorkWishParams) { return this.post<WorkType.WorkWorkWishResult>(`${SERVICE.WORK}/v1/work-wish`, params); }
  workV1MovieCapDetail(params: WorkType.WorkMovieCapDetailParams) { return this.get<WorkType.WorkMovieCapDetailResult>(`${SERVICE.WORK}/v1/movie-cap-detail`, params); }
  workV1MovieCapRelation(params: WorkType.WorkMovieCapRelationParams) { return this.get<WorkType.WorkMovieCapRelationResult>(`${SERVICE.WORK}/v1/movie-cap-relation`, params); }
  workV1MovieCapLike(params: WorkType.WorkMovieCapLikeParams) { return this.post<WorkType.WorkMovieCapLikeResult>(`${SERVICE.WORK}/v1/movie-cap-like`, params); }
  workV1MovieCapProgress(params: WorkType.WorkMovieCapProgressParams) { return this.post<void>(`${SERVICE.WORK}/v1/movie-cap-progress`, params); }
  workV1MovieCapRentInfo(params: WorkType.WorkMovieCapRentInfoParams) { return this.get<WorkType.WorkMovieCapRentInfoResult>(`${SERVICE.WORK}/v1/movie-cap-rent-info`, params); }
  workV1MovieCapRent(params: WorkType.WorkMovieCapRentParams) { return this.post<WorkType.WorkMovieCapRentResult>(`${SERVICE.WORK}/v1/movie-cap-rent`, params); }
  workV1WishList(params: WorkType.WorkWishListParams) { return this.get<WorkType.WorkWishListResult>(`${SERVICE.WORK}/v1/wish-list`, params); }
  workV1WorkWatchedList(params: WorkType.WorkWorkWatchedListParams) { return this.get<WorkType.WorkWorkWatchedListResult>(`${SERVICE.WORK}/v1/work-watched-list`, params); }
  workV1WorkContinueList(params: WorkType.WorkWorkContinueListParams) { return this.get<WorkType.WorkWorkContinueListResult>(`${SERVICE.WORK}/v1/work-continue-list`, params); }
  workV1UpcomingList(params: WorkType.WorkUpcomingListParams) { return this.get<WorkType.WorkUpcomingListResult>(`${SERVICE.WORK}/v1/upcoming-list`, params); }
  workV1UpcomingSubscribe(params: WorkType.WorkUpcomingSubscribeParams) { return this.post<WorkType.WorkUpcomingSubscribeResult>(`${SERVICE.WORK}/v1/upcoming-subscribe`, params); }
  workV1MovieCapProgressList() { return this.get<WorkType.WorkMovieCapProgressListResult>(`${SERVICE.WORK}/v1/movie-cap-progress-list`); }
  curationV1WorkRecent() { return this.get<CurationType.CurationWorkRecentResult>(`${SERVICE.CURATION}/v1/work-recent`); }
  curationV1MovieCapFree() { return this.get<CurationType.CurationMovieCapFreeResult>(`${SERVICE.CURATION}/v1/movie-cap-free`); }
  curationV1SubCapRecent() { return this.get<CurationType.CurationSubCapRecentResult>(`${SERVICE.CURATION}/v1/sub-cap-recent`); }
  curationV1PromotionMain() { return this.get<CurationType.CurationPromotionMainResult>(`${SERVICE.CURATION}/v1/promotion-main`); }
  curationV1WorkPopular() { return this.get<CurationType.CurationWorkPopularResult>(`${SERVICE.CURATION}/v1/work-popular`); }
  curationV1SubCapPopular() { return this.get<CurationType.CurationSubCapPopularResult>(`${SERVICE.CURATION}/v1/sub-cap-popular`); }
  curationV1WorkCollection() { return this.get<CurationType.CurationWorkCollectionResult>(`${SERVICE.CURATION}/v1/work-collection`); }
  curationV1WorkCollectionDetail(params: CurationType.CurationWorkCollectionDetailParams) { return this.get<CurationType.CurationWorkCollectionDetailResult>(`${SERVICE.CURATION}/v1/work-collection-detail`, params); }
  curationV1SubCapRecommendation() { return this.get<CurationType.CurationSubCapRecommendationResult>(`${SERVICE.CURATION}/v1/sub-cap-recommendation`); }
  curationV1SubCapRecommendedNow() { return this.get<CurationType.CurationSubCapRecommendedNowResult>(`${SERVICE.CURATION}/v1/sub-cap-recommended-now`); }
  curationV1SubCapKeyword() { return this.get<CurationType.CurationSubCapKeywordResult>(`${SERVICE.CURATION}/v1/sub-cap-keyword`); }
  subCapV1SubCapList(params: SubCapType.SubCapSubCapListParams) { return this.get<SubCapType.SubCapSubCapListResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-list`, params); }
  subCapV1SubCapDetail(params: SubCapType.SubCapSubCapDetailParams) { return this.get<SubCapType.SubCapSubCapDetailResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-detail`, params); }
  subCapV1SubCapRelation(params: SubCapType.SubCapSubCapRelationParams) { return this.get<SubCapType.SubCapSubCapRelationResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-relation`, params); }
  subCapV1SubCapLike(params: SubCapType.SubCapSubCapLikeParams) { return this.post<SubCapType.SubCapSubCapLikeResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-like`, params); }
  subCapV1SubCapProgress(params: SubCapType.SubCapSubCapProgressParams) { return this.post<void>(`${SERVICE.SUB_CAP}/v1/sub-cap-progress`, params); }
  newsV1NoticeList(params: NewsType.NewsNoticeListParams) { return this.get<NewsType.NewsNoticeListResult>(`${SERVICE.NEWS}/v1/notice-list`, params); }
  newsV1NoticeDetail(params: NewsType.NewsNoticeDetailParams) { return this.get<NewsType.NewsNoticeDetailResult>(`${SERVICE.NEWS}/v1/notice-detail`, params); }
  newsV1PromotionList(params: NewsType.NewsPromotionListParams) { return this.get<NewsType.NewsPromotionListResult>(`${SERVICE.NEWS}/v1/promotion-list`, params); }
  newsV1PromotionDetail(params: NewsType.NewsPromotionDetailParams) { return this.get<NewsType.NewsPromotionDetailResult>(`${SERVICE.NEWS}/v1/promotion-detail`, params); }
  newsV1NotificationList(params: NewsType.NewsNotificationListParams) { return this.get<NewsType.NewsNotificationListResult>(`${SERVICE.NEWS}/v1/notification-list`, params); }
  newsV1NotificationRead(params: NewsType.NewsNotificationReadParams) { return this.post<NewsType.NewsNotificationReadResult>(`${SERVICE.NEWS}/v1/notification-read`, params); }
  newsV1Version(params: NewsType.NewsVersionParams) { return this.get<NewsType.NewsVersionResult>(`${SERVICE.NEWS}/v1/version`, params); }
  newsV1LoginScreenInfo() { return this.get<NewsType.NewsLoginScreenInfoResult>(`${SERVICE.NEWS}/v1/login-screen-info`); }
  newsV1Banner(params: NewsType.NewsBannerParams) { return this.get<NewsType.NewsBannerResult>(`${SERVICE.NEWS}/v1/banner`, params); }
  newsV1BannerClick(params: NewsType.NewsBannerClickParams) { return this.post<void>(`${SERVICE.NEWS}/v1/banner-click`, params); }
  qnaV1QnaList(params: QnaType.QnaUserQnaListParams) { return this.get<QnaType.QnaUserQnaListResult>(`${SERVICE.QNA}/v1/qna-list`, params); }
  qnaV1QnaDetail(params: QnaType.QnaUserQnaDetailParams) { return this.get<QnaType.QnaUserQnaDetailResult>(`${SERVICE.QNA}/v1/qna-detail`, params); }
  qnaV1QnaWrite(params: QnaType.QnaUserQnaWriteParams) { return this.post<QnaType.QnaUserQnaWriteResult>(`${SERVICE.QNA}/v1/qna-write`, params); }
  searchV1Search(params: SearchType.SearchSearchParams) { return this.get<SearchType.SearchSearchResult>(`${SERVICE.SEARCH}/v1/search`, params); }
  searchV1SearchAutocomplete(params: SearchType.SearchSearchAutocompleteParams) { return this.get<SearchType.SearchSearchAutocompleteResult>(`${SERVICE.SEARCH}/v1/search-autocomplete`, params); }
  searchV1SearchTextList() { return this.get<SearchType.SearchSearchTextListResult>(`${SERVICE.SEARCH}/v1/search-text-list`); }
  /* eslint-enable  max-len */

  /**
   * API 요청시 사용할 Auth Token을 설정합니다.
   */
  setAuthToken(authToken: string | null): void {
    this.authToken = authToken;

    const payload = authToken != null ? decodeJWTPayload<{ uid: string; }>(authToken) : null;
    const uid = payload?.uid ?? null;
    this.firebaseAnalyticsService.setUserId(uid);
  }

  getUnauthorizedObservable(): Observable<void> {
    return from(this.unauthorizedSubject);
  }

  /**
   * HTTP 요청용 베이스 헤더를 반환합니다.
   */
  private getHeaders(): HeadersMap {
    const headers: HeadersMap = {};

    if (this.authToken) {
      headers.Authorization = `Bearer ${this.authToken}`;
    }

    return headers;
  }

  /**
   * API GET 요청을 보냅니다.
   * @param url 요청 보낼 주소
   * @param params 요청 파라미터
   */
  private get<T>(url: string, params?: any): APIResponseObservable<T> {
    return defer(() => {
      let requestTime = Date.now();
      const headers = this.getHeaders();
      const queryString = paramsToQueryString(params);
      return this.httpClient.get<APIResponse<DateWrapped<T>>>(API_URL + url + (queryString ? '?' + queryString : ''), { headers }).pipe(
        map((res) => ({ ...res, result: unwrapDate(res.result) })),
        retryWhen((errs) => errs.pipe(
          take(1),
          concatMap((err) => err instanceof HttpErrorResponse && err.status === 0 && Date.now() - requestTime >= 3000 ?
            of(err) :
            throwError(() => err),
          ),
          tap(() => requestTime = Date.now()),
        )),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.unauthorizedSubject.next();
            }
            const errorBody = err.error as APIResponseFailure;
            if (errorBody != null && typeof errorBody === 'object' && errorBody.error != null) {
              throw new APIError(err.status, errorBody.error);
            }
          }
          throw err;
        }),
      );
    });
  }

  /**
   * API POST 요청을 보냅니다.
   * @param url 요청 보낼 주소
   * @param params 요청 파라미터
   */
  private post<T>(url: string, params?: any): APIResponseObservable<T> {
    return defer(() => {
      let requestTime = Date.now();
      const headers = this.getHeaders();
      return this.httpClient.post<APIResponse<DateWrapped<T>>>(API_URL + url, params, { headers }).pipe(
        map((res) => ({ ...res, result: unwrapDate(res.result) })),
        retryWhen((errs) => errs.pipe(
          take(1),
          concatMap((err) => err instanceof HttpErrorResponse && err.status === 0 && Date.now() - requestTime >= 3000 ?
            of(err) :
            throwError(() => err),
          ),
          tap(() => requestTime = Date.now()),
        )),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.unauthorizedSubject.next();
            }
            const errorBody = err.error as APIResponseFailure;
            if (errorBody != null && typeof errorBody === 'object' && errorBody.error != null) {
              throw new APIError(err.status, errorBody.error);
            }
          }
          throw err;
        }),
      );
    });
  }
}
