import { FacebookSDK } from './facebook-sdk.d';

declare global {
  interface Window {
    FB: FacebookSDK;
    fbAsyncInit: () => void;
  }
}

export function enableFacebookSdk(facebookAppId: string): boolean {
  if (!facebookAppId) {
    return false;
  }

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: facebookAppId,
      cookie: true,
      xfbml: true,
      version: 'v10.0',
    });
  };

  ((d, s, id) => {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode?.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');

  return true;
}
