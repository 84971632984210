import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController, NavController } from '@ionic/angular';
import { map, Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppDownloadModalComponent, AppDownloadModalParams } from '../../components/app-download-modal';
import { openModal } from '../../utils/open-modal';

@Injectable({
  providedIn: 'root'
})
export class AppDownloadService {
  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
  ) {}

  /**
   * 앱 설치 유도 팝업을 표시하고 앱 설치 버튼을 눌렀는지의 여부를 emit하는 `Observable`을 반환합니다.
   * 이미 앱인 경우 팝업을 표시하지 않고 즉시 `false`를 emit하는 `Observable`을 반환합니다.
   */
  openAppDownloadModal(options?: {
    params?: AppDownloadModalParams;
    bottomDrawer?: boolean;
    preventNavigation?: boolean;
  }): Observable<boolean> {
    return !Capacitor.isNative ?
      openModal<{ download: boolean; }>(this.modalCtrl, {
        component: AppDownloadModalComponent,
        componentProps: options?.params,
        cssClass: options?.bottomDrawer ? 'modal-bottom-drawer-transparent' : 'modal-transparent',
      }).pipe(
        map((ev) => ev.data?.download ?? false),
        tap((download) => {
          if (!options?.preventNavigation && download) {
            window.open(environment.appDownloadLinkQR, '_blank');
          }
        }),
      ) :
      of(false);
  }
}
