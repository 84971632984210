import { Observable, BehaviorSubject, from } from 'rxjs';
import { environment } from './environments/environment';

declare global {
  interface Window {
    beforeInstallPromptEvent: BeforeInstallPromptEvent;
    beforeInstallPromptObservable: Observable<BeforeInstallPromptEvent | null>;
  }

  interface WindowEventMap {
    'beforeinstallprompt': BeforeInstallPromptEvent;
  }
}

export interface BeforeInstallPromptEvent extends Event {
  platforms: Array<string>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed'; platform: string; }>;

  prompt(): Promise<void>;
}

/**
 * Angular 상에서 설치 프롬프트를 제어하기 위한 설정을 합니다.
 * 서비스 워커 활성화가 필요합니다.
 */
export function interceptBeforeInstallPrompt(): void {
  if (!environment.serviceWorker) {
    return;
  }

  const subject = new BehaviorSubject<BeforeInstallPromptEvent | null>(null);

  // 설치 프롬프트 기본 동작 방지 및 Subject로 emit
  window.addEventListener('beforeinstallprompt', (ev: BeforeInstallPromptEvent) => {
    ev.preventDefault();

    window.beforeInstallPromptEvent = ev;
    subject.next(ev);
  });

  window.beforeInstallPromptObservable = from(subject);
}
