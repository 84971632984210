import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AlertButton, AlertInput } from '@ionic/core';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertModalComponent implements OnInit {
  header?: string;
  message?: string;
  messageTemplate?: TemplateRef<unknown>;
  inputs?: Array<AlertInput>;
  buttons?: Array<AlertButton>;
  buttonFill?: string;
  values?: { [name: string]: any };
  showCloseButton?: boolean;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {}

  ngOnInit() {
    this.header = this.navParams.get('header');
    const message = this.navParams.get('message');
    this.message = typeof message === 'string' ? message : undefined;
    this.messageTemplate = typeof message !== 'string' ? message : undefined;
    this.inputs = this.navParams.get('inputs');
    if (this.inputs) {
      this.values = this.inputs.reduce((acc, cur, idx) => {
        acc[cur.name || idx] = cur.value;
        return acc;
      }, {} as Record<string, any>);
    }
    this.buttons = this.navParams.get('buttons')
      ?.map((button: AlertButton | string) => typeof button === 'string' ? { text: button } : button);
    this.buttonFill = this.navParams.get('buttonFill');
    this.showCloseButton = this.navParams.get('showCloseButton');
  }

  onClickClose(): void {
    this.modalCtrl.dismiss(undefined, 'cancel');
  }

  onChangeInput(input: AlertInput, index: number, ev: CustomEvent<{ value: any; }>): void {
    if (this.values) {
      this.values[input.name || index] = ev.detail.value;
    }
  }

  onClickButton(button: AlertButton): void {
    this.modalCtrl.dismiss({ values: this.values }, button.role);
  }
}
