import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BootService } from 'src/app/services/boot';

@Injectable({
  providedIn: 'root'
})
export class BootGuard implements CanActivate {
  constructor(
    private location: Location,
    private router: Router,
    private bootService: BootService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.bootService.isBooted) {
      setTimeout(() => {
        this.location.replaceState(state.url);
        this.router.navigate(['/boot'], {
          queryParams: { r: state.url },
          state: { rState: this.router.getCurrentNavigation()?.extras.state },
          skipLocationChange: true,
        });
      });
      return false;
    }
    return true;
  }
}
