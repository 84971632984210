import { Observable, Observer, SubscriptionLike } from 'rxjs';

interface ObservableLike<T> {
  subscribe(observer?: Partial<Observer<T>>): SubscriptionLike;
}

export function normalizeObservable<T>(source: ObservableLike<T>): Observable<T> {
  return new Observable<T>((subscriber) => source.subscribe({
    next(value) {
      subscriber.next(value);
    },
    error(error) {
      subscriber.error(error);
    },
    complete() {
      subscriber.complete();
    },
  }));
}
