import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { IAPError as IAPErrorOriginal } from '@awesome-cordova-plugins/in-app-purchase-2';
import { AppStoreReceipt, IAPProduct, IAPProductOptions, InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { Capacitor } from '@capacitor/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  EMPTY,
  endWith,
  filter,
  finalize,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
  tap,
  throwError,
  withLatestFrom
} from 'rxjs';
import { APIErrorCode } from '../../constants/api-error-code.constant';
import { AccountCompletePurchaseResult } from '../../types/api/account.type';
import { TicketBuyOption } from '../../types/client.type';
import { isNotNullish } from '../../utils/etc.util';
import { runInZone } from '../../utils/run-in-zone';
import { AlertService } from '../alert';
import { APIError } from '../api';
import { AuthService } from '../auth';
import { PurchaseService } from '../purchase';
import { UserService } from '../user';
import { IAPError } from './iap-error';
import { InAppPurchaseCompleteResult } from './in-app-purchase.type';

function isIAPProductWithAppStoreTransaction(product: IAPProduct): product is IAPProduct & { transaction: AppStoreReceipt; } {
  return product.type !== 'application' && product.transaction?.type === 'ios-appstore';
}

@Injectable({
  providedIn: 'root'
})
export class InAppPurchaseService implements OnDestroy {
  /**
   * 이용 가능 여부
   */
  get available(): boolean {
    return this.isNativeIos && !this.isErrorOccurred;
  }

  readonly ERR_SETUP: number;
  readonly ERR_LOAD: number;
  readonly ERR_PURCHASE: number;
  readonly ERR_LOAD_RECEIPTS: number;
  readonly ERR_CLIENT_INVALID: number;
  readonly ERR_PAYMENT_CANCELLED: number;
  readonly ERR_PAYMENT_INVALID: number;
  readonly ERR_PAYMENT_NOT_ALLOWED: number;
  readonly ERR_UNKNOWN: number;
  readonly ERR_REFRESH_RECEIPTS: number;
  readonly ERR_INVALID_PRODUCT_ID: number;
  readonly ERR_FINISH: number;
  readonly ERR_COMMUNICATION: number;
  readonly ERR_SUBSCRIPTIONS_NOT_AVAILABLE: number;
  readonly ERR_MISSING_TOKEN: number;
  readonly ERR_VERIFICATION_FAILED: number;
  readonly ERR_BAD_RESPONSE: number;
  readonly ERR_REFRESH: number;
  readonly ERR_PAYMENT_EXPIRED: number;
  readonly ERR_DOWNLOAD: number;
  readonly ERR_SUBSCRIPTION_UPDATE_NOT_AVAILABLE: number;

  readonly productLoaded$: Observable<IAPProduct>;
  readonly productUpdated$: Observable<IAPProduct>;
  readonly productError$: Observable<IAPErrorOriginal>;
  readonly productApproved$: Observable<IAPProduct>;
  readonly productOwned$: Observable<IAPProduct>;
  readonly productCancelled$: Observable<IAPProduct>;
  readonly productRefunded$: Observable<IAPProduct>;
  readonly productRegistered$: Observable<IAPProduct>;
  readonly productValid$: Observable<IAPProduct>;
  readonly productInvalid$: Observable<IAPProduct>;
  readonly productRequested$: Observable<IAPProduct>;
  readonly productInitiated$: Observable<IAPProduct>;
  readonly productFinished$: Observable<IAPProduct>;
  readonly productVerified$: Observable<IAPProduct>;
  readonly productUnverified$: Observable<IAPProduct>;
  readonly productExpired$: Observable<IAPProduct>;
  readonly productDownloaded$: Observable<IAPProduct>;
  readonly refresh$: Observable<boolean>;
  readonly refreshing$: Observable<boolean>;
  readonly completePurchase$: Observable<InAppPurchaseCompleteResult>;

  /**
   * 네이티브 여부
   */
  private isNativeIos = (Capacitor.isNative ?? false) && Capacitor.getPlatform() === 'ios';

  private isAuthed = false;
  private isReady = false;
  private isErrorOccurred = false;

  private ngOnDestroySubject = new Subject<void>();
  private productLoadedSubject = new Subject<IAPProduct>();
  private productUpdatedSubject = new Subject<IAPProduct>();
  private productErrorSubject = new Subject<IAPErrorOriginal>();
  private productApprovedSubject = new Subject<IAPProduct>();
  private productOwnedSubject = new Subject<IAPProduct>();
  private productCancelledSubject = new Subject<IAPProduct>();
  private productRefundedSubject = new Subject<IAPProduct>();
  private productRegisteredSubject = new Subject<IAPProduct>();
  private productValidSubject = new Subject<IAPProduct>();
  private productInvalidSubject = new Subject<IAPProduct>();
  private productRequestedSubject = new Subject<IAPProduct>();
  private productInitiatedSubject = new Subject<IAPProduct>();
  private productFinishedSubject = new Subject<IAPProduct>();
  private productVerifiedSubject = new Subject<IAPProduct>();
  private productUnverifiedSubject = new Subject<IAPProduct>();
  private productExpiredSubject = new Subject<IAPProduct>();
  private productDownloadedSubject = new Subject<IAPProduct>();
  private refreshSubject = new BehaviorSubject<boolean>(false);
  private refreshingSubject = new BehaviorSubject<boolean>(false);
  private completePurchaseSubject = new Subject<InAppPurchaseCompleteResult>();

  constructor(
    private ngZone: NgZone,
    private store: InAppPurchase2,
    private alertService: AlertService,
    private purchaseService: PurchaseService,
    private userService: UserService,
    private authService: AuthService,
  ) {
    this.ERR_SETUP = this.available ? store.ERR_SETUP : -1;
    this.ERR_LOAD = this.available ? store.ERR_LOAD : -1;
    this.ERR_PURCHASE = this.available ? store.ERR_PURCHASE : -1;
    this.ERR_LOAD_RECEIPTS = this.available ? store.ERR_LOAD_RECEIPTS : -1;
    this.ERR_CLIENT_INVALID = this.available ? store.ERR_CLIENT_INVALID : -1;
    this.ERR_PAYMENT_CANCELLED = this.available ? store.ERR_PAYMENT_CANCELLED : -1;
    this.ERR_PAYMENT_INVALID = this.available ? store.ERR_PAYMENT_INVALID : -1;
    this.ERR_PAYMENT_NOT_ALLOWED = this.available ? store.ERR_PAYMENT_NOT_ALLOWED : -1;
    this.ERR_UNKNOWN = this.available ? store.ERR_UNKNOWN : -1;
    this.ERR_REFRESH_RECEIPTS = this.available ? store.ERR_REFRESH_RECEIPTS : -1;
    this.ERR_INVALID_PRODUCT_ID = this.available ? store.ERR_INVALID_PRODUCT_ID : -1;
    this.ERR_FINISH = this.available ? store.ERR_FINISH : -1;
    this.ERR_COMMUNICATION = this.available ? store.ERR_COMMUNICATION : -1;
    this.ERR_SUBSCRIPTIONS_NOT_AVAILABLE = this.available ? store.ERR_SUBSCRIPTIONS_NOT_AVAILABLE : -1;
    this.ERR_MISSING_TOKEN = this.available ? store.ERR_MISSING_TOKEN : -1;
    this.ERR_VERIFICATION_FAILED = this.available ? store.ERR_VERIFICATION_FAILED : -1;
    this.ERR_BAD_RESPONSE = this.available ? store.ERR_BAD_RESPONSE : -1;
    this.ERR_REFRESH = this.available ? store.ERR_REFRESH : -1;
    this.ERR_PAYMENT_EXPIRED = this.available ? store.ERR_PAYMENT_EXPIRED : -1;
    this.ERR_DOWNLOAD = this.available ? store.ERR_DOWNLOAD : -1;
    this.ERR_SUBSCRIPTION_UPDATE_NOT_AVAILABLE = this.available ? store.ERR_SUBSCRIPTION_UPDATE_NOT_AVAILABLE : -1;

    this.productLoaded$ = from(this.productLoadedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productUpdated$ = from(this.productUpdatedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productError$ = from(this.productErrorSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productApproved$ = from(this.productApprovedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productOwned$ = from(this.productOwnedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productCancelled$ = from(this.productCancelledSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productRefunded$ = from(this.productRefundedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productRegistered$ = from(this.productRegisteredSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productValid$ = from(this.productValidSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productInvalid$ = from(this.productInvalidSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productRequested$ = from(this.productRequestedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productInitiated$ = from(this.productInitiatedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productFinished$ = from(this.productFinishedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productVerified$ = from(this.productVerifiedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productUnverified$ = from(this.productUnverifiedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productExpired$ = from(this.productExpiredSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.productDownloaded$ = from(this.productDownloadedSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.refresh$ = from(this.refreshSubject).pipe(takeUntil(this.ngOnDestroySubject), endWith(false));
    this.refreshing$ = from(this.refreshingSubject).pipe(takeUntil(this.ngOnDestroySubject), endWith(false));
    this.completePurchase$ = from(this.completePurchaseSubject).pipe(takeUntil(this.ngOnDestroySubject));

    this.authService.getAccessTokenObservable().subscribe((accessToken) => {
      this.isAuthed = !!accessToken;
    });
  }

  ngOnDestroy(): void {
    this.ngOnDestroySubject.next();
    this.ngOnDestroySubject.complete();
    this.productLoadedSubject.complete();
    this.productUpdatedSubject.complete();
    this.productErrorSubject.complete();
    this.productApprovedSubject.complete();
    this.productOwnedSubject.complete();
    this.productCancelledSubject.complete();
    this.productRefundedSubject.complete();
    this.productRegisteredSubject.complete();
    this.productValidSubject.complete();
    this.productInvalidSubject.complete();
    this.productRequestedSubject.complete();
    this.productInitiatedSubject.complete();
    this.productFinishedSubject.complete();
    this.productVerifiedSubject.complete();
    this.productUnverifiedSubject.complete();
    this.productExpiredSubject.complete();
    this.productDownloadedSubject.complete();
    this.refreshSubject.complete();
    this.refreshingSubject.complete();
    this.completePurchaseSubject.complete();
  }

  async init(): Promise<void> {
    if (!this.isNativeIos) {
      return Promise.reject(new Error('Not available'));
    }

    // this.store.verbosity = this.store.DEBUG;
    this.setupListeners();

    this.refresh$.pipe(
      filter((refresh) => refresh),
      withLatestFrom(this.refreshingSubject),
      filter(([, refreshing]) => !refreshing),
      concatMap(() => {
        this.refreshingSubject.next(true);

        return new Observable<void>((subscriber) => {
          const refreshReq = this.store.refresh();

          refreshReq.completed(() => {
            subscriber.next();
            subscriber.complete();
          });
          refreshReq.failed(() => {
            subscriber.error(new Error('Refresh failed'));
          });
          refreshReq.cancelled(() => {
            subscriber.error(new Error('Refresh cancelled'));
          });
        }).pipe(
          finalize(() => {
            this.refreshingSubject.next(false);
          }),
          runInZone(this.ngZone),
        );
      }),
    ).subscribe();
  }

  refresh(): Observable<void> {
    return new Observable((subscriber) => {
      const sub = this.refreshing$.pipe(
        filter((refreshing) => !refreshing),
        take(1),
        map(() => undefined),
      ).subscribe(subscriber);
      this.refreshSubject.next(true);
      return sub;
    });
  }

  order(product: string | IAPProduct): Observable<void> {
    return new Observable<void>((subscriber) => {
      const order = this.store.order(product);

      order.then(() => {
        subscriber.next();
        subscriber.complete();
      }, (err: any) => {
        subscriber.error(err);
      });
    }).pipe(
      runInZone(this.ngZone),
    );
  }

  purchase(product: string | IAPProduct): Observable<AccountCompletePurchaseResult> {
    return this.order(product).pipe(
      mergeMap(() => merge(
        this.completePurchase$,
        this.productError$.pipe(
          concatMap((err) => throwError(() =>
            new IAPError(`${this.getIAPErrorName(err.code)}: ${err.message}`, err.code),
          )),
        ),
        this.productCancelled$.pipe(
          concatMap(() => throwError(() =>
            new IAPError(`${this.getIAPErrorName(this.ERR_PAYMENT_CANCELLED)}: Payment cancelled`, this.ERR_PAYMENT_CANCELLED),
          )),
        ),
        this.ngOnDestroySubject.pipe(
          concatMap(() => throwError(() =>
            new IAPError(`${this.getIAPErrorName(this.ERR_UNKNOWN)}: Unknown error`, this.ERR_UNKNOWN),
          )),
        ),
      )),
      take(1),
      concatMap((result) => result.success && result.completeResult != null ?
        of(result.completeResult) :
        throwError(() => new IAPError(`${this.getIAPErrorName(this.ERR_UNKNOWN)}: Unknown error`, this.ERR_UNKNOWN))),
      runInZone(this.ngZone),
    );
  }

  getProduct(id: string): IAPProduct | undefined {
    return this.store.products.find((product) => product.id === id);
  }

  getIAPErrorName(errorCode: number): string {
    if (errorCode === this.ERR_SETUP) { return 'ERR_SETUP'; }
    if (errorCode === this.ERR_LOAD) { return 'ERR_LOAD'; }
    if (errorCode === this.ERR_PURCHASE) { return 'ERR_PURCHASE'; }
    if (errorCode === this.ERR_LOAD_RECEIPTS) { return 'ERR_LOAD_RECEIPTS'; }
    if (errorCode === this.ERR_CLIENT_INVALID) { return 'ERR_CLIENT_INVALID'; }
    if (errorCode === this.ERR_PAYMENT_CANCELLED) { return 'ERR_PAYMENT_CANCELLED'; }
    if (errorCode === this.ERR_PAYMENT_INVALID) { return 'ERR_PAYMENT_INVALID'; }
    if (errorCode === this.ERR_PAYMENT_NOT_ALLOWED) { return 'ERR_PAYMENT_NOT_ALLOWED'; }
    if (errorCode === this.ERR_UNKNOWN) { return 'ERR_UNKNOWN'; }
    if (errorCode === this.ERR_REFRESH_RECEIPTS) { return 'ERR_REFRESH_RECEIPTS'; }
    if (errorCode === this.ERR_INVALID_PRODUCT_ID) { return 'ERR_INVALID_PRODUCT_ID'; }
    if (errorCode === this.ERR_FINISH) { return 'ERR_FINISH'; }
    if (errorCode === this.ERR_COMMUNICATION) { return 'ERR_COMMUNICATION'; }
    if (errorCode === this.ERR_SUBSCRIPTIONS_NOT_AVAILABLE) { return 'ERR_SUBSCRIPTIONS_NOT_AVAILABLE'; }
    if (errorCode === this.ERR_MISSING_TOKEN) { return 'ERR_MISSING_TOKEN'; }
    if (errorCode === this.ERR_VERIFICATION_FAILED) { return 'ERR_VERIFICATION_FAILED'; }
    if (errorCode === this.ERR_BAD_RESPONSE) { return 'ERR_BAD_RESPONSE'; }
    if (errorCode === this.ERR_REFRESH) { return 'ERR_REFRESH'; }
    if (errorCode === this.ERR_PAYMENT_EXPIRED) { return 'ERR_PAYMENT_EXPIRED'; }
    if (errorCode === this.ERR_DOWNLOAD) { return 'ERR_DOWNLOAD'; }
    if (errorCode === this.ERR_SUBSCRIPTION_UPDATE_NOT_AVAILABLE) { return 'ERR_SUBSCRIPTION_UPDATE_NOT_AVAILABLE'; }
    return 'ERR_UNKNOWN';
  }

  ticketBuyOptionList(): Observable<Array<TicketBuyOption>> {
    const platform = this.isNativeIos ? 'ios' : undefined;

    if (platform == null) {
      return of([]);
    }

    return this.purchaseService.ticketBuyOptionList(platform).pipe(
      concatMap((list) => {
        if (this.isReady) {
          return of(list);
        }

        const productIdList = platform === 'ios' ?
          list.map((option) => option?.iosProductId).filter(isNotNullish) :
          [];

        this.registerProducts(productIdList);
        return combineLatest([
          this.refresh(),
          this.ready(),
        ]).pipe(
          map(() => list),
          catchError((err) => {
            console.error(err);
            this.isErrorOccurred = true;
            return of([]);
          }),
          finalize(() => {
            this.isReady = true;
          }),
        );
      }),
      map((list) => list.map((item) => {
        const productId = platform === 'ios' ?
          item.iosProductId :
          undefined;
        const product = productId != null ? this.getProduct(productId) : undefined;
        return product?.valid ? {
          ...item,
          price: product.priceMicros / 1_000_000,
          priceTax: 0,
          priceString: product.price,
        } : null;
      }).filter(isNotNullish)),
      runInZone(this.ngZone),
    );
  }

  private setupListeners(): void {
    this.productApproved$.pipe(
      filter(isIAPProductWithAppStoreTransaction),
    ).subscribe((product) => {
      const { transaction } = product;
      this.purchaseService.makePurchaseIapIos({ receipt: transaction.appStoreReceipt }).pipe(
        tap(() => product.finish()),
        catchError((err) => {
          if (err instanceof APIError && err.response.code === APIErrorCode.PURCHASE_DUPLICATED) {
            // 중복 구매 알림 방지
          } else {
            this.alertService.alertAPIError(err);
          }
          return EMPTY;
        }),
        mergeMap((res) => this.purchaseService.completePurchase(
          res.userPurchaseUid,
          { pgToken: transaction.appStoreReceipt },
        ).pipe(
          tap((res2) => {
            if (!this.completePurchaseSubject.observed) {
              this.alertService.alert('알림', '구매가 완료되었습니다.');
            }
            this.completePurchaseSubject.next({ success: true, product, completeResult: res2 });
            this.userService.updateBalance(this.isAuthed);
          }),
          catchError((err) => {
            if (!this.completePurchaseSubject.observed) {
              this.alertService.alertAPIError(err);
            }
            this.completePurchaseSubject.next({ success: false, product, error: err });
            return EMPTY;
          }),
        )),
      ).subscribe();
    });

    const loadedCallback = (product: IAPProduct) => { this.productLoadedSubject.next(product); };
    const updatedCallback = (product: IAPProduct) => { this.productUpdatedSubject.next(product); };
    const errorCallback = (error: IAPErrorOriginal) => { this.productErrorSubject.next(error); };
    const approvedCallback = (product: IAPProduct) => { this.productApprovedSubject.next(product); };
    const ownedCallback = (product: IAPProduct) => { this.productOwnedSubject.next(product); };
    const cancelledCallback = (product: IAPProduct) => { this.productCancelledSubject.next(product); };
    const refundedCallback = (product: IAPProduct) => { this.productRefundedSubject.next(product); };
    const registeredCallback = (product: IAPProduct) => { this.productRegisteredSubject.next(product); };
    const validCallback = (product: IAPProduct) => { this.productValidSubject.next(product); };
    const invalidCallback = (product: IAPProduct) => { this.productInvalidSubject.next(product); };
    const requestedCallback = (product: IAPProduct) => { this.productRequestedSubject.next(product); };
    const initiatedCallback = (product: IAPProduct) => { this.productInitiatedSubject.next(product); };
    const finishedCallback = (product: IAPProduct) => { this.productFinishedSubject.next(product); };
    const verifiedCallback = (product: IAPProduct) => { this.productVerifiedSubject.next(product); };
    const unverifiedCallback = (product: IAPProduct) => { this.productUnverifiedSubject.next(product); };
    const expiredCallback = (product: IAPProduct) => { this.productExpiredSubject.next(product); };
    const downloadedCallback = (product: IAPProduct) => { this.productDownloadedSubject.next(product); };

    this.store.when('product').loaded(loadedCallback);
    this.store.when('product').updated(updatedCallback);
    this.store.when('product').error(errorCallback);
    this.store.when('product').approved(approvedCallback);
    this.store.when('product').owned(ownedCallback);
    this.store.when('product').cancelled(cancelledCallback);
    this.store.when('product').refunded(refundedCallback);
    this.store.when('product').registered(registeredCallback);
    this.store.when('product').valid(validCallback);
    this.store.when('product').invalid(invalidCallback);
    this.store.when('product').requested(requestedCallback);
    this.store.when('product').initiated(initiatedCallback);
    this.store.when('product').finished(finishedCallback);
    this.store.when('product').verified(verifiedCallback);
    this.store.when('product').unverified(unverifiedCallback);
    this.store.when('product').expired(expiredCallback);
    this.store.when('product').downloaded(downloadedCallback);

    from(this.ngOnDestroySubject).subscribe(() => {
      [
        loadedCallback,
        updatedCallback,
        errorCallback,
        approvedCallback,
        ownedCallback,
        cancelledCallback,
        refundedCallback,
        registeredCallback,
        validCallback,
        invalidCallback,
        requestedCallback,
        initiatedCallback,
        finishedCallback,
        verifiedCallback,
        unverifiedCallback,
        expiredCallback,
        downloadedCallback,
      ].forEach((callback) => this.store.off(callback));
    });
  }

  private registerProducts(productIdList: Array<string>): void {
    const productOptionsList = productIdList.map<IAPProductOptions>((id) => ({
      id,
      type: this.store.CONSUMABLE,
    }));
    this.store.register(productOptionsList);
  }

  private ready(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.store.ready(() => {
        subscriber.next();
        subscriber.complete();
      });
    });
  }
}
