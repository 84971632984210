import { Observable, Observer, Subject, Subscription } from 'rxjs';

enum QueueItemType {
  VALUE,
  ERROR,
}

/**
 * 구독자가 없는 경우 큐에 데이터가 쌓이며, 구독자가 생기는 경우 큐에 쌓인 모든 데이터를 방출합니다.
 * 구독자가 있는 상태에서 next가 호출되면 일반 Subject처럼 작동합니다.
 */
export class QueueSubject<T> extends Subject<T> {
  private queue: Array<[QueueItemType, T]>;
  private subject: Subject<T>;

  /**
   * @param queueSize 큐 크기 (기본값 무제한)
   */
  constructor(private queueSize?: number) {
    super();
    this.queue = [];
    this.subject = new Subject();
  }

  next(value: T): void {
    if (this.subject.observed) {
      this.subject.next(value);
    } else {
      this.queue.push([QueueItemType.VALUE, value as T]);
      if (this.queueSize != null && this.queue.length > this.queueSize) {
        this.queue.shift();
      }
    }
  }

  error(err: any): void {
    if (this.subject.observed) {
      this.subject.error(err);
    } else {
      this.queue.push([QueueItemType.ERROR, err]);
      if (this.queueSize != null && this.queue.length > this.queueSize) {
        this.queue.shift();
      }
    }
  }

  complete(): void {
    this.subject.complete();
  }

  subscribe(observer?: Partial<Observer<T>>): Subscription;
  subscribe(next: (value: T) => void): Subscription;
  /**
   * @deprecated Instead of passing separate callback arguments, use an observer argument.
   * Signatures taking separate callback arguments will be removed in v8.
   * Details: https://rxjs.dev/deprecations/subscribe-arguments
   */
  subscribe(next?: ((value: T) => void) | null, error?: ((error: any) => void) | null, complete?: (() => void) | null): Subscription;
  subscribe(
    observerOrNext?: Partial<Observer<T>> | ((value: T) => void) | null,
    error?: ((error: any) => void) | null,
    complete?: (() => void) | null
  ): Subscription {
    const obs = new Observable<T>((subscriber) => {
      for (let i = 0, len = this.queue.length; i < len; i += 1) {
        const replay = this.queue[i];
        if (replay[0] === QueueItemType.VALUE) {
          subscriber.next(replay[1]);
        } else {
          subscriber.error(replay[1]);
        }
      }
      this.queue = [];
      const subscription = this.subject.subscribe(subscriber);
      return () => {
        subscription.unsubscribe();
      };
    });
    return typeof observerOrNext === 'object' && observerOrNext != null ?
      obs.subscribe(observerOrNext) :
      obs.subscribe(observerOrNext, error, complete);
  }
}
