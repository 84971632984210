import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { defer, EMPTY, from, Observable, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AlertService } from '../../services/alert';
import { AuthService } from '../../services/auth';
import { unsubscribeAll } from '../../utils/unsubscribe-all';

@Component({
  selector: 'app-login-waikiki-modal',
  templateUrl: './login-waikiki-modal.component.html',
  styleUrls: ['./login-waikiki-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginWaikikiModalComponent implements OnInit, OnDestroy {
  loginScreenId?: string;

  isLoggingIn = false;
  get canLogin(): boolean {
    return !!(this.email && this.password && !this.isLoggingIn);
  }

  email = '';
  password = '';

  private loginSubscription?: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private alertService: AlertService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loginScreenId = this.navParams.get<string>('loginScreenId');
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.loginSubscription,
    ]);
  }

  onClickLogin(): void {
    if (this.isLoggingIn) {
      return;
    }

    if (!this.email) {
      this.alertService.alert('알림', '이메일을 입력해주세요.');
      return;
    }

    if (!this.password) {
      this.alertService.alert('알림', '비밀번호를 입력해주세요.');
      return;
    }

    unsubscribeAll([this.loginSubscription]);
    this.loginSubscription = this.login(this.email, this.password).subscribe();
  }

  onClickRegister(): void {
    this.modalCtrl.dismiss();
  }

  onClickResetPassword(): void {
    this.modalCtrl.dismiss();
  }

  private login(email: string, password: string): Observable<void> {
    return defer(() => {
      this.isLoggingIn = true;
      this.changeDetectorRef.markForCheck();

      return from(this.authService.login(email, password, this.loginScreenId)).pipe(
        tap(() => {
          this.modalCtrl.dismiss();
        }),
        catchError((err) => {
          this.alertService.alertAPIError(err);
          return EMPTY;
        }),
        finalize(() => {
          this.isLoggingIn = false;
          this.changeDetectorRef.markForCheck();
        }),
      );
    });
  }
}
