import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, merge, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { unsubscribeAll } from '../../utils/unsubscribe-all';

@Injectable({
  providedIn: 'root'
})
export class GuardUtilService implements OnDestroy {
  get hasRouted(): boolean {
    return this.hasRoutedInner;
  }
  get hasInteracted(): boolean {
    return this.hasInteractedInner;
  }

  private hasRoutedInner = false;
  private hasInteractedInner = false;

  private routerEventsSubscription?: Subscription;
  private interactionSubscription?: Subscription;

  constructor(
    private router: Router,
  ) {}

  init(): void {
    this.routerEventsSubscription = this.router.events.pipe(
      filter((ev): ev is NavigationEnd => ev instanceof NavigationEnd),
      take(1),
    ).subscribe(() => {
      this.hasRoutedInner = true;
    });

    if (typeof document !== 'undefined') {
      // https://html.spec.whatwg.org/multipage/interaction.html#user-activation-processing-model
      this.interactionSubscription = merge(
        fromEvent(document, 'change'),
        fromEvent(document, 'click'),
        fromEvent(document, 'contextmenu'),
        fromEvent(document, 'dblclick'),
        fromEvent(document, 'mouseup'),
        fromEvent(document, 'pointerup'),
        fromEvent(document, 'reset'),
        fromEvent(document, 'submit'),
        fromEvent(document, 'touchend'),
      ).pipe(
        take(1),
      ).subscribe(() => {
        this.hasInteractedInner = true;
      });
    }
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.routerEventsSubscription,
      this.interactionSubscription,
    ]);
  }
}
