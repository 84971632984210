import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getAccessTokenPromise().then((accessToken) => {
      if (accessToken) {
        return true;
      }
      this.router.navigate(['/login'], {
        queryParams: { r: state.url },
        state: { rState: this.router.getCurrentNavigation()?.extras.state },
      });
      return false;
    });
  }
}
