import { APIErrorCode } from '../../constants/api-error-code.constant';

type JsonAllowed = string | number | boolean | Array<JsonAllowed> | { [key: string]: JsonAllowed; } | null;

/**
 * API 응답
 */
export interface APIResponse<T = any> {
  isSuccess: true;
  result: T;
}

/**
 * API 오류 응답
 */
export interface APIResponseFailure {
  isSuccess: false;
  error: APIResponseFailureError;
}

/**
 * API 응답 내 오류
 */
export interface APIResponseFailureError {
  /**
   * 오류 코드
   */
  code: APIErrorCode;
  /**
   * 오류 이름
   */
  name: string;
  /**
   * 오류 메시지
   */
  message: string;
  /**
   * 오류 스택
   */
  stack?: string;
  /**
   * 오류 추가 정보
   */
  additionalInfo?: JsonAllowed;
}

/**
 * API 오류
 */
export class APIError extends Error {
  /**
   * HTTP 상태 코드
   */
  statusCode: number;
  /**
   * API 응답 내 오류 원본
   */
  response: APIResponseFailureError;
  /**
   * 오류 추가 정보
   */
  additionalInfo?: JsonAllowed;

  constructor(statusCode: number, response: APIResponseFailureError) {
    super(response.message);
    this.statusCode = statusCode;
    this.response = response;
    this.additionalInfo = response.additionalInfo;
  }
}
