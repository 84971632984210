import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import { environment } from '../environments/environment';
import { BootService } from './services/boot';
import { ScreenTrackingService } from './services/screen-tracking';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet, { static: true }) ionRouterOutlet!: IonRouterOutlet;

  constructor(
    private platform: Platform,
    private bootService: BootService,
    private screenTrackingService: ScreenTrackingService,
  ) {
    if (this.platform.is('ios') && !this.platform.is('capacitor')) {
      this.enableIosOverscrollTrick();
    }
  }

  ngOnInit(): void {
    this.bootService.boot({
      ionRouterOutlet: this.ionRouterOutlet,
      facebookPixelId: environment.facebookPixelId,
      facebookAppId: environment.facebookAppId,
      naverAnalytics: environment.naverAnalytics,
    });
  }

  /**
   * iOS Safari 환경에서 ion-content의 양끝에서 오버스크롤하는 경우 HTML이 오버스크롤되는 것을 방지합니다.
   */
  private enableIosOverscrollTrick(): void {
    const ionContentScrollListener = (ev: Event) => {
      const elem = ev.target as HTMLElement;
      const scroll = elem.scrollTop;
      const scrollMin = 0;
      const scrollMax = elem.scrollHeight - elem.clientHeight;
      const overscrolled = elem.dataset.overscrolled != null;
      if (!overscrolled && (scroll > scrollMax || scroll < scrollMin)) {
        elem.setAttribute('data-overscrolled', '');
      } else if (overscrolled && scroll <= scrollMax && scroll > scrollMin) {
        elem.removeAttribute('data-overscrolled');
        setTimeout(() => {
          elem.scrollTo({ top: scroll - 1 });
        });
      } else if (overscrolled && scroll >= scrollMin && scroll < scrollMax) {
        elem.removeAttribute('data-overscrolled');
        setTimeout(() => {
          elem.scrollTo({ top: scroll + 1 });
        });
      }
    };
    const ionContentObserver = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === document.ELEMENT_NODE) {
            const elem = node as HTMLElement;
            const ionContents: Array<HTMLElement> = [];
            if (elem.matches('ion-content:not([data-overscroll-fix])')) {
              ionContents.push(elem);
            }
            elem.querySelectorAll('ion-content:not([data-overscroll-fix])').forEach((ionContent) => {
              ionContents.push(ionContent as HTMLElement);
            });
            for (const ionContent of ionContents) {
              ionContent.setAttribute('data-overscroll-fix', '');
              const shadowRoot = ionContent.shadowRoot ?? ionContent.attachShadow({ mode: 'open' });
              const main = shadowRoot.querySelector('main');
              if (main) {
                main.scrollTo({ top: 1 });
                main.addEventListener('scroll', ionContentScrollListener);
              } else {
                const mainObserver = new MutationObserver((mutations2) => {
                  mutations2.forEach((mutation2) => {
                    mutation2.addedNodes.forEach((node2) => {
                      if (node2.nodeType === document.ELEMENT_NODE) {
                        const elem2 = node2 as HTMLElement;
                        const mains2: Array<HTMLElement> = [];
                        if (elem2.matches('main')) {
                          mains2.push(elem2);
                        }
                        elem2.querySelectorAll('main').forEach((main2) => {
                          mains2.push(main2);
                        });
                        mains2.forEach((main2) => {
                          setTimeout(() => {
                            main2.scrollTo({ top: 1 });
                            main2.addEventListener('scroll', ionContentScrollListener);;
                          });
                        });
                        mainObserver.disconnect();
                      }
                    });
                  });
                });
                mainObserver.observe(shadowRoot, { childList: true, subtree: true });
              }
            }
          }
        });
      }
    });
    ionContentObserver.observe(document.documentElement, { childList: true, subtree: true });
  }
}
