import { Injectable } from '@angular/core';
import { Capacitor, Plugins } from '@capacitor/core';
import { DateTime } from 'luxon';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../api';
import { VersionInfo } from './version.type';

const { Device } = Plugins;

function vercmp(a: string, b: string): number;
function vercmp(a: string | null | undefined, b: string | null | undefined): number | null;
function vercmp(a: string | null | undefined, b: string | null | undefined): number | null {
  if (a == null || b == null) {
    return null;
  }

  let aDotIdx = -1;
  let bDotIdx = -1;
  do {
    const aStartIdx = aDotIdx + 1;
    const bStartIdx = bDotIdx + 1;
    aDotIdx = a.indexOf('.', aDotIdx + 1);
    bDotIdx = b.indexOf('.', bDotIdx + 1);
    const aPart = a.slice(aStartIdx, aDotIdx !== -1 ? aDotIdx : a.length);
    const bPart = b.slice(bStartIdx, bDotIdx !== -1 ? bDotIdx : b.length);
    const sub = +aPart - +bPart;
    if (sub) {
      return Math.sign(sub);
    }
  } while (aDotIdx > -1 && bDotIdx > -1);

  if (aDotIdx > -1 && bDotIdx === -1) {
    return 1;
  } else if (aDotIdx === -1 && bDotIdx > -1) {
    return -1;
  }

  return 0;
}

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  get currentVersion(): string | null {
    return this.currentVersionInner;
  }
  get latestVersion(): string | null {
    return this.latestVersionInner;
  }
  get latestVersionCheckedAt(): DateTime | null {
    return this.latestVersionCheckedAtInner;
  }
  get isUpdateAvailable(): boolean | null {
    const cmp = vercmp(this.latestVersion, this.currentVersion);
    return cmp == null ? null : cmp > 0;
  }

  private currentVersionInner: string | null = null;
  private latestVersionInner: string | null = null;
  private requiredMinVersionInner: string | null = null;
  private latestVersionCheckedAtInner: DateTime | null = null;

  constructor(
    private apiService: ApiService,
  ) {}

  /**
   * 앱 버전과 서버 버전 정보를 가져옵니다.
   * @returns 업데이트 필요 여부
   */
  async init(versionInfo?: VersionInfo): Promise<boolean | null> {
    return Promise.all([
      this.updateCurrentVersion().catch((err) => { console.error(err); }),
      this.updateLatestVersion(versionInfo).catch((err) => { console.error(err); }),
    ]).then(() => {
      if (this.requiredMinVersionInner && this.currentVersionInner) {
        const cmp = vercmp(this.requiredMinVersionInner, this.currentVersionInner);
        if (cmp != null) {
          return cmp > 0;
        }
      }
      return null;
    });
  }

  async updateLatestVersion(versionInfo?: VersionInfo): Promise<string | null> {
    const version = versionInfo ?? (await lastValueFrom(this.apiService.newsV1Version({ platform: Capacitor.getPlatform() }))).result;
    this.latestVersionInner = version.latestVersion;
    this.requiredMinVersionInner = version.requiredMinVersion;
    this.latestVersionCheckedAtInner = DateTime.utc();
    return version.latestVersion;
  }

  private async updateCurrentVersion(): Promise<string | null> {
    const version = (await Device.getInfo())?.appVersion || null;
    this.currentVersionInner = version;
    return version;
  }
}
