import { Injectable, TemplateRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertInput } from '@ionic/core';
import { EMPTY, firstValueFrom, map, Observable } from 'rxjs';
import { AlertModalComponent } from '../../components/alert-modal';
import { openModal } from '../../utils/open-modal';
import { APIError } from '../api';
import { AlertButton2 } from './alert.type';

export type AlertButtonFill = 'clear' | 'outline' | 'solid';

const DEFAULT_OK_TEXT = '확인';
const DEFAULT_CANCEL_TEXT = '취소';
const DEFAULT_BUTTON_FILL: AlertButtonFill = 'solid';
const DEFAULT_SHOW_CLOSE_BUTTON = false;

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private modalCtrl: ModalController,
  ) {}

  /**
   * 알림 팝업을 표시합니다. 알림 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 이행합니다.
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   * @param buttonFill 버튼 fill
   */
  async alert(
    header: string,
    message: string | TemplateRef<unknown>,
    okButton: string | AlertButton2 = DEFAULT_OK_TEXT,
    buttonFill: AlertButtonFill = DEFAULT_BUTTON_FILL,
    showCloseButton: boolean = DEFAULT_SHOW_CLOSE_BUTTON,
  ): Promise<boolean> {
    return firstValueFrom(
      this.alert$(header, message, okButton, buttonFill, showCloseButton),
    );
  }

  /**
   * 알림 팝업을 표시합니다. 알림 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 이행합니다.
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   * @param buttonFill 버튼 fill
   */
  alert$(
    header: string,
    message: string | TemplateRef<unknown>,
    okButton: string | AlertButton2 = DEFAULT_OK_TEXT,
    buttonFill: AlertButtonFill = DEFAULT_BUTTON_FILL,
    showCloseButton: boolean = DEFAULT_SHOW_CLOSE_BUTTON,
  ): Observable<boolean> {
    return openModal(this.modalCtrl, {
      component: AlertModalComponent,
      componentProps: {
        header,
        message,
        buttons: [okButton],
        buttonFill,
        showCloseButton,
      },
      cssClass: 'app-alert',
    }).pipe(
      map((dismissDetail) => !(dismissDetail.role != null ? ['cancel', 'backdrop'].includes(dismissDetail.role) : false)),
    );
  }

  /**
   * 확인 팝업을 표시합니다. 확인 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 이행합니다.
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   * @param buttonFill 버튼 fill
   */
  async confirm(
    header: string,
    message: string | TemplateRef<unknown>,
    okButton: string | AlertButton2 = DEFAULT_OK_TEXT,
    cancelButton: string | AlertButton2 = DEFAULT_CANCEL_TEXT,
    buttonFill: AlertButtonFill = DEFAULT_BUTTON_FILL,
    showCloseButton: boolean = DEFAULT_SHOW_CLOSE_BUTTON,
  ): Promise<boolean> {
    return firstValueFrom(
      this.confirm$(header, message, okButton, cancelButton, buttonFill, showCloseButton),
    );
  }

  /**
   * 확인 팝업을 표시합니다. 확인 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 이행합니다.
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   * @param buttonFill 버튼 fill
   */
  confirm$(
    header: string,
    message: string | TemplateRef<unknown>,
    okButton: string | AlertButton2 = DEFAULT_OK_TEXT,
    cancelButton: string | AlertButton2 = DEFAULT_CANCEL_TEXT,
    buttonFill: AlertButtonFill = DEFAULT_BUTTON_FILL,
    showCloseButton: boolean = DEFAULT_SHOW_CLOSE_BUTTON,
  ): Observable<boolean> {
    const cancelButton2: AlertButton2 =
      typeof cancelButton === 'string' ?
      { text: cancelButton, role: 'cancel' } :
      cancelButton;

    return openModal(this.modalCtrl, {
      component: AlertModalComponent,
      componentProps: {
        header,
        message,
        buttons: [okButton, cancelButton2],
        buttonFill,
        showCloseButton,
      },
      cssClass: 'app-alert',
    }).pipe(
      map((dismissDetail) => !(dismissDetail.role != null ? ['cancel', 'backdrop'].includes(dismissDetail.role) : false)),
    );
  }

  /**
   * 프롬프트 팝업을 표시합니다. 확인 팝업이 닫힐 때 입력한 데이터를 이행합니다.
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param prompt 프롬프트
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   * @param buttonFill 버튼 fill
   */
  async prompt<T = string>(
    header: string,
    message: string | TemplateRef<unknown>,
    prompt: string | AlertInput,
    okButton: string | AlertButton2 = DEFAULT_OK_TEXT,
    cancelButton: string | AlertButton2 = DEFAULT_CANCEL_TEXT,
    buttonFill: AlertButtonFill = DEFAULT_BUTTON_FILL,
    showCloseButton: boolean = DEFAULT_SHOW_CLOSE_BUTTON,
  ): Promise<T> {
    return firstValueFrom(
      this.prompt$(header, message, prompt, okButton, cancelButton, buttonFill, showCloseButton),
    );
  }

  /**
   * 프롬프트 팝업을 표시합니다. 확인 팝업이 닫힐 때 입력한 데이터를 이행합니다.
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param prompt 프롬프트
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   * @param buttonFill 버튼 fill
   */
  prompt$<T = string>(
    header: string,
    message: string | TemplateRef<unknown>,
    prompt: string | AlertInput,
    okButton: string | AlertButton2 = DEFAULT_OK_TEXT,
    cancelButton: string | AlertButton2 = DEFAULT_CANCEL_TEXT,
    buttonFill: AlertButtonFill = DEFAULT_BUTTON_FILL,
    showCloseButton: boolean = DEFAULT_SHOW_CLOSE_BUTTON,
  ): Observable<T> {
    const prompt2: AlertInput =
      typeof prompt === 'string' ?
      { name: 'value', type: 'text', placeholder: prompt } :
      prompt;
    const cancelButton2: AlertButton2 =
      typeof cancelButton === 'string' ?
      { text: cancelButton, role: 'cancel' } :
      cancelButton;

    return openModal(this.modalCtrl, {
      component: AlertModalComponent,
      componentProps: {
        header,
        message,
        inputs: [prompt2],
        buttons: [okButton, cancelButton2],
        buttonFill,
        showCloseButton,
      },
      cssClass: 'app-alert',
    }).pipe(
      map((dismissDetail) => !(dismissDetail.role != null ? ['cancel', 'backdrop'].includes(dismissDetail.role) : false) ?
        dismissDetail.data?.values[prompt2.name || 0] :
        null,
      ),
    );
  }

  /**
   * `err` 가 `APIError` 인 경우 알림 팝업을 표시합니다. 그렇지 않은 경우 `console.error(err)` 를 호출합니다.
   * @param err 오류
   */
  async alertAPIError(err: Error | unknown): Promise<void> {
    return firstValueFrom(
      this.alertAPIError$(err),
    );
  }

  /**
   * `err` 가 `APIError` 인 경우 알림 팝업을 표시합니다. 그렇지 않은 경우 `console.error(err)` 를 호출합니다.
   * @param err 오류
   */
  alertAPIError$(err: Error | unknown): Observable<void> {
    if (err instanceof APIError) {
      return this.alert$('알림', err.message).pipe(
        map(() => undefined),
      );
    } else {
      console.error(err);
      return EMPTY;
    }
  }
}
