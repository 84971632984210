import { Plugins } from '@capacitor/core';
import { DateTime, Duration, DurationLikeObject } from 'luxon';
import { concat, EMPTY, from, Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isNotNullish } from '../etc.util';

const { Storage } = Plugins;

export interface Cache<T> {
  value: T;
  expiresAt?: string;
}

export interface CacheOptions {
  noCache?: boolean;
  expiry?: Duration | number | DurationLikeObject;
}

export function cache<T>(key: string, options?: CacheOptions): OperatorFunction<T, T> {
  return function(source: Observable<T>): Observable<T> {
    const storageKey = `cache.${key}`;

    const getCache$ = from(Storage.get({ key: storageKey })).pipe(
      switchMap(async (result) => {
        try {
          return result.value != null ? JSON.parse(result.value) as Cache<T> : null;
        } catch {
          await Storage.remove({ key: storageKey });
          return null;
        }
      }),
      filter(isNotNullish),
      filter<Cache<T>>((cacheObj) =>
        cacheObj != null &&
        (cacheObj?.expiresAt == null || DateTime.fromISO(cacheObj.expiresAt) > DateTime.local())
      ),
      map((cacheObj) => cacheObj?.value),
      catchError(() => EMPTY),
    );

    const setCachePipe = switchMap(async (value: T) => {
      const cacheObj: Cache<T> = {
        value,
      };
      if (options?.expiry != null) {
        cacheObj.expiresAt = DateTime.local().plus(options?.expiry).toISO();
      }
      const cacheStr = JSON.stringify(cacheObj);
      await Storage.set({ key: storageKey, value: cacheStr });
      return value;
    });

    const removeCacheOnErrorPipe = catchError<T, Observable<T>>((err) => from(Storage.remove({ key: storageKey })).pipe(
      switchMap(() => throwError(err)),
    ));

    return (getCache$ != null ? concat(getCache$, source) : source)
      .pipe(setCachePipe, removeCacheOnErrorPipe);
  };
}
