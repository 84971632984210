import { ModalController, ModalOptions } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { concatMap, defer, finalize, from, Observable, tap } from 'rxjs';

const BACKDROP = 'backdrop';

export function openModal<T = any>(
  modalController: ModalController,
  opts: ModalOptions,
  finalizeRole: string = BACKDROP,
): Observable<OverlayEventDetail<T>> {
  return defer(() => {
    let modal: HTMLIonModalElement | undefined;
    return from(modalController.create(opts)).pipe(
      tap((m) => (modal = m).present()),
      concatMap((m) => m.onWillDismiss<T>()),
      finalize(() => modal?.dismiss(undefined, finalizeRole)),
    );
  });
}
