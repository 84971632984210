import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppDownloadGuard } from './guards/app-download';
import { AuthGuard } from './guards/auth';
import { BootGuard } from './guards/boot';
import { DevLockGuard } from './guards/dev-lock';
import { IosUseCouponGuard } from './guards/ios-use-coupon';

const routes: Routes = [
  {
    path: 'boot',
    loadChildren: () => import('./pages/boot').then( m => m.BootPageModule)
  },
  {
    path: 'dev-lock',
    canActivate: [BootGuard],
    loadChildren: () => import('./pages/dev-lock').then( m => m.DevLockPageModule)
  },
  {
    path: '',
    canActivate: [BootGuard],
    canActivateChild: [DevLockGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/main').then( m => m.MainPageModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/login').then( m => m.LoginPageModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/register').then( m => m.RegisterPageModule)
      },
      {
        path: 'movie',
        loadChildren: () => import('./pages/work').then( m => m.WorkPageModule)
      },
      // {
      //   path: 'drama',
      //   loadChildren: () => import('./pages/work').then( m => m.WorkPageModule)
      // },
      {
        path: 'ticket-history',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/ticket-history').then( m => m.TicketHistoryPageModule)
      },
      {
        path: 'ticket-usage',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/ticket-usage').then( m => m.TicketUsagePageModule)
      },
      {
        path: 'setting',
        loadChildren: () => import('./pages/setting').then( m => m.SettingPageModule)
      },
      {
        path: 'account-info',
        loadChildren: () => import('./pages/account-info').then( m => m.AccountInfoPageModule)
      },
      {
        path: 'ticket-buy',
        loadChildren: () => import('./pages/ticket-buy').then( m => m.TicketBuyPageModule)
      },
      {
        path: 'purchase',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/purchase').then( m => m.PurchasePageModule)
      },
      {
        path: 'refund',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/refund').then( m => m.RefundPageModule)
      },
      {
        path: 'promotion',
        loadChildren: () => import('./pages/promotion').then( m => m.PromotionPageModule)
      },
      {
        path: 'notice',
        loadChildren: () => import('./pages/notice').then( m => m.NoticePageModule)
      },
      {
        path: 'qna',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/qna').then( m => m.QnaPageModule)
      },
      {
        path: 'change-email',
        loadChildren: () => import('./pages/change-email').then( m => m.ChangeEmailPageModule)
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/change-password').then( m => m.ChangePasswordPageModule)
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./pages/reset-password').then( m => m.ResetPasswordPageModule)
      },
      {
        path: 'term',
        loadChildren: () => import('./pages/term').then( m => m.TermPageModule)
      },
      {
        path: 'sub-cap',
        loadChildren: () => import('./pages/sub-cap').then( m => m.SubCapPageModule)
      },
      {
        path: 'register-external',
        loadChildren: () => import('./pages/register-external').then( m => m.RegisterExternalPageModule)
      },
      {
        path: 'oauth-naver',
        loadChildren: () => import('./pages/oauth').then( m => m.OauthPageModule)
      },
      {
        path: 'payment-result',
        loadChildren: () => import('./pages/payment-result').then( m => m.PaymentResultPageModule)
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/search').then(m => m.SearchPageModule)
      },
      {
        path: 'notification',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/notification').then(m => m.NotificationPageModule)
      },
      {
        path: 'app-download',
        canActivate: [AppDownloadGuard],
        loadChildren: () => import('./pages/app-download').then(m => m.AppDownloadPageModule)
      },
      {
        path: 'use-coupon',
        canActivate: [IosUseCouponGuard],
        loadChildren: () => import('./pages/use-coupon').then(m => m.UseCouponPageModule)
      },
      {
        path: 'version',
        loadChildren: () => import('./pages/version').then(m => m.VersionPageModule)
      },
      {
        path: 'dev-setting',
        loadChildren: () => import('./pages/dev-setting').then((m) => m.DevSettingPageModule),
      },
      {
        path: 'ticket-pass',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/ticket-pass').then((m) => m.TicketPassPageModule),
      },
      // {
      //   path: '',
      //   redirectTo: 'home',
      //   pathMatch: 'full'
      // },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/not-found').then( m => m.NotFoundPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
