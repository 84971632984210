import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableFacebookSdk } from './facebook-sdk';

import { interceptBeforeInstallPrompt } from './install-prompt';

interceptBeforeInstallPrompt();
enableFacebookSdk(environment.facebookAppId);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
