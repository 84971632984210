<section class="alert-section">
  <div class="alert-header" [class.with-close-button]="showCloseButton">
    <div class="alert-header-text">{{ header }}</div>
    <ion-button *ngIf="showCloseButton" class="alert-close-button" color="dark" fill="clear" (click)="onClickClose()">
      <ion-icon class="alert-close-button-icon" name="wai-nav-close"></ion-icon>
    </ion-button>
  </div>
  <div class="alert-content">
    <div *ngIf="message != null" class="alert-message">{{ message }}</div>
    <div *ngIf="messageTemplate != null" class="alert-message">
      <ng-container *ngTemplateOutlet="messageTemplate"></ng-container>
    </div>
    <div *ngIf="inputs?.length" class="alert-inputs">
      <ion-item *ngFor="let input of inputs; let i = index">
        <ion-input
          [type]="input.type || 'text'"
          [name]="input.name || i"
          [placeholder]="input.placeholder || ''"
          [value]="input.value || input.checked"
          [disabled]="!!input.disabled"
          [min]="input.min"
          [max]="input.max"
          (ionChange)="onChangeInput(input, i, $event)">
        </ion-input>
      </ion-item>
    </div>
  </div>
  <div class="alert-footer">
    <div class="alert-buttons">
      <ion-button *ngFor="let button of buttons" class="alert-button" [color]="button.color || 'primary'" [fill]="buttonFill || 'clear'" (click)="onClickButton(button)">{{ button.text }}</ion-button>
    </div>
  </div>
</section>
