<div class="input-wrapper">
  <ng-content select="[slot=start]"></ng-content>
  <input
    *ngIf="type !== 'textarea'"
    #input
    class="input"
    [attr.accept]="accept ?? null"
    [attr.autocapitalize]="autocapitalize ?? null"
    [attr.autocomplete]="autocomplete ?? null"
    [attr.autocorrect]="autocorrect ?? null"
    [attr.autofocus]="autofocus ? '' : null"
    [attr.enterkeyhint]="enterkeyhint ?? null"
    [attr.inputmode]="inputmode ?? null"
    [attr.max]="max ?? null"
    [attr.maxlength]="maxlength ?? null"
    [attr.min]="min ?? null"
    [attr.minlength]="minlength ?? null"
    [attr.multiple]="multiple ? '' : null"
    [attr.pattern]="pattern ?? null"
    [attr.placeholder]="placeholder ?? null"
    [attr.readonly]="readonly ? '' : null"
    [attr.required]="required ? '' : null"
    [attr.spellcheck]="spellcheck ? '' : null"
    [attr.step]="step ?? null"
    [attr.type]="type ?? null"
    [disabled]="disabled"
    [ngModel]="value"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (ngModelChange)="onModelChange($event)">
  <textarea
    *ngIf="type === 'textarea'"
    #textarea
    class="input input-textarea"
    [attr.accept]="accept ?? null"
    [attr.autocapitalize]="autocapitalize ?? null"
    [attr.autocomplete]="autocomplete ?? null"
    [attr.autocorrect]="autocorrect ?? null"
    [attr.autofocus]="autofocus ? '' : null"
    [attr.enterkeyhint]="enterkeyhint ?? null"
    [attr.inputmode]="inputmode ?? null"
    [attr.max]="max ?? null"
    [attr.maxlength]="maxlength ?? null"
    [attr.min]="min ?? null"
    [attr.minlength]="minlength ?? null"
    [attr.multiple]="multiple ? '' : null"
    [attr.pattern]="pattern ?? null"
    [attr.placeholder]="placeholder ?? null"
    [attr.readonly]="readonly ? '' : null"
    [attr.required]="required ? '' : null"
    [attr.spellcheck]="spellcheck ? '' : null"
    [attr.step]="step ?? null"
    [disabled]="disabled"
    [ngModel]="value"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (ngModelChange)="onModelChange($event)"></textarea>
  <ng-content></ng-content>
  <ng-content select="[slot=end]"></ng-content>
</div>
