<section class="modal-section">
  <div class="modal-header">
    <div class="modal-header-text" [innerHTML]="formatMessage(headerTemplate)"></div>
  </div>
  <div class="modal-content">
    <div class="modal-message" [innerHTML]="formatMessage(messageTemplate)"></div>
    <div *ngFor="let receivedTicket of receivedTickets" class="modal-ticket" [innerHTML]="formatTicket(getTicketTemplate(receivedTicket), receivedTicket)"></div>
  </div>
  <div class="modal-footer">
    <div class="modal-buttons">
      <ion-button class="modal-button" (click)="onClickClose()" [innerHTML]="formatMessage(buttonTemplate)"></ion-button>
    </div>
  </div>
</section>
