export enum APIErrorCode {
  RESPONSE_TIMEOUT = 'RESPONSE_TIMEOUT',
  INSUFFICIENT_PERMISSION = 'INSUFFICIENT_PERMISSION',
  AUTH_TOKEN_REQUIRED = 'AUTH_TOKEN_REQUIRED',
  AUTH_TOKEN_EXPIRED = 'AUTH_TOKEN_EXPIRED',
  INVALID_PARAMS = 'INVALID_PARAMS',
  EMAIL_OR_PASSWORD_DOES_NOT_MATCH = 'EMAIL_OR_PASSWORD_DOES_NOT_MATCH',
  GETTING_EXTERNAL_AUTH_INFO_FAILED = 'GETTING_EXTERNAL_AUTH_INFO_FAILED',
  EXTERNAL_AUTH_TOKEN_REQUIRED = 'EXTERNAL_AUTH_TOKEN_REQUIRED',
  EXTERNAL_AUTH_MISMATCH = 'EXTERNAL_AUTH_MISMATCH',
  EXTERNAL_AUTH_UNLINK_FAILED = 'EXTERNAL_AUTH_UNLINK_FAILED',
  EMAIL_EMPTY = 'EMAIL_EMPTY',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_EMAIL_CODE = 'INVALID_EMAIL_CODE',
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
  USER_ALREADY_REGISTERED = 'USER_ALREADY_REGISTERED',
  INVALID_NICKNAME = 'INVALID_NICKNAME',
  USER_NICKNAME_DUPLICATED = 'USER_NICKNAME_DUPLICATED',
  USER_NOT_REGISTERED = 'USER_NOT_REGISTERED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVALID_SEESION = 'INVALID_SEESION',
  INVALID_EMAIL_CODE_PURPOSE = 'INVALID_EMAIL_CODE_PURPOSE',
  DELETING_USER_FAILED = 'DELETING_USER_FAILED',
  PASSWORD_DOES_NOT_MATCH = 'PASSWORD_DOES_NOT_MATCH',
  CANNOT_CHANGE_PASSWORD_OF_EXTERNAL_AUTHED_USER = 'CANNOT_CHANGE_PASSWORD_OF_EXTERNAL_AUTHED_USER',
  TICKET_BUY_OPTION_NOT_FOUND = 'TICKET_BUY_OPTION_NOT_FOUND',
  PURCHASE_NOT_FOUND = 'PURCHASE_NOT_FOUND',
  PURCHASE_NOT_IN_PENDING = 'PURCHASE_NOT_IN_PENDING',
  PAYMENT_NOT_FOUND = 'PAYMENT_NOT_FOUND',
  PAYMENT_NOT_COMPLETED = 'PAYMENT_NOT_COMPLETED',
  PURCHASE_AND_PAYMENT_DO_NOT_MATCH = 'PURCHASE_AND_PAYMENT_DO_NOT_MATCH',
  PURCHASE_NOT_REFUNDABLE = 'PURCHASE_NOT_REFUNDABLE',
  PURCHASE_NOT_REFUNDABLE_WHEN_TICKETS_ARE_USED = 'PURCHASE_NOT_REFUNDABLE_WHEN_TICKETS_ARE_USED',
  PURCHASE_ALREADY_REFUNDED = 'PURCHASE_ALREADY_REFUNDED',
  PAYMENT_NOT_CANCELABLE = 'PAYMENT_NOT_CANCELABLE',
  INVALID_PROGRESS = 'INVALID_PROGRESS',
  SUB_CAP_NOT_FOUND = 'SUB_CAP_NOT_FOUND',
  WORK_NOT_FOUND = 'WORK_NOT_FOUND',
  NO_PERMISSION_TO_READ_CONTENT = 'NO_PERMISSION_TO_READ_CONTENT',
  WORK_OR_MOVIE_CAP_NOT_FOUND = 'WORK_OR_MOVIE_CAP_NOT_FOUND',
  MOVIE_CAP_RENT_ONE_KIND_OF_TICKET = 'MOVIE_CAP_RENT_ONE_KIND_OF_TICKET',
  MOVIE_CAP_ALREADY_RENTED = 'MOVIE_CAP_ALREADY_RENTED',
  CONNECTING_PAYMENT_SERVER_FAILED = 'CONNECTING_PAYMENT_SERVER_FAILED',
  VERIFYING_PAYMENT_FAILED = 'VERIFYING_PAYMENT_FAILED',
  CANCELING_PAYMENT_FAILED = 'CANCELING_PAYMENT_FAILED',
  INSUFFICIENT_TICKET_BALANCE = 'INSUFFICIENT_TICKET_BALANCE',
  KAKAOPAY_READY_FAILED = 'KAKAOPAY_READY_FAILED',
  KAKAOPAY_APPROVE_FAILED = 'KAKAOPAY_APPROVE_FAILED',
  KAKAOPAY_ORDER_FAILED = 'KAKAOPAY_ORDER_FAILED',
  KAKAOPAY_CANCEL_FAILED = 'KAKAOPAY_CANCEL_FAILED',
  UPCOMING_NOT_FOUND = 'UPCOMING_NOT_FOUND',
  PURCHASE_DUPLICATED = 'PURCHASE_DUPLICATED',
  PUSH_NOTIFICATION_DISABLED = 'PUSH_NOTIFICATION_DISABLED',
  SEARCH_TEXT_TOO_LONG = 'SEARCH_TEXT_TOO_LONG',
  INVALID_COUPON = 'INVALID_COUPON',
  APP_ONLY_COUPON = 'APP_ONLY_COUPON',
  COUPON_ALREADY_USED = 'COUPON_ALREADY_USED',
  COUPON_CODE_LIMIT_REACHED = 'COUPON_CODE_LIMIT_REACHED',
  COUPON_TYPE_LIMIT_REACHED = 'COUPON_TYPE_LIMIT_REACHED',
  COUPON_CODE_EXPIRED = 'COUPON_CODE_EXPIRED',
  APP_ONLY_CONTENT = 'APP_ONLY_CONTENT',
  MOVIE_CAP_RENT_FAILED = 'MOVIE_CAP_RENT_FAILED',
  CANNOT_USE_TICKET_PASS_FOR_NON_WORK = 'CANNOT_USE_TICKET_PASS_FOR_NON_WORK',
  TICKET_PASS_CONDITION_DOES_NOT_MATCH = 'TICKET_PASS_CONDITION_DOES_NOT_MATCH',
  IAP_RECEIPT_VALIDATION_FAILED = 'IAP_RECEIPT_VALIDATION_FAILED',
  INVALID_IAP_RECEIPT = ' INVALID_IAP_RECEIPT',
  PAYMENT_CANCELABLE_ON_APPLE = 'PAYMENT_CANCELABLE_ON_APPLE',
  PAYCO_ORDER_FAILED = 'PAYCO_ORDER_FAILED',
}
