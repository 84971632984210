import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DurationLikeObject } from 'luxon';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewsNotificationReadResult } from '../../types/api/news.type';
import { Banner, Notice, NoticePreview, Notification, Promotion, PromotionPreview } from '../../types/client.type';
import { cache } from '../../utils/operator/cache';
import { ApiService } from '../api';

const COMMON_EXPIRY: DurationLikeObject = { day: 7 };

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  constructor(
    private platform: Platform,
    private apiService: ApiService,
  ) {}

  /**
   * 공지 목록을 가져옵니다.
   * @param page 페이지
   */
  getNoticeList(page: number): Observable<Array<NoticePreview>> {
    return this.apiService.newsV1NoticeList({ page }).pipe(
      map((res) => res.result.list),
    );
  }

  /**
   * 공지를 가져옵니다.
   * @param id 공지 ID
   */
  getNotice(id: string | null): Observable<Notice | null> {
    if (id == null) {
      return of(null);
    }

    return this.apiService.newsV1NoticeDetail({ _id: id }).pipe(
      map((res) => res.result.notice),
    );
  }

  /**
   * 프로모션 목록을 가져옵니다.
   * @param page 페이지
   */
  getPromotionList(page: number): Observable<Array<PromotionPreview>> {
    return this.apiService.newsV1PromotionList({ page }).pipe(
      map((res) => res.result.list),
    );
  }

  /**
   * 프로모션을 가져옵니다.
   * @param id 프로모션 ID
   */
  getPromotion(id: string | null): Observable<Promotion | null> {
    if (id == null) {
      return of(null);
    }

    return this.apiService.newsV1PromotionDetail({ _id: id }).pipe(
      map((res) => res.result.promotion),
    );
  }

  getNotificationList(page: number): Observable<Array<Notification>> {
    return this.apiService.newsV1NotificationList({ page }).pipe(
      map((res) => res.result.list),
    );
  }

  markNotificationAsRead(id: string, fromPush?: boolean): Observable<NewsNotificationReadResult> {
    return this.apiService.newsV1NotificationRead({ id, fromPush }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 배너를 가져옵니다.
   */
  getBanner(noCache?: boolean): Observable<Array<Banner>> {
    const platform = this.getPlatformString();
    return this.apiService.newsV1Banner({ platform }).pipe(
      map((res) => res.result.list),
      cache('news.getBanner', { noCache, expiry: COMMON_EXPIRY }),
    );
  }

  /**
   * 배너 클릭을 기록합니다.
   */
  clickBanner(id: string): Observable<void> {
    return this.apiService.newsV1BannerClick({ _id: id }).pipe(
      map((res) => res.result),
    );
  }

  /**
   * 플랫폼 정보 문자열을 가져옵니다.
   */
  private getPlatformString(): string {
    const base = `${this.platform.is('mobile') ? 'Mobile ' : ''}${this.platform.is('hybrid') ? 'App' : 'Web'}`;
    const additional: Array<string> = [];
    if (this.platform.is('android')) {
      additional.push('Android');
    }
    if (this.platform.is('ios')) {
      additional.push('iOS');
    }
    if (this.platform.is('pwa')) {
      additional.push('PWA');
    }
    return base + (additional.length ? ` (${additional.join(', ')})` : '');
  }
}
