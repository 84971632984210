import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FirebaseAnalyticsPlugin } from '@capacitor-community/firebase-analytics';
import { Capacitor, Plugins } from '@capacitor/core';
import { hasKey } from '../../utils/has-key';

const FirebaseAnalytics = Plugins.FirebaseAnalytics as FirebaseAnalyticsPlugin;

// https://firebase.google.com/docs/reference/android/com/google/firebase/analytics/FirebaseAnalytics.Event
const EARN_VIRTUAL_CURRENCY_EVENT = 'earn_virtual_currency';
const LOGIN_EVENT = 'login';
const PURCHASE_EVENT = 'purchase';
const REFUND_EVENT = 'refund';
const SCREEN_VIEW_EVENT = 'screen_view';
const SHARE_EVENT = 'share';
const SIGN_UP_EVENT = 'sign_up';
const SPEND_VIRTUAL_CURRENCY_EVENT = 'spend_virtual_currency';

// https://firebase.google.com/docs/reference/android/com/google/firebase/analytics/FirebaseAnalytics.Param
const AFFILIATION = 'affiliation';
const CONTENT_TYPE = 'content_type';
const COUPON = 'coupon';
const CURRENCY = 'currency';
const ITEM_ID = 'item_id';
const ITEM_NAME = 'item_name';
const ITEMS = 'items';
const METHOD = 'method';
const SCREEN_CLASS_KEY = 'screen_class';
const SCREEN_NAME_KEY = 'screen_name';
const SHIPPING = 'shipping';
const TAX = 'tax';
const TRANSACTION_ID = 'transaction_id';
const VALUE = 'value';
const VIRTUAL_CURRENCY_NAME = 'virtual_currency_name';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {
  constructor(
    private angularFireAnalytics: AngularFireAnalytics,
  ) {}

  logEvent(
    eventName: string,
    eventParams: object,
  ): void {
    if (Capacitor.isNative) {
      switch (eventName) {
        case EARN_VIRTUAL_CURRENCY_EVENT:
          FirebaseAnalytics.logEvent({
            name: eventName,
            params: {
              [VIRTUAL_CURRENCY_NAME]: hasKey(eventParams, VIRTUAL_CURRENCY_NAME) ? eventParams[VIRTUAL_CURRENCY_NAME] : undefined,
              [VALUE]: hasKey(eventParams, VALUE) ? eventParams[VALUE] : undefined,
            },
          });
          break;
        case LOGIN_EVENT:
          FirebaseAnalytics.logEvent({
            name: eventName,
            params: {
              [METHOD]: hasKey(eventParams, METHOD) ? eventParams[METHOD] : undefined,
            },
          });
          break;
        case PURCHASE_EVENT:
          FirebaseAnalytics.logEvent({
            name: eventName,
            params: {
              [AFFILIATION]: hasKey(eventParams, AFFILIATION) ? eventParams[AFFILIATION] : undefined,
              [COUPON]: hasKey(eventParams, COUPON) ? eventParams[COUPON] : undefined,
              [CURRENCY]: hasKey(eventParams, CURRENCY) ? eventParams[CURRENCY] : undefined,
              [ITEMS]: hasKey(eventParams, ITEMS) ? eventParams[ITEMS] : undefined,
              [SHIPPING]: hasKey(eventParams, SHIPPING) ? eventParams[SHIPPING] : undefined,
              [TAX]: hasKey(eventParams, TAX) ? eventParams[TAX] : undefined,
              [TRANSACTION_ID]: hasKey(eventParams, TRANSACTION_ID) ? eventParams[TRANSACTION_ID] : undefined,
              [VALUE]: hasKey(eventParams, VALUE) ? eventParams[VALUE] : undefined,
            },
          });
          break;
        case REFUND_EVENT:
          FirebaseAnalytics.logEvent({
            name: eventName,
            params: {
              [AFFILIATION]: hasKey(eventParams, AFFILIATION) ? eventParams[AFFILIATION] : undefined,
              [COUPON]: hasKey(eventParams, COUPON) ? eventParams[COUPON] : undefined,
              [CURRENCY]: hasKey(eventParams, CURRENCY) ? eventParams[CURRENCY] : undefined,
              [ITEMS]: hasKey(eventParams, ITEMS) ? eventParams[ITEMS] : undefined,
              [SHIPPING]: hasKey(eventParams, SHIPPING) ? eventParams[SHIPPING] : undefined,
              [TAX]: hasKey(eventParams, TAX) ? eventParams[TAX] : undefined,
              [TRANSACTION_ID]: hasKey(eventParams, TRANSACTION_ID) ? eventParams[TRANSACTION_ID] : undefined,
              [VALUE]: hasKey(eventParams, VALUE) ? eventParams[VALUE] : undefined,
            },
          });
          break;
        case SCREEN_VIEW_EVENT:
          FirebaseAnalytics.logEvent({
            name: eventName,
            params: {
              [SCREEN_CLASS_KEY]: hasKey(eventParams, SCREEN_CLASS_KEY) ? eventParams[SCREEN_CLASS_KEY] : undefined,
              [SCREEN_NAME_KEY]: hasKey(eventParams, SCREEN_NAME_KEY) ? eventParams[SCREEN_NAME_KEY] : undefined,
            },
          });
          break;
        case SHARE_EVENT:
          FirebaseAnalytics.logEvent({
            name: eventName,
            params: {
              [CONTENT_TYPE]: hasKey(eventParams, CONTENT_TYPE) ? eventParams[CONTENT_TYPE] : undefined,
              [ITEM_ID]: hasKey(eventParams, ITEM_ID) ? eventParams[ITEM_ID] : undefined,
              [METHOD]: hasKey(eventParams, METHOD) ? eventParams[METHOD] : undefined,
            },
          });
          break;
        case SIGN_UP_EVENT:
          FirebaseAnalytics.logEvent({
            name: eventName,
            params: {
              [METHOD]: hasKey(eventParams, METHOD) ? eventParams[METHOD] : undefined,
            },
          });
          break;
        case SPEND_VIRTUAL_CURRENCY_EVENT:
          FirebaseAnalytics.logEvent({
            name: eventName,
            params: {
              [ITEM_NAME]: hasKey(eventParams, ITEM_NAME) ? eventParams[ITEM_NAME] : undefined,
              [VIRTUAL_CURRENCY_NAME]: hasKey(eventParams, VIRTUAL_CURRENCY_NAME) ? eventParams[VIRTUAL_CURRENCY_NAME] : undefined,
              [VALUE]: hasKey(eventParams, VALUE) ? eventParams[VALUE] : undefined,
            },
          });
          break;
        default:
          FirebaseAnalytics.logEvent({ name: eventName, params: eventParams });
      }
    } else {
      this.angularFireAnalytics.logEvent(eventName, eventParams);
    }
  }

  setUserId(id: string | null): void {
    if (Capacitor.isNative) {
      // TODO: null 입력 가능하게 개선 (https://github.com/capacitor-community/firebase-analytics/issues/49)
      FirebaseAnalytics.setUserId({ userId: id! });
    } else {
      this.angularFireAnalytics.setUserId(id!); // firebase-js-sdk와 @angular/fire에서 파라미터 타입이 잘못 지정되어 있음
    }
  }
}
