<section class="login-section">
  <div class="login-header">로그인</div>
  <app-input class="login-input" type="email" autocomplete="email" placeholder="이메일을 입력해주세요." [disabled]="isLoggingIn" [(ngModel)]="email" (keydown.enter)="onClickLogin()">
    <ion-icon slot="start" class="login-input-icon" name="wai-email"></ion-icon>
  </app-input>
  <app-input class="login-input" type="password" autocomplete="current-password" placeholder="비밀번호를 입력해주세요." [disabled]="isLoggingIn" [(ngModel)]="password" (keydown.enter)="onClickLogin()">
    <ion-icon slot="start" class="login-input-icon" name="wai-password"></ion-icon>
  </app-input>
  <ion-button class="login-button" [color]="canLogin ? 'primary' : 'medium'" (click)="onClickLogin()">로그인</ion-button>
</section>
<section class="help-section">
  <a class="help-register" [routerLink]="['/register']" [state]="{ loginScreenId }" (click)="onClickRegister()">회원가입</a>
  <div class="help-sep"></div>
  <a class="help-reset-password" [routerLink]="['/reset-password']" (click)="onClickResetPassword()">비밀번호 찾기</a>
</section>
<div class="modal-bottom-drawer-bottom"></div>
